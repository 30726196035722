import React from 'react';
import styles from './coachThematicTable.module.scss';
import { Coach_thamatic_title } from '../../config/helperConstants';
import { IThematic } from '../../models/type';

interface Props {
  thematics: Array<IThematic>;
  target_user_position: string;
}

const CoachThematicTable: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const renderThemanticTable = () => {
    const renderArray = [];
    const thematicsResult: any = {
      EXEC: [],
      MNGR: [],
      CDP: [],
      CLB: [],
    };

    props.thematics.forEach((item: IThematic) => {
      thematicsResult[item.user_position] = [...thematicsResult[item.user_position], item];
    });

    for (let key in thematicsResult) {
      if (!thematicsResult[key].length) continue;
      renderArray.push(
        <div className={styles.wrapper}>
          <h3>{Coach_thamatic_title.get(key)}</h3>
          <ul>
            {thematicsResult[key].map((item: IThematic) => {
              return <li>{item.title}</li>;
            })}
          </ul>
        </div>,
      );
    }

    return renderArray;
  };

  /*MAIN RETURN*/
  return <>{renderThemanticTable()}</>;
};

export default React.memo(CoachThematicTable);
