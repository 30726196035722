import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Button, Form, Icon } from 'semantic-ui-react';
import Annoucement from '../Common/Annoucement/Annoucement';
import Validator from '../../services/validation/validation';
import { errorMessage } from '../../services/validation/errorMessage';
import { API_userForgetPassword } from '../../services/apis/userApi';
import styles from './ForgetPassword.module.scss';
import { useMutation } from 'urql';
import { useTranslation } from 'react-i18next';

const ForgetPassword: React.FC = () => {
  const [{ fetching }, forgetPasswordMethod] = useMutation(API_userForgetPassword);

  const [formState, setFormState] = useState({
    value: '',
    error: false,
    errorMessage: [],
    sentEmailSuccess: false,
    goToLogin: false,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFormState({ ...formState, value: event.currentTarget.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const tempState = { ...formState };
    let emailPassed = true;
    //validate valid email
    if (!Validator.checkValidEmail(formState.value)) {
      tempState.error = true;
      tempState.errorMessage[0] = errorMessage.email.invalid.frError;
      emailPassed = false;
    }

    if (Validator.checkEmpty(formState.value)) {
      tempState.error = true;
      tempState.errorMessage[0] = errorMessage.email.presence.frError;
      emailPassed = false;
    }

    if (emailPassed) {
      tempState.error = false;
    }

    setFormState(tempState);
    if (!emailPassed) return;

    //email pass validation --> call api request
    forgetPasswordMethod({ email: formState.value })
      .then((res) => {
        //why if(!res.data) doesn't work???
        if (!res.data === true) {
          setFormState({
            ...formState,
            error: true,
            errorMessage: [translate('Email is incorrect'), translate('Check your email again')],
          });
          return;
        }
        if (res?.data?.forgottenPassword.status_code === 200) {
          setFormState({ ...formState, sentEmailSuccess: true });
          return;
        }
      })
      .catch((err) => console.error(err));
  };

  if (formState.goToLogin) {
    return <Navigate to='login' />;
  }

  return (
    <>
      {!formState.sentEmailSuccess ? (
        <Form
          className={styles.formBlock}
          onSubmit={(e) => {
            handleSubmit(e);
          }}>
          <Icon name='lock' size={'big'} />
          <p className={`${styles.title} topLevelText`}>{translate('Reset password')}</p>
          <p className={styles.description}>{translate('Please use your email to reset password')}</p>
          <Form.Field className={styles.formField}>
            <input
              name='email'
              placeholder='Email'
              type='email'
              className={styles.inputField}
              onChange={(e) => handleOnChange(e)}
              autoComplete='true'
            />
            {formState.errorMessage.map((message: string) => {
              return (
                <p
                  className={styles.errorMessage}
                  style={formState.error ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                  {message}
                </p>
              );
            })}
          </Form.Field>

          <Button type='submit' className={styles.submitButton} disabled={fetching}>
            {translate('Send')}
          </Button>

          <p
            className={styles.forgetPasswordText}
            onClick={() => {
              setFormState({ ...formState, goToLogin: true });
            }}>
            <Icon name='long arrow alternate left' />
            {translate('Back to login')}
          </p>
        </Form>
      ) : (
        <Annoucement
          title={translate('Email sent')}
          description={translate('Reset password email sent')}
          btnTitle={translate('Back to home page')}
        />
      )}
    </>
  );
};

export default ForgetPassword;
