import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';
import { useMutation, useQuery } from 'urql';
import CardProgramManager from '../../components/CardProgramManager/CardProgramManager';
import AlertModal from '../../components/Common/AlertModal/AlertModal';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import CourseListTable from '../../components/CourseListTable/CourseListTable';
import EditableProgramDetailComp from '../../components/EditableProgramDetailComp/EditableProgramDetailComp';
import EditableTeamCoachs from '../../components/EditableTeamCoachs/EditableTeamCoachs';
import PMHeaderBlock from '../../components/PMHeaderBlock/PMHeaderBlock';
import UploadComponent from '../../components/UploadComponent/UploadComponent';
import { AlertModalContentQuestionStyle, Helper_Actions, MaterialIconName, STATUS_CODE } from '../../models/enum';
import { IProgramManagerExtended } from '../../models/type';
import { PROGRAM_getDetail } from '../../services/apis/programApi';
import {
  PROGRAMMANAGER_getPMListFromProgram,
  PROGRAMMANAGER_removeFromProgram,
} from '../../services/apis/programManagerApi';
import styles from './programDetailPage.module.scss';
import { useLocation } from 'react-router-dom';

const ProgramDetailPage: React.FC = (props: any) => {
  const [programDetailQueryvar, setProgramDetailQueryvar] = useState(null);

  const [PMListFromProgramQueryvar, setPMListFromProgramQueryvar] = useState(null);

  const [alertModalState, setAlertModalState] = useState({
    isRemovePMShowed: false,
    modalContentQuestion: null,
    modalContent: null,
    program_id: null,
    id: null,
  });

  const location = useLocation();

  const program_id = useMemo(() => {
    return window.location.pathname.split('/').pop();
  }, []);

  const [, executeRemoveProgramManger] = useMutation(PROGRAMMANAGER_removeFromProgram);

  const [programQueryResult] = useQuery({
    query: PROGRAM_getDetail,
    variables: programDetailQueryvar,
    requestPolicy: 'network-only',
    pause: !programDetailQueryvar,
  });

  const [programManagerQueryResult, reExecuteQueryProgramManger] = useQuery({
    query: PROGRAMMANAGER_getPMListFromProgram,
    variables: PMListFromProgramQueryvar,
    requestPolicy: 'cache-and-network',
    pause: !PMListFromProgramQueryvar,
  });

  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const handleAgreeToRemoveProgramManager = useCallback(() => {
    executeRemoveProgramManger({
      id: alertModalState.id,
      program_id: alertModalState.program_id,
    })
      .then((res) => {
        if (res.data.removeProgramManagerFromProgram.status_code === STATUS_CODE.SUCCESS) {
          setAlertModalState({ ...alertModalState, isRemovePMShowed: false });
          reExecuteQueryProgramManger();
        }
      })
      .catch((err) => console.error(err));
  }, [alertModalState, executeRemoveProgramManger, reExecuteQueryProgramManger]);

  const handleSearch = useCallback(
    (searchValue: string) => {
      setPMListFromProgramQueryvar({
        program_id: program_id,
        count: 100,
        page: 1,
        order_by: 'createdAt',
        direction: 'desc',
        search_name: searchValue,
      });
    },
    [program_id],
  );

  const renderProgramManagers = (PMArray: Array<IProgramManagerExtended>) => {
    return PMArray.map((item: IProgramManagerExtended, index: number) => {
      return (
        <CardProgramManager
          programManager={item}
          key={index}
          showDeleteCross={true}
          removeProgramManger={() => {
            setAlertModalState({
              ...alertModalState,
              isRemovePMShowed: true,
              modalContentQuestion: [
                {
                  text: 'Voulez-vous vraiment détacher ',
                  style: AlertModalContentQuestionStyle.BASIC,
                },
                {
                  text: item.user_infos.first_name + ' ' + item.user_infos.last_name,
                  style: AlertModalContentQuestionStyle.PRIMARY,
                },
                {
                  text: ' du programme ',
                  style: AlertModalContentQuestionStyle.BASIC,
                },
                {
                  text: programQueryResult.data?.detailProgram?.program.name
                    ? programQueryResult.data?.detailProgram?.program.name
                    : '',
                  style: AlertModalContentQuestionStyle.PRIMARY,
                },
                {
                  text: ' ?',
                  style: AlertModalContentQuestionStyle.BASIC,
                },
              ],
              modalContent: 'Ce gestionnaire de programme est toujours disponible dans la base de données.',
              program_id: program_id,
              id: item.id,
            });
          }}
        />
      );
    });
  };

  /*EFFECTS*/
  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.info,
        title: translate('Program detail'),
      },
    });
  }, [dispatch, translate]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setProgramDetailQueryvar({ id: program_id });
    setPMListFromProgramQueryvar({
      program_id: program_id,
      count: 20,
      page: 1,
      order_by: 'createdAt',
      direction: 'desc',
      search_name: '',
    });
  }, [location.pathname, program_id]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <p className={styles.titleDesc}>{translate('Title desc of detail program page')}</p>
      </div>

      <div className={styles.contentWrapper}>
        {programQueryResult.fetching && <AwaitingComp fluid={false} />}
        {programQueryResult.error && <p className={styles.titleDesc}>{translate('No detail')}</p>}
        {programQueryResult.data && (
          <Grid>
            <Grid.Row>
              <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
                <EditableProgramDetailComp
                  showTitle={true}
                  isEditingMode={true}
                  program={{
                    ...programQueryResult.data.detailProgram.program,
                    id: program_id,
                  }}
                  programMangerTotal={
                    programManagerQueryResult.data
                      ? programManagerQueryResult.data.listProgramManagersFromProgram.program_managers.length
                      : null
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>

      {programQueryResult.data && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
                <UploadComponent
                  passedItem_ID={programDetailQueryvar.id}
                  reFetch={() => reExecuteQueryProgramManger()}
                  title={translate('Import program manager list')}
                  titleDesc={translate('Excel with format')}
                  titleDescBold='email, nom, prenom'
                  titleDescContinuous={translate('Upload file cannot larger than 5MB')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className={styles.customLastGrid}>
            <Grid.Row>
              <Grid.Column tablet={16} computer={16} widescreen={12} largeScreen={14}>
                <div className={styles.programManagersListWrapper}>
                  <PMHeaderBlock
                    showSearch={true}
                    showDropdown={false}
                    showReload={false}
                    handleSearch={(value: string) => handleSearch(value)}
                  />

                  {programManagerQueryResult.data && (
                    <div>
                      {renderProgramManagers(
                        programManagerQueryResult.data.listProgramManagersFromProgram.program_managers,
                      )}
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
      {programQueryResult.data?.detailProgram && (
        <>
          <Grid className={styles.customLastGrid}>
            <Grid.Row>
              <Grid.Column tablet={16} computer={16} widescreen={12} largeScreen={14}>
                <EditableTeamCoachs
                  key={`coach-team-${program_id}`}
                  programName={programQueryResult.data.detailProgram.program.name}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className={styles.customLastGrid}>
            <Grid.Row>
              <Grid.Column tablet={16} computer={16} widescreen={12} largeScreen={14}>
                <Segment className={styles.courseListWrapper}>
                  <span className={styles.courseListTitle}>{translate('Courses list')}</span>
                </Segment>
              </Grid.Column>
              <Grid.Column tablet={16} computer={16} widescreen={12} largeScreen={14}>
                <CourseListTable key={`courses-${program_id}`} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}

      {alertModalState.isRemovePMShowed && (
        <AlertModal
          title='CONFIRMER LA SUPPRESSION'
          contentQuestion={alertModalState.modalContentQuestion}
          content={alertModalState.modalContent}
          cancel={() => setAlertModalState({ ...alertModalState, isRemovePMShowed: false })}
          agree={() => handleAgreeToRemoveProgramManager()}
        />
      )}
    </div>
  );
};

export default ProgramDetailPage;
