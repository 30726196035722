/*QUERY*/
export const PROGRAM_getPrograms = `
query ($page : Int!, $search_name : String, $order_by: ProgramOrderableColumn, $direction : SortDirection!, $client_id : ID){
    listPrograms(page: $page, search_name: $search_name, order_by: $order_by, direction: $direction, client_id: $client_id) {
        status_code
        programs {
            id
            name
            creation_date
            close_date
            courses_summary {
                number_of_unassigned
                number_of_started
                number_of_done
                total
            }
            client {
                name
            }
            offerConfigurations {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
            }
            number_of_courses
            number_of_program_managers
            number_of_coaches
            number_of_coachees
        }
        paginatorInfo {
            currentPage
            lastPage
        }
    }
}
`;

export const PROGRAM_getDetail = `
query ($id : ID!){
    detailProgram(id: $id) {
        status_code
        program {
            name
            creation_date
            start_date
            close_date
            has_started
            client {
                name
            }
            program_managers {
                id
                email
            }
            offerConfigurations {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_courses
            }
            number_of_courses
            number_of_program_managers
            number_of_coaches
            number_of_coachees
        }
    }
}
`;

export const PROGRAM_get_program = `
query ($program_id : ID!) {
    getProgram (id: $program_id) {
        status_code
        program {
            id
            name
            courses_summary {
                number_of_unassigned
                number_of_started
                number_of_done
                total
                evaluation
            }
            sessions_summary {
                number_of_sessions_unplanned
                number_of_sessions_planned
                number_of_sessions_done
                total
            }
            number_of_courses
            number_of_coachees
            offerConfigurations {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_courses
                number_of_program_managers
                number_of_coaches
                number_of_coachees
                number_of_unassigned
                number_of_started
                number_of_closed
            }
            self_diagnosis {
                number_of_done
                total
            }
            top_of_needs
            courses {
                id
                objectives {
                    id
                    evaluations {
                        id
                        grade
                        session_id
                        session_current_index
                    }
                }
            }
        }
    }
}
`;
/*MUTATION*/

export const PROGRAM_createProgram = `
mutation ($client_id : ID!, $name : String!, $start_date : Date!, $offer_configurations : [CreateOfferConfiguration!]!) {
    addProgram(
        client_id: $client_id,
        name: $name,
        start_date: $start_date,
        offer_configurations: $offer_configurations
    ) {
        status_code
        program {
            id
            name
            creation_date
            start_date
            client {
                id
                name
            }
            offerConfigurations {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_courses
            }
            number_of_courses
            number_of_program_managers
            number_of_coaches
            number_of_coachees
        }
    }
}`;

export const PROGRAM_update = `
mutation ($id : ID!, $name : String!, $start_date : Date!, $offer_configurations : [CreateOfferConfiguration!]!){
    updateProgram(
        id: $id
        name: $name
        start_date: $start_date
        offer_configurations : $offer_configurations
    ) {
        status_code
        program {
            id
            name
            creation_date
            start_date
            client {
                id
                name
            }
            offerConfigurations {
                name
                offer_id
                number_of_sessions
                number_of_courses
                duration_of_session
                has_objectives
                has_self_diagnosis
            }
            number_of_courses
            number_of_program_managers
            number_of_coaches
            number_of_coachees
        }
    }
}
`;

/*OFFER*/

export const PRORAM_OFFER_getList = `
query ($page: Int!, $search_name: String, $order_by: OfferOrderableColumn, $direction: SortDirection) {
    listOffers(page: $page, search_name: $search_name, order_by: $order_by, direction: $direction) {
        status_code
        offers {
            id
            name
            number_of_sessions
            duration_of_session
            has_objectives
            has_self_diagnosis
        }
        paginatorInfo {
            currentPage
            lastPage
        }
    }
}
`;
