/* QUERIES */
export const COURSE_listCoursesFromProgram = `
query ($program_id : ID!) {
    listCoursesFromProgramDetail (
        program_id: $program_id
    ) {
        status_code
        courses {
            id
            started_at
            evaluation
            number_of_sessions_done
            number_of_sessions
            coachee {
                first_name
                last_name
            }
            coach {
                first_name
                last_name
            }
            offer_configuration {
                id
                name
            }
        }
    }
}
`;

export const COURSE_course_table = `
query ($program_id : ID!, $count : Int, $page : Int!, $order_by : String, $direction :String ) {
    listCourses(
        program_id: $program_id,
        count: $count,
        page: $page,
        order_by: $order_by,
        direction: $direction
    ) {
        status_code
        courses {
            id
            created_at
            started_at
            closed_at
            status
            offer_configuration {
                id
                name
                offer_id
                number_of_sessions
                duration_of_session
                has_objectives
                has_self_diagnosis
                number_of_courses
            }
            evaluation
            coachee {
                first_name
                last_name
            }
            coach {
                first_name
                last_name
            }
            number_of_sessions_done
            objectives{
                id
                evaluations {
                    id
                    grade
                    session_id
                    session_current_index
                }
            }
        }
        paginatorInfo {
            currentPage
            lastPage
        }
    }
}`;

export const COURSE_force_coach_to_course = `
mutation($course_id: ID!, $coach_id: ID!) {
    forceAssignCoachOnCourse(
        course_id: $course_id,
        coach_id: $coach_id
    ) {
        status_code
    }
}
`;

export const COURSE_interrupt_course = `
mutation($course_id: ID!) {
    interruptCourse(
        course_id: $course_id
    ) {
        status_code
    }
}
`;
