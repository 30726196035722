import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Image, Icon } from 'semantic-ui-react';
import { locationOrigin } from '../../config/helperConstants';
import styles from './previewFile.module.scss';

interface Props {
  photoLink: string;
  photoName: string;
}

const PreviewFile: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const { t: translate } = useTranslation();

  return (
    <Segment className={styles.customedSegment}>
      <div className={styles.topBlock}>
        <div className={styles.image}>
          {props.photoLink ? (
            <Image src={locationOrigin + '/' + props.photoLink} size='small' className={styles.previewImage} />
          ) : (
            <Icon name='user' size='huge' className={styles.userIcon} />
          )}
        </div>
      </div>
      <div className={styles.bottomBlock}>
        <span className={styles.photoName}>{props.photoName}</span>
        <span>{translate('Coach')}</span>
      </div>
    </Segment>
  );
};

export default PreviewFile;
