import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { IClient, IProgram } from '../../models/type';
import { dateToStringConvertor } from '../../utilities/dateConvertor';
import styles from './clientRow.module.scss';
import { mpburl } from '../../config/helperConstants';

interface Props {
  client: IClient;
  handleViewDetail: () => void;
}

const ClientRow: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const renderPrograms = useCallback((programsArray: Array<IProgram>) => {
    return programsArray.map((item, index) => {
      if (index > 2) {
        return <div key={item.id}></div>;
      }
      return (
        <Link key={item.id} to={{ pathname: `/program-detail/detail/${item.id}` }}>
          <li className={styles.li}>{item.name}</li>
        </Link>
      );
    });
  }, []);

  const renderWorkingDate = useCallback(() => {
    const dateStringArr = dateToStringConvertor(props.client.days);
    return <>{dateStringArr[0] + '-' + dateStringArr.pop()}</>;
  }, [props.client.days]);

  return (
    <tr className='tableListRow'>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        <Link to={`/client-detail/info/${props.client.id}`}>
          <span className={styles.textClientName}>{props.client.name}</span>
        </Link>
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.client?.project_director &&
          `${props.client.project_director.first_name + ' ' + props.client.project_director.last_name}`}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {renderWorkingDate()}
        <br />
        {props.client.open_at}-{props.client.close_at}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        <ul className={styles.ul}>{renderPrograms(props.client.programs)}</ul>
        {props.client.programs.length > 3 && (
          <p className={styles.textSeeMore}>
            <Link to={'/programslist'} state={{ client_needed_fetch_program: props.client }}>
              <span>Voir Plus...</span>
            </Link>
          </p>
        )}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>{props.client.tool === 'n/a' ? '' : props.client.tool}</td>
      <td className={`${styles.tbodyTD}`}>
        <p className={styles.subdomain}>{props.client.subdomain + mpburl}</p>
      </td>
    </tr>
  );
};

export default React.memo(ClientRow);
