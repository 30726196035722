import { Program_Actions } from '../../../models/enum';
import { ICoach_in_program_reducer, ICoach_render_coach_team, IProgram_Actions } from '../../../models/type';
import { mapAllCoursesProgressionPoint } from '../../../utilities/progression/progressionUtils';

const initialState: any = {
  coachsTeam: [],
  coursesProgression: {
    programID: '',
    progressionCollection: [],
  },
};

export const programReducer = (state = initialState, action: IProgram_Actions) => {
  switch (action.type) {
    case Program_Actions.add_coach_to_team:
      const tempArr = [...state.coachsTeam];
      const isExisted = tempArr.findIndex((coach: ICoach_in_program_reducer) => {
        return coach.id === action.payload.id;
      });

      if (isExisted !== -1) {
        return state;
      }

      tempArr.push({
        id: action.payload.id,
        name: action.payload.title,
      });
      return {
        ...state,
        coachsTeam: tempArr,
      };

    case Program_Actions.remove_coach_from_team:
      const copiedCoachsTeam = [...state.coachsTeam];
      const indexFound = copiedCoachsTeam.findIndex((item: ICoach_render_coach_team) => {
        return item.id === action.payload;
      });
      copiedCoachsTeam.splice(indexFound, 1);
      return { ...state, coachsTeam: copiedCoachsTeam };

    case Program_Actions.reset_coach_team_with_backup:
      return { ...state, coachsTeam: action.payload };

    case Program_Actions.set_coachs_team:
      const coaches = action.payload.map((item: any) => {
        return {
          id: item.id,
          name: item.user_infos.first_name + ' ' + item.user_infos.last_name,
        };
      });
      return { ...state, coachsTeam: coaches };

    case Program_Actions.set_course_progression:
      const newCoursesProgression = {
        programID: action.payload.programID,
        progressionCollection: mapAllCoursesProgressionPoint(action.payload.courses),
      };

      return { ...state, coursesProgression: newCoursesProgression };

    default:
      return state;
  }
};
