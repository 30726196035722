import { Coach_Actions, Coach_classify } from '../../../models/enum';
import { ICoach_Actions, IThematic } from '../../../models/type';

interface State {
  listThematics: {
    EXEC: Array<IThematic>;
    MNGR: Array<IThematic>;
    CLB: Array<IThematic>;
    CDP: Array<IThematic>;
  };
}

const initialState: State = {
  listThematics: {
    EXEC: [],
    MNGR: [],
    CLB: [],
    CDP: [],
  },
};

export const coachReducer = (state = initialState, action: ICoach_Actions) => {
  switch (action.type) {
    case Coach_Actions.set_thematics:
      const sortedThematic = action.payload.sort(function (a: IThematic, b: IThematic) {
        return a.position - b.position;
      });
      const sortedEXEC: Array<IThematic> = sortedThematic.filter((item: IThematic) => {
        return item.user_position === Coach_classify.Executive;
      });
      const sortedMNGR: Array<IThematic> = sortedThematic.filter((item: IThematic) => {
        return item.user_position === Coach_classify.Senior;
      });
      const sortedCLB: Array<IThematic> = sortedThematic.filter((item: IThematic) => {
        return item.user_position === Coach_classify.Future;
      });
      const sortedCDP: Array<IThematic> = sortedThematic.filter((item: IThematic) => {
        return item.user_position === Coach_classify.Coach;
      });
      return {
        ...state,
        listThematics: {
          EXEC: sortedEXEC,
          MNGR: sortedMNGR,
          CLB: sortedCLB,
          CDP: sortedCDP,
        },
      };
    default:
      return state;
  }
};
