import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Segment } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { PROGRAM_get_program } from '../../services/apis/programApi';
import { OfferName, Program_Actions, STATUS_CODE } from '../../models/enum';
import { filerCourseType, generate_staked_bar_data } from '../../utilities/ChartHelper';
import ChartContainer from '../ChartContainer/ChartContainer';
import DashboardStatisticalComp from '../DashboardStatisticalComp/DashboardStatisticalComp';
import styles from './programDashboardComp.module.scss';
import TopListingComp from '../TopListingComp/TopListingComp';
import { useDispatch, useSelector } from 'react-redux';
import { calcAvgProgrammProgressionPoint, renderPercentage } from '../../utilities/progression/progressionUtils';

interface Props {
  programID: string;
}

const ProgramDashboardComp: React.FC<Props> = (props: Props) => {
  /*PROPS*/
  const [programDetailResult] = useQuery({
    query: PROGRAM_get_program,
    variables: { program_id: props.programID },
    requestPolicy: 'cache-and-network',
    pause: !props.programID,
  });

  const coursesProgressionSelector = useSelector((state: any) => state.programReducer.coursesProgression);

  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  /*EFFECTS*/
  useEffect(() => {
    if (+programDetailResult.data?.getProgram.status_code !== +STATUS_CODE.SUCCESS) return;

    if (coursesProgressionSelector.programID === props.programID) return;

    dispatch({
      type: Program_Actions.set_course_progression,
      payload: {
        programID: props.programID,
        courses: programDetailResult.data.getProgram.program.courses,
      },
    });
  }, [coursesProgressionSelector.programID, dispatch, programDetailResult.data, props.programID]);

  /*MAIN RETURN*/

  if (!programDetailResult.data) return <></>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentBlock}>
        <div className={styles.chartsBlock}>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile='16' tablet='16' computer='8'>
                <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                  <Segment className={`${styles.customedSegment}`}>
                    <ChartContainer
                      chartTitle={translate('Program progress')}
                      chartTitleHighlight={true}
                      chartType='horizontal_stacked_bar'
                      data={generate_staked_bar_data(programDetailResult.data.getProgram.program.courses_summary)}
                    />
                  </Segment>
                </div>
                <div className={styles.leftBottomWrapper}>
                  <div className={`${styles.leftBottomBlock} ${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                    <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                      <ChartContainer
                        chartTitle={OfferName.Flash + ' ' + translate('Course summary')}
                        chartType='horizontal_stacked_bar'
                        data={generate_staked_bar_data(
                          filerCourseType(
                            programDetailResult.data.getProgram.program.offerConfigurations,
                            OfferName.Flash,
                          ),
                        )}
                      />
                    </Segment>
                  </div>
                  <div className={`${styles.leftBottomBlock} ${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                    <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                      <ChartContainer
                        chartTitle={OfferName.Focus + ' ' + translate('Course summary')}
                        chartType='horizontal_stacked_bar'
                        data={generate_staked_bar_data(
                          filerCourseType(
                            programDetailResult.data.getProgram.program.offerConfigurations,
                            OfferName.Focus,
                          ),
                        )}
                      />
                    </Segment>
                  </div>
                  <div className={`${styles.leftBottomBlock} ${styles.heightForBlock} `}>
                    <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                      <ChartContainer
                        chartTitle={OfferName.Development + ' ' + translate('Course summary')}
                        chartType='horizontal_stacked_bar'
                        data={generate_staked_bar_data(
                          filerCourseType(
                            programDetailResult.data.getProgram.program.offerConfigurations,
                            OfferName.Development,
                          ),
                        )}
                      />
                    </Segment>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile='16' tablet='16' computer='8'>
                <Grid>
                  <Grid.Row className={styles.firstRowOfRightCol}>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Session completed over sessions planned')}
                            category_sessions={programDetailResult.data.getProgram.program.sessions_summary}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Self diagnostic done')}
                            category_auto_diagnostics={programDetailResult.data.getProgram.program.self_diagnosis}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={styles.secondRowOfRightCol}>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Total coachees 2')}
                            simplyNumber={programDetailResult.data.getProgram.program.number_of_coachees}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Overall satisfaction')}
                            simplyNumber={programDetailResult.data.getProgram.program.courses_summary.evaluation + '/5'}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile='16' computer='8'>
                      <div className={`${styles.heightForBlock} ${styles.blockMarginBottom}`}>
                        <Segment className={`${styles.customedSegment} ${styles.h100}`}>
                          <DashboardStatisticalComp
                            label={translate('Progression')}
                            simplyNumber={renderPercentage(
                              calcAvgProgrammProgressionPoint(coursesProgressionSelector.progressionCollection),
                            )}
                          />
                        </Segment>
                      </div>
                    </Grid.Column>
                    <Grid.Column computer='8'></Grid.Column>
                    <Grid.Column computer='16'>
                      <div className={styles.topNeedBlock}>
                        <Segment className={`${styles.customedSegment} ${styles.topNeedBlock}`}>
                          <TopListingComp top_of_need={programDetailResult.data.getProgram.program.top_of_needs} />
                        </Segment>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProgramDashboardComp);
