import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { ListMediaResourcesSortableColumn, SortDirection } from '../../models/enum';
import { IMediaResource } from '../../models/type';
import MediaResourceRow from './MediaResourceRow';
import styles from './mediaResourcesListPage.module.scss';

interface Props {
  handleChangeDirection: (category: ListMediaResourcesSortableColumn, direction: SortDirection) => void;
  currentOrderBy: string;
  currentDirection: string;
}

const MediaResourcesTable: React.FC<Props> = (props: Props) => {
  /*PROPS*/

  const mediaResourcesListSelector: Array<IMediaResource> = useSelector(
    (state: any) => state.mediaResourcesReducer.mediaRourcesList,
  );

  const selectedMediaListSelector: Array<string> = useSelector(
    (state: any) => state.mediaResourcesReducer.selectedMediaList,
  );

  /*CONST*/
  const titleSortedASC =
    props.currentOrderBy === ListMediaResourcesSortableColumn.title && props.currentDirection === SortDirection.ASC;

  const formatSortedASC =
    props.currentOrderBy === ListMediaResourcesSortableColumn.format && props.currentDirection === SortDirection.ASC;

  const languageSortedASC =
    props.currentOrderBy === ListMediaResourcesSortableColumn.language && props.currentDirection === SortDirection.ASC;

  /*METHODS*/
  const { t: translate } = useTranslation();

  const renderRow = (mediaResourceList: Array<IMediaResource>) => {
    if (!mediaResourceList) return;
    return mediaResourceList.map((item: IMediaResource) => {
      return (
        <MediaResourceRow
          mediaResourceItem={item}
          checked={selectedMediaListSelector.includes(item.id)}
          key={item.id}
        />
      );
    });
  };

  return (
    <table className={styles.table}>
      <thead className={styles.fixedHeader}>
        <tr className={styles.headerTR}>
          <th className={`${styles.headerTH} ${styles.selectTH}`}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Choose')}</span>
            </div>
          </th>
          <th className={`${styles.headerTH} ${styles.titleTH}`}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Title')}</span>
              {titleSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() => {
                    props.handleChangeDirection(ListMediaResourcesSortableColumn.title, SortDirection.DESC);
                  }}
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() => {
                    props.handleChangeDirection(ListMediaResourcesSortableColumn.title, SortDirection.ASC);
                  }}
                />
              )}
            </div>
          </th>

          <th className={`${styles.headerTH} ${styles.formatTH}`}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Format')}</span>
              {formatSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() =>
                    props.handleChangeDirection(ListMediaResourcesSortableColumn.format, SortDirection.DESC)
                  }
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() =>
                    props.handleChangeDirection(ListMediaResourcesSortableColumn.format, SortDirection.ASC)
                  }
                />
              )}
            </div>
          </th>

          <th className={`${styles.headerTH} ${styles.thematicTH}`}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Thematic', { count: 2 })}</span>
            </div>
          </th>

          <th className={`${styles.headerTH} ${styles.languageTH}`}>
            <div className={styles.headerTitleBlock}>
              <span>{translate('Language')}</span>
              {languageSortedASC ? (
                <Icon
                  name='angle up'
                  className={styles.sortIcon}
                  onClick={() =>
                    props.handleChangeDirection(ListMediaResourcesSortableColumn.language, SortDirection.DESC)
                  }
                />
              ) : (
                <Icon
                  name='angle down'
                  className={styles.sortIcon}
                  onClick={() =>
                    props.handleChangeDirection(ListMediaResourcesSortableColumn.language, SortDirection.ASC)
                  }
                />
              )}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>{renderRow(mediaResourcesListSelector)}</tbody>
    </table>
  );
};

export default MediaResourcesTable;
