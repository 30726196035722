import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { translateDropDownOption, validURL } from '../../utilities/helper';
import styles from './editableMediaResource.module.scss';
import { languages_without_all_choice, maxUploadSize, mediaResourcesInputFormat } from '../../config/helperConstants';
import {
  AlertModalContentQuestionStyle,
  FormMessages,
  Helper_Actions,
  IconEnum,
  ListMediaResourcesSortableColumn,
  MediaResourcesFormats,
  STATUS_CODE,
} from '../../models/enum';
import AlertModal from '../Common/AlertModal/AlertModal';
import UploadMediaFrame from './UploadMediaFrame';
import { useDispatch } from 'react-redux';
import { palette } from '../../theme/palette';
import { useMutation } from 'urql';
import {
  MEDIARESOURCES_create_media_resource,
  MEDIARESOURCES_update_media_resource,
} from '../../services/apis/mediaResourcesApis';
import { useNavigate } from 'react-router-dom';
import { IMediaResource, IThematic } from '../../models/type';
import PreviewFile from './PreviewFile';
import '../../theme/customedDropDown.scss';

interface State {
  format: MediaResourcesFormats;
  title: string;
  description: string;
  language: string;
  link: string;
  files: any;
  thematic_keywords: string;
  hasEdited: boolean;
}

interface Props {
  editMode: boolean;
  mediaItem?: IMediaResource;
}

const initialFormState: State = {
  format: null,
  title: '',
  description: '',
  language: '',
  link: '',
  files: null,
  thematic_keywords: '',
  hasEdited: false,
};

const EditableMediaResource: React.FC<Props> = (props: Props) => {
  /*STATE*/

  const [formState, setFormState] = useState<State>(initialFormState);

  const [helperState, setHelperState] = useState({
    isEditing: true,
    fileInstruction: 'Download content',
    isModalShowed: false,
    message: '',
    backup: {
      formState: null,
    },
  });

  /*METHODS*/
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  const [, executeCreateMediaResource] = useMutation(MEDIARESOURCES_create_media_resource);

  const [, executeUpdateMediaResource] = useMutation(MEDIARESOURCES_update_media_resource);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      hasEdited: true,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSelectDropDown = (name: string, value: any) => {
    if (name === ListMediaResourcesSortableColumn.format) {
      if (value === MediaResourcesFormats.image)
        setHelperState({
          ...helperState,
          fileInstruction: 'File format must be either JPEG, PNG',
        });
      else if (value === MediaResourcesFormats.pdf)
        setHelperState({
          ...helperState,
          fileInstruction: 'File format must be either PDF',
        });
      else
        setHelperState({
          ...helperState,
          fileInstruction: 'Please paste the link here',
        });
    }

    setFormState({
      ...formState,
      hasEdited: true,
      [name]: value,
      files: name === 'format' ? null : formState.files,
    });

    if (name === 'format') {
      dispatch({
        type: Helper_Actions.set_icon_name,
        payload: {
          icon: IconEnum['cloud upload'],
          color: palette.grey4,
        },
      });
    }
  };

  const handleSwitchEditing = () => {
    setHelperState({
      ...helperState,
      isEditing: true,
      message: '',

      backup: { ...helperState.backup, formState: formState },
    });
    setFormState({ ...formState, hasEdited: false });
  };

  const handleCancelButton = () => {
    if (formState.hasEdited) {
      setHelperState({ ...helperState, isModalShowed: true });
      return;
    }
    setHelperState({ ...helperState, isEditing: false });
  };

  const handleAgreeCancelForm = useCallback(() => {
    if (props.editMode) {
      setFormState(helperState.backup.formState);
      setHelperState({
        ...helperState,
        isModalShowed: false,
        isEditing: false,
      });
      return;
    }
    setFormState(initialFormState);
    setHelperState({
      ...helperState,
      isEditing: false,
      isModalShowed: false,
    });
  }, [helperState, props.editMode]);

  const hadleSubmit = () => {
    switch (props.editMode) {
      //Update
      case true:
        const prepUpdateObject: any = {
          id: props.mediaItem.id,
          title: formState.title,
          description: formState.description,
          language: formState.language,
          link: formState.link,
          file: null,
          thematic_keywords: formState.thematic_keywords,
        };
        if ([MediaResourcesFormats.video_link, MediaResourcesFormats.web_link].includes(formState.format)) {
          if (!validURL(formState.link)) {
            setHelperState({
              ...helperState,
              message: translate('Invalid internet link'),
            });
            return;
          }

          prepUpdateObject.link = formState.link;
        }

        if ([MediaResourcesFormats.pdf, MediaResourcesFormats.image].includes(formState.format) && formState.files)
          prepUpdateObject.file = formState.files[0];
        executeUpdateMediaResource(prepUpdateObject)
          .then((res) => {
            if (res.data && res.data.updateMediaResource.status_code === STATUS_CODE.SUCCESS) {
              setHelperState({
                ...helperState,
                isEditing: false,
                message: FormMessages.Success,
              });
              setFormState({ ...formState, hasEdited: false });
            } else if (+res.error.graphQLErrors[0].extensions.error_code === +STATUS_CODE.NOTFOUND) {
              setHelperState({
                ...helperState,
                message:
                  translate('Themantic must starts with') +
                  ' ' +
                  'DS, MS, PS, CS, DB, MB, PB, CB' +
                  ' ' +
                  translate('and end with 1 or 2 digit number'),
              });
            } else if (+res.error.graphQLErrors[0].extensions.error_code === +STATUS_CODE.PAYLOADTOOLARGE) {
              setHelperState({
                ...helperState,
                message: translate('Upload file cannot larger than 5MB'),
              });
            } else {
              setHelperState({
                ...helperState,
                isEditing: false,
                message: 'Failed',
              });
              console.error(res.error);
            }
          })
          .catch((err) => console.error(err));
        return;

      //Create
      case false:
        const prepCreateObject: any = {
          format: formState.format,
          title: formState.title,
          description: formState.description,
          language: formState.language,
          link: '',
          files: null,
          thematic_keywords: formState.thematic_keywords,
        };
        //case: link
        if ([MediaResourcesFormats.video_link, MediaResourcesFormats.web_link].includes(formState.format)) {
          if (!validURL(formState.link)) {
            setHelperState({
              ...helperState,
              message: 'Invalid internet link',
            });
            return;
          }
          prepCreateObject.link = formState.link;
        }

        //case: file
        if ([MediaResourcesFormats.pdf, MediaResourcesFormats.image].includes(formState.format))
          prepCreateObject.file = formState.files[0];

        executeCreateMediaResource(prepCreateObject)
          .then((res) => {
            if (res.data?.createMediaResource?.status_code === STATUS_CODE.SUCCESS) {
              navigate(`/media-resources-detail/?${res.data.createMediaResource.media_resource.id}`);
            } else if (+res.error.graphQLErrors[0].extensions.error_code === +STATUS_CODE.NOTFOUND) {
              setHelperState({
                ...helperState,
                message:
                  translate('Themantic must starts with') +
                  ' ' +
                  'DS, MS, PS, CS, DB, MB, PB, CB' +
                  ' ' +
                  translate('and end with 1 or 2 digit number'),
              });
            } else if (+res.error.graphQLErrors[0].extensions.error_code === +STATUS_CODE.PAYLOADTOOLARGE) {
              setHelperState({
                ...helperState,
                message: translate('Upload file cannot larger than 5MB'),
              });
            } else {
              handleAgreeCancelForm();
              console.error(res.error);
            }
          })
          .catch((err) => console.error(err));
        return;
    }
  };

  const filledAllRequiredFields = () => {
    const isCommonFieldsFilled =
      formState.format && formState.title && formState.description && formState.language && formState.hasEdited;
    if ([MediaResourcesFormats.video_link, MediaResourcesFormats.web_link].includes(formState.format)) {
      return isCommonFieldsFilled && formState.link;
    }
    if ([MediaResourcesFormats.pdf, MediaResourcesFormats.image].includes(formState.format) && !props.editMode) {
      return isCommonFieldsFilled && formState.files && formState?.files[0]?.size <= maxUploadSize;
    }
    if ([MediaResourcesFormats.pdf, MediaResourcesFormats.image].includes(formState.format) && props.editMode) {
      if (isCommonFieldsFilled && !formState.files) return true;
      if (isCommonFieldsFilled && formState.files) return formState?.files[0]?.size <= maxUploadSize;
    } else return isCommonFieldsFilled;
  };

  /*Const*/

  const canShowUploadFrame =
    [MediaResourcesFormats.image, MediaResourcesFormats.pdf].includes(formState.format) && helperState.isEditing;

  useEffect(() => {
    if (!props.editMode) return;
    const prepState: State = initialFormState;
    prepState.title = props.mediaItem.title;
    prepState.thematic_keywords = props.mediaItem.thematics.map((item: IThematic) => item.keyword).join(', ');
    prepState.format = props.mediaItem.format;
    prepState.language = props.mediaItem.language;
    prepState.description = props.mediaItem.description;
    prepState.link = props.mediaItem.link;

    setFormState({ ...prepState });
    let fileInstructionMessage: string;
    if (props.mediaItem.format === MediaResourcesFormats.image)
      fileInstructionMessage = 'File format must be either JPEG, PNG';
    else if (props.mediaItem.format === MediaResourcesFormats.pdf)
      fileInstructionMessage = 'Le format du fichier doit être PDF et ne pas dépasser 5Mo.';
    else fileInstructionMessage = 'Download content';
    setHelperState({
      ...helperState,
      isEditing: false,
      fileInstruction: fileInstructionMessage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.formWrapper}>
      {!helperState.isEditing && (
        <Button className={`${styles.editBtn}`} onClick={handleSwitchEditing}>
          <Icon name='pencil alternate' className={styles.pencilIcon} />
          <span className={`topLevelText buttonText ${styles.editText}`}>{translate('Edit')}</span>
        </Button>
      )}
      <div className={styles.formContainer}>
        <form className={styles.formMediaResource}>
          {helperState.message && (
            <p
              className={
                helperState.message === FormMessages.Success ? 'textInfoFormMessage' : 'textDangerFormMessage'
              }>
              {translate(helperState.message)}
            </p>
          )}
          <div className={`${styles.formField} `}>
            <div className={` ${styles.labelWrapper} `}>
              <label className={styles.label}>
                {translate('Title')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={styles.inputField}
              readOnly={!helperState.isEditing}
              name='title'
              type='text'
              placeholder={translate('Resources name')}
              value={formState.title}
              onChange={(e) => handleOnChange(e)}
            />
          </div>

          <div className={`${styles.formField} `}>
            <div className={` ${styles.labelWrapper} `}>
              <label className={styles.label}>
                {translate('Format')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>

            <div id='customedDropDownID'>
              <Dropdown
                className={`${styles.customDropdown}`}
                placeholder={translate('Web link')}
                search
                selection
                compact
                icon={helperState.isEditing && !props.editMode ? IconEnum.dropdown : {}}
                disabled={!helperState.isEditing || props.editMode}
                name='format'
                value={formState.format}
                // options={translateDropDownOption(
                //   removeFirstItem(mediaResourcesFormat),
                //   translate
                // )}
                options={translateDropDownOption(mediaResourcesInputFormat, translate)}
                onChange={(e, { name, value }) => handleSelectDropDown(name, value)}
              />
            </div>
          </div>

          <div className={`${styles.formField} `}>
            <div className={` ${styles.labelWrapper} `}>
              <label className={styles.label}>{translate('Thematic', { count: 2 })}</label>
            </div>
            <input
              className={styles.inputField}
              readOnly={!helperState.isEditing}
              name='thematic_keywords'
              type='text'
              placeholder='MB3, PB2, PS2...'
              value={formState.thematic_keywords}
              onChange={(e) => handleOnChange(e)}
            />
          </div>

          <div className={`${styles.formField} `}>
            <div className={` ${styles.labelWrapper} `}>
              <label className={styles.label}>
                {translate('Language')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <div id='customedDropDownID'>
              <Dropdown
                placeholder={translate('Language')}
                search
                selection
                compact
                name='language'
                icon={helperState.isEditing ? IconEnum.dropdown : {}}
                disabled={!helperState.isEditing}
                value={formState.language}
                options={translateDropDownOption(languages_without_all_choice, translate)}
                className={`${styles.customDropdown}`}
                onChange={(e, { name, value }) => handleSelectDropDown(name, value)}
              />
            </div>
          </div>

          <div className={`${styles.formField} `}>
            <div className={` ${styles.labelWrapper} `}>
              <label className={styles.label}>
                {translate('Description')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            {helperState.isEditing ? (
              <input
                className={styles.inputField}
                readOnly={!helperState.isEditing}
                name='description'
                type='text'
                placeholder={translate('Media resource description')}
                value={formState.description}
                onChange={(e) => handleOnChange(e)}
              />
            ) : (
              <div>{formState.description}</div>
            )}
          </div>

          {props.editMode &&
          !helperState.isEditing &&
          [MediaResourcesFormats.pdf, MediaResourcesFormats.image].includes(formState.format) ? (
            <></>
          ) : (
            <div className={`${styles.formField} `}>
              <div className={` ${styles.labelWrapper} `}>
                <label className={styles.label}>
                  {translate('Content')}
                  {helperState.isEditing && <sup className={styles.sup}>*</sup>}
                </label>
              </div>
              {[MediaResourcesFormats.video_link, MediaResourcesFormats.web_link].includes(formState.format) ? (
                <>
                  {helperState.isEditing ? (
                    <input
                      className={styles.inputField}
                      readOnly={!helperState.isEditing}
                      name='link'
                      type='text'
                      placeholder={translate('Please paste the link here')}
                      value={formState.link}
                      onChange={(e) => handleOnChange(e)}
                    />
                  ) : (
                    <a className={styles.accessLinkText} href={formState.link} target='_blank' rel='noreferrer'>
                      {formState.link}
                    </a>
                  )}
                </>
              ) : (
                <div className={`${styles.inputField} ${styles.fileInstructionText}`}>
                  {translate(helperState.fileInstruction)}
                </div>
              )}
            </div>
          )}

          {canShowUploadFrame && (
            <UploadMediaFrame
              setFiles={(file) => setFormState({ ...formState, hasEdited: true, files: file })}
              files={formState.files}
              formatChosen={formState.format}
            />
          )}
        </form>
        <div className={styles.rightGroup}>
          {helperState.isEditing ? (
            <>
              <Button
                className={`${styles.btnSave} ${filledAllRequiredFields() && styles.activeSave}`}
                disabled={!filledAllRequiredFields()}
                onClick={hadleSubmit}>
                <span className='buttonText'>{translate('Save')}</span>
              </Button>
              <Button className={`${styles.btnCancel}`} onClick={handleCancelButton}>
                <span className='buttonText'>{translate('Cancel')}</span>
              </Button>
            </>
          ) : (
            <>
              {props.editMode &&
                [MediaResourcesFormats.image, MediaResourcesFormats.pdf].includes(props.mediaItem.format) && (
                  <PreviewFile
                    createDate={props.mediaItem.created_at}
                    fileLink={props.mediaItem.link}
                    format={props.mediaItem.format}
                  />
                )}
            </>
          )}
        </div>
      </div>

      {helperState.isModalShowed && (
        <AlertModal
          title={translate('Confirm cancel')}
          contentQuestion={[
            {
              text: translate('Do you want to undo?'),
              style: AlertModalContentQuestionStyle.BASIC,
            },
          ]}
          content={translate('You will be return to previous page with unsaved data')}
          cancel={() => setHelperState({ ...helperState, isModalShowed: false })}
          agree={handleAgreeCancelForm}
        />
      )}
    </div>
  );
};

export default EditableMediaResource;
