import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Modal } from 'semantic-ui-react';

import styles from './modalCustom.module.scss';

interface Props {
  actions?: Array<ReactNode>;
  content?: ReactNode;
  header?: ReactNode;
  handleClose: () => void;
  open: boolean;
}

const ModalCustom: FC<Props> = ({ actions, content, handleClose, header, open }: Props) => {
  const { t: translate } = useTranslation();

  const closeIcon: ReactNode = <Icon name='close' className={styles.closeIcon} />;

  const defaultHeader: ReactNode = <h1 className={`${styles.title} topLevelText`}>{translate('Confirm cancel')}</h1>;

  return (
    <Modal
      closeIcon={closeIcon}
      closeOnDimmerClick={false}
      dimmer={{ className: `${styles.dimmerCus}` }}
      onClose={handleClose}
      open={open}>
      <Modal.Header className={styles.headerWrapper} content={!header ? defaultHeader : header} />
      <Modal.Content className={styles.contentWrapper}>
        <Modal.Description children={content} />
      </Modal.Content>
      <Modal.Actions actions={actions} className={styles.actionWrapper} />
    </Modal>
  );
};

export default ModalCustom;
