/* QUERY */

export const COACHTEAM_detailCoachTeam = `
query ($program_id: ID!) {
    detailCoachTeam (
        program_id: $program_id
    ) {
        status_code
        coach_team {
            id
            name
            coaches {
                id
                user_infos {
                    id
                    email
                    first_name
                    last_name
                    department
                    position
                }
            }
        }
    }
}
`;

/* MUTATION */
export const COACHTEAM_updateCoachTeam = `
mutation (
    $id: ID!,
    $name: String!,
    $coach_ids: [ID!]!
) {
    updateCoachTeam (
        id: $id,
        name: $name,
        coach_ids: $coach_ids
    ) {
        status_code
        coach_team {
            id
            name
        }
    }
}
`;
