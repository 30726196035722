import { useNavigate } from 'react-router-dom';
import { token, token_name } from '../config/tokenConstant';

const useLogOut = () => {
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem(token);
    localStorage.removeItem(token_name);
    navigate('/');
  };

  return logOut;
};

export default useLogOut;
