export const wholeWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

export const dateOptions = [
  { key: 1, text: 'Lundi', value: 1 },
  { key: 2, text: 'Mardi', value: 2 },
  { key: 3, text: 'Mercredi', value: 3 },
  { key: 4, text: 'Jeudi', value: 4 },
  { key: 5, text: 'Vendredi', value: 5 },
  { key: 6, text: 'Samedi', value: 6 },
  { key: 7, text: 'Dimanche', value: 7 },
];

const dateMap = new Map([
  [1, 'Lundi'],
  [2, 'Mardi'],
  [3, 'Mercredi'],
  [4, 'Jeudi'],
  [5, 'Vendredi'],
  [6, 'Samedi'],
  [7, 'Dimanche'],
]);

export const dateToStringConvertor = (array: Array<number>): Array<string> => {
  const result: Array<string> = [];
  for (let item of array) {
    result.push(dateMap.get(item));
  }
  return result;
};

/*****/
export const mapDateToContinuosArray = (raw: Array<number>): Array<number> => {
  const processedArr = [];
  for (let i = raw[0]; i <= raw[1]; i++) {
    processedArr.push(i);
  }
  return processedArr;
};
