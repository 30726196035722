import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ClientDetailPage from '../../pages/ClientDetailPage/ClientDetailPage';
import ClientsListPage from '../../pages/ClientsListPage/ClientsListPage';
import CoachDetailPage from '../../pages/CoachDetailPage/CoachDetailPage';
import CoachListPage from '../../pages/CoachListPage/CoachListPage';
import CreateClientPage from '../../pages/CreateClientPage/CreateClientPage';
import CreateCoachPage from '../../pages/CreateCoachPage/CreateCoachPage';
import CreateMediaResourcePage from '../../pages/CreateMediaResourcePage/CreateMediaResourcePage';
import CreateProgramPage from '../../pages/CreateProgramPage/CreateProgramPage';
import MediaResourcesDetailPage from '../../pages/MediaResourcesDetailPage/MediaResourcesDetailPage';
import MediaResourcesListPage from '../../pages/MediaResourcesListPage/MediaResourcesListPage';
import ProgramDetailPage from '../../pages/ProgramDetailPage/ProgramDetailPage';
import ProgramsListPage from '../../pages/ProgramsListPage/ProgramsListPage';
import Dashboard from '../Dashboard/Dashboard';
import CreateClientInfo from '../CreateClientInfo/CreateClientInfo';
import CreateClientProjectDirector from '../CreateClientProjectDirector/CreateClientProjectDirector';
import ClientDetailInfo from '../ClientDetailInfo/ClientDetailInfo';
import ClientDetailProjectDirector from '../ClientDetailProjectDirector/ClientDetailProjectDirector';

const DashboardRouter: React.FC = () => {
  return (
    <>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='createclient' element={<CreateClientPage />}>
          <Route path='info' element={<CreateClientInfo />} />
          <Route path='manager' element={<CreateClientProjectDirector />} />
        </Route>
        <Route path='createprogram' element={<CreateProgramPage />} />
        <Route path='clientlist' element={<ClientsListPage />} />
        <Route path='client-detail' element={<ClientDetailPage />}>
          <Route path='info/:id' element={<ClientDetailInfo />} />
          <Route path='manager/:id' element={<ClientDetailProjectDirector />} />
        </Route>
        <Route path='programslist' element={<ProgramsListPage />} />
        <Route path='program-detail/:id' element={<ProgramDetailPage />} />
        <Route path='createcoach' element={<CreateCoachPage />} />
        <Route path='coach-detail' element={<CoachDetailPage />} />
        <Route path='coachsList' element={<CoachListPage />} />
        <Route path='resourcelist' element={<MediaResourcesListPage />} />
        <Route path='addresource' element={<CreateMediaResourcePage />} />
        <Route path='media-resources-detail' element={<MediaResourcesDetailPage />} />
      </Routes>
    </>
  );
};

export default React.memo(DashboardRouter);
