class Validator {
  //properties
  public static specialCharacter = /[!@#$%^&*()_+\\=\\[\]{};:"\\|,.<>\\/?•¨ˆ`´''€$£¥₩§¿¡‰]+/;

  //methods
  public static checkEmpty(inputValue: string): boolean {
    if (!inputValue || inputValue.trim() === '') {
      return true; //YES it is empty
    }
    return false;
  }

  public static checkHasSpecialChar(inputValue: string): boolean {
    //YES  has special characters
    return this.specialCharacter.test(inputValue);
  }

  public static checkLength(inputValue: string, length: number, limit: string): boolean {
    //YES  length < min
    switch (limit) {
      case 'min':
        return inputValue.length < length ? true : false;
      case 'max':
        return inputValue.length > length ? true : false;
      default:
        console.warn('error checkInputLength');
        return false;
    }
  }

  public static checkHasUpperCase(inputValue: string): boolean {
    const regrex = /[A-Z]/;
    return regrex.test(inputValue);
  }

  public static checkHasLowerCase(inputValue: string): boolean {
    const regrex = /[a-z]/;
    return regrex.test(inputValue);
  }

  public static checkHasNumber(inputValue: string): boolean {
    const regrex = /\d+/;
    return regrex.test(inputValue);
  }

  public static checkValidEmail(inputValue: string): boolean {
    const regrex = /\S+@\S+\.\S+/;
    return regrex.test(inputValue);
  }
}

export default Validator;
