import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { IMediaResource, IThematic } from '../../models/type';
import { LANGUGAGE_map } from '../../config/helperConstants';
import styles from './mediaResourcesListPage.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MediaResources_Actions, MediaResourcesFormats } from '../../models/enum';
import { Link } from 'react-router-dom';

interface Props {
  mediaResourceItem: IMediaResource;
  checked: boolean;
}

const MediaResourceRow: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const joinKeywordValues = (thematicArray: Array<IThematic>) => {
    const flatArray: Array<string> = thematicArray.map((item: IThematic) => {
      return item.keyword;
    });
    return flatArray.join(', ');
  };

  const handleOnChange = (item: IMediaResource) => {
    dispatch({
      type: MediaResources_Actions.select_media_item,
      payload: item,
    });
  };

  return (
    <tr className={`${styles.row} ${props.checked && styles.selected}`}>
      <td>
        <Checkbox onChange={() => handleOnChange(props.mediaResourceItem)} checked={props.checked} />
      </td>
      <td>
        <Link to={`/media-resources-detail/?${props.mediaResourceItem.id}`}>
          <span className={styles.textName}>{props.mediaResourceItem.title}</span>
        </Link>
      </td>
      <td className={props.mediaResourceItem.format === MediaResourcesFormats.pdf ? 'topLevelText' : 'secondLevelText'}>
        {translate(props.mediaResourceItem.format)}
      </td>
      <td>{joinKeywordValues(props.mediaResourceItem.thematics)}</td>
      <td>{translate(LANGUGAGE_map.get(props.mediaResourceItem.language))}</td>
    </tr>
  );
};

export default React.memo(MediaResourceRow);
