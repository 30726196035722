import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout';
import ForgetPasswordPage from '../../pages/ForgetPassword/ForgetPasswordPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import ResetPasswordPage from '../../pages/ResetPasswordPage/ResetPasswordPage';
import PrivateRoute from './PrivateRoute';
import DashboardRouter from './DashboardRouter';

const Router = () => (
  <Routes>
    <Route index element={<LoginPage />} />
    <Route path='login' element={<LoginPage />} />
    <Route path='forgetpassword' element={<ForgetPasswordPage />} />
    <Route path='reset-password' element={<ResetPasswordPage />} />
    <Route
      path='/'
      element={
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      }>
      <Route path='/*' element={<DashboardRouter />} />
    </Route>
  </Routes>
);

export default Router;
