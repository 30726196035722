import React, { useCallback, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styles from './createDirector.module.scss';
import AlertModal from '../Common/AlertModal/AlertModal';
import { useMutation, useQuery } from 'urql';
import { DIRECTOR_create } from '../../services/apis/directorApi';
import { AlertModalContentQuestionStyle, Client_Action, STATUS_CODE } from '../../models/enum';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CLIENT_get_project_director } from '../../services/apis/clientsApi';

const CreateDirector: React.FC = () => {
  /*PROPS*/
  const [helperState, setHelperState] = useState({
    resMessage: null,
    modal: false,
    formBackup: null,
    formBehavior: {
      isEditing: true,
      editingMode: false,
    },
  });

  const [formState, setFormState] = useState({
    projectDirector_id: null,
    email: '',
    firstName: '',
    lastName: '',
    department: '',
    position: '',
    isEdited: false,
  });

  const [clientProjectDirectorResult] = useQuery({
    query: CLIENT_get_project_director,
    variables: { id: window.location.pathname.split('/').pop() },
    requestPolicy: 'cache-and-network',
  });

  const [directorCreateResult, executeCreateDirector] = useMutation(DIRECTOR_create);
  // const [directorUpdateResult, executeUpdateDirector] =
  //   useMutation(DIRECTOR_update);

  /*METHODS*/
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const handleSwitchEditing = useCallback(() => {
    setHelperState({
      ...helperState,
      resMessage: '',
      formBackup: formState,
      formBehavior: {
        ...helperState.formBehavior,
        isEditing: true,
      },
    });
  }, [formState, helperState]);

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.currentTarget.name]: event.currentTarget.value,
      isEdited: true,
    });
  };

  const handleModalDisplay = useCallback(() => {
    if (!formState.isEdited) {
      setHelperState({
        ...helperState,
        resMessage: '',
        formBehavior: { ...helperState.formBehavior, isEditing: false },
      });
      return;
    }
    setHelperState({ ...helperState, modal: true });
  }, [formState, helperState]);

  const handleAgreeCancelForm = useCallback(() => {
    setHelperState({
      ...helperState,
      modal: false,
      formBehavior: { ...helperState.formBehavior, isEditing: false },
    });
    if (helperState.formBehavior.editingMode) {
      setFormState(helperState.formBackup);
      return;
    }
    setFormState({
      projectDirector_id: null,
      email: '',
      firstName: '',
      lastName: '',
      department: '',
      position: '',
      isEdited: false,
    });
  }, [helperState]);

  const handleSubmit = useCallback(() => {
    /*For create and update project director*/
    executeCreateDirector({
      client_id: window.location.pathname.split('/').pop(),
      email: formState.email,
      first_name: formState.firstName,
      last_name: formState.lastName,
      department: formState.department ? formState.department : 'n/a',
      position: formState.position ? formState.position : 'n/a',
    })
      .then((res) => {
        if (+res.data?.addProjectDirector.status_code === +STATUS_CODE.SUCCESS) {
          setHelperState({
            ...helperState,
            resMessage: translate('Success'),
            formBehavior: { isEditing: false, editingMode: true },
          });
          setFormState({
            ...formState,
            projectDirector_id: res.data.addProjectDirector.project_director.id,
          });
        } else if (res.error.graphQLErrors[0].extensions.error_code === STATUS_CODE.BADREQUEST) {
          setHelperState({
            ...helperState,
            resMessage: translate('Must create client first'),
            formBehavior: { ...helperState.formBehavior, isEditing: false },
          });
        } else {
          setHelperState({
            ...helperState,
            resMessage: translate('Unable to create'),
            formBehavior: { ...helperState.formBehavior, isEditing: false },
          });
        }
        return;
      })
      .catch((err) => console.error(err));
  }, [executeCreateDirector, formState, helperState, translate]);

  const canActiveSubmitBtn = (): boolean => {
    if (directorCreateResult.fetching) return false;
    if (formState.email && formState.firstName && formState.lastName && formState.isEdited) return true;
    return false;
  };

  /*EFFECT*/

  useEffect(() => {
    //No project director => set editingmode false (mean creating mode)
    if (!clientProjectDirectorResult.data?.detailClient.client.project_director) return;
    //has director
    setHelperState({
      ...helperState,
      formBehavior: { isEditing: false, editingMode: true },
    });
    dispatch({
      type: Client_Action.set_client_name,
      payload: clientProjectDirectorResult.data?.detailClient.client.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientProjectDirectorResult.data]);

  useEffect(() => {
    if (!clientProjectDirectorResult.data?.detailClient.client.project_director) return;
    setFormState({
      ...formState,
      projectDirector_id: clientProjectDirectorResult.data.detailClient.client.project_director.id,
      email: clientProjectDirectorResult.data.detailClient.client.project_director.email,
      firstName: clientProjectDirectorResult.data.detailClient.client.project_director.first_name,
      lastName: clientProjectDirectorResult.data.detailClient.client.project_director.last_name,
      department:
        clientProjectDirectorResult.data.detailClient.client.project_director.department === 'n/a'
          ? ''
          : clientProjectDirectorResult.data.detailClient.client.project_director.department,
      position:
        clientProjectDirectorResult.data.detailClient.client.project_director.position === 'n/a'
          ? ''
          : clientProjectDirectorResult.data.detailClient.client.project_director.position,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientProjectDirectorResult.data]);

  return (
    <div className={styles.formWrapper}>
      <h1 className={styles.formTitle}>{translate('Project director')}</h1>
      <p className={styles.textResMessage}>{helperState.resMessage}</p>
      {!helperState.formBehavior.isEditing && (
        <Button className={`${styles.editBtn}`} onClick={handleSwitchEditing}>
          <Icon name='pencil alternate' className={styles.pencilIcon} />
          <span className={`topLevelText buttonText ${styles.editText}`}>{translate('Edit')}</span>
        </Button>
      )}
      <div className={styles.formContainer}>
        <form className={styles.formCreateDirector} id='directorForm'>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                Email
                {helperState.formBehavior.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <div className={styles.inputSearchWrapper}>
              <input
                className={`${styles.inputField} ${helperState.formBehavior.isEditing && styles.w66}`}
                name='email'
                type='email'
                placeholder='nom@email.com'
                readOnly={helperState.formBehavior.isEditing ? false : true}
                value={formState.email}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formField}>
              <div className={styles.labelWrapper}>
                <label className={styles.label}>
                  {translate('First name')}
                  {helperState.formBehavior.isEditing && <sup className={styles.sup}>*</sup>}
                </label>
              </div>
              <input
                className={`${styles.inputField} ${styles.w42}`}
                name='firstName'
                type='text'
                placeholder={translate('First name')}
                readOnly={helperState.formBehavior.isEditing ? false : true}
                onChange={(e) => handleOnChange(e)}
                value={formState.firstName}
              />
            </div>
            <div className={`${styles.formField} ${styles.positioningInput} `}>
              <div className={`${styles.labelWrapper} `}>
                <label className={styles.label}>
                  {translate('Last name')}
                  {helperState.formBehavior.isEditing && <sup className={styles.sup}>*</sup>}
                </label>
              </div>
              <input
                className={`${styles.inputField}`}
                name='lastName'
                type='text'
                placeholder={translate('Family name')}
                readOnly={helperState.formBehavior.isEditing ? false : true}
                onChange={(e) => handleOnChange(e)}
                value={formState.lastName}
              />
            </div>
          </div>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>{translate('Job')}</label>
            </div>
            <input
              className={styles.inputField}
              name='department'
              type='text'
              placeholder={translate('Job')}
              readOnly={helperState.formBehavior.isEditing ? false : true}
              onChange={(e) => handleOnChange(e)}
              value={formState.department === 'n/a' ? '' : formState.department}
            />
          </div>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>{translate('Position')}</label>
            </div>
            <input
              className={styles.inputField}
              name='position'
              type='text'
              placeholder={translate('Title')}
              readOnly={helperState.formBehavior.isEditing ? false : true}
              onChange={(e) => handleOnChange(e)}
              value={formState.position === 'n/a' ? '' : formState.position}
            />
          </div>
        </form>
        <div className={styles.rightGroup}>
          {helperState.formBehavior.isEditing && (
            <>
              <Button
                type='button'
                disabled={!canActiveSubmitBtn()}
                className={`${styles.btnSave} ${canActiveSubmitBtn() && styles.activeSave} ${
                  directorCreateResult.fetching && styles.disable
                }`}
                onClick={handleSubmit}>
                <span className='buttonText topLevelText'>{translate('Save')}</span>
              </Button>
              <Button className={styles.btnCancel} onClick={handleModalDisplay}>
                <span className='buttonText topLevelText'>{translate('Cancel')}</span>
              </Button>
            </>
          )}
        </div>
      </div>

      {helperState.modal && (
        <AlertModal
          title={translate('Confirm cancel')}
          contentCenter={true}
          contentQuestion={[
            {
              text: translate('Do you want to undo?'),
              style: AlertModalContentQuestionStyle.BASIC,
            },
          ]}
          content={translate('You will be return to previous page with unsaved data')}
          agree={handleAgreeCancelForm}
          cancel={() => setHelperState({ ...helperState, modal: false })}
        />
      )}
    </div>
  );
};

export default CreateDirector;
