import React from 'react';
import styles from './Chart.module.scss';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
      color: '#1E2947',
      clamp: false,
      anchor: 'end',
      align: 'end',
      offset: 0,
      padding: {
        right: 20,
      },
    },
    tooltip: {
      callbacks: {
        labelColor: function (tooltip) {
          return {
            borderColor: 'rgba(0,0,0,0)',
            backgroundColor: tooltip.dataset.backgroundColor as string,
            borderWidth: 0,
          };
        },
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      border: { display: true },
      grid: {
        drawOnChartArea: false,
        display: true,
      },
      ticks: {
        display: true,
      },
    },
    y: {
      beginAtZero: true,
      border: {
        display: true,
      },
      grid: {
        drawOnChartArea: true,
        display: true,
      },
      suggestedMax: 10,
      ticks: {
        display: true,
      },
    },
  },
};

interface Props {
  renderData: any;
}
const Chart: React.FC<Props> = (props) => {
  //Props

  //methods
  const { t: translate } = useTranslation();

  switch (props.renderData.chartType) {
    case 'bar':
      return (
        <div className={styles.barWrapper}>
          <Bar data={props.renderData.data} width={100} height={300} options={options} />
        </div>
      );
    case 'horizontal_stacked_bar':
      const { number_of_started, number_of_done, number_of_unassigned, total } = props.renderData.data;
      //calculate % of Done and started
      const percentageOfStartedAndDone = (100 * (number_of_started + number_of_done)) / total + '%';
      //calculate % of Done
      const percentageOfDone = (100 * number_of_done) / (number_of_started + number_of_done) + '%';

      return (
        <div>
          <div className={styles.topBlock}>
            <div className={styles.totalBlock}>
              <span className={styles.totalNumber}>{total}</span>
              <span className={styles.totalText}>{translate('Course')}</span>
            </div>
            <div className={styles.legendBlock}>
              <div className={styles.legendBlock_topRow}>
                <div className={styles.legendItem}>
                  <div className={`${styles.bar_legendColor} ${styles.startedColor}`}></div>
                  <p>
                    <span className={styles.legendNumber}>{number_of_started}</span>
                    <span className={styles.legendText}>
                      {translate('Started', {
                        context: 'male',
                        count: number_of_started,
                      })}
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.legendBlock_botRow}>
                <div className={styles.legendItem}>
                  <div className={`${styles.bar_legendColor} ${styles.doneColor}`}></div>
                  <p>
                    <span className={styles.legendNumber}>{number_of_done}</span>
                    <span className={styles.legendText}>
                      {translate('Completed', {
                        context: 'male',
                        count: number_of_done,
                      })}
                    </span>
                  </p>
                </div>
                <div className={styles.legendItem}>
                  <div className={`${styles.bar_legendColor} ${styles.unassignedColor}`}></div>
                  <p>
                    <span className={styles.legendNumber}>{number_of_unassigned}</span>
                    <span className={styles.legendText}>
                      {translate('Unassigned', {
                        context: 'male',
                        count: number_of_unassigned,
                      })}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.fullBar}>
            <div className={styles.startedAndDone} style={{ width: percentageOfStartedAndDone }}>
              <div className={styles.done} style={{ width: percentageOfDone }}></div>
            </div>
          </div>
        </div>
      );

    default:
      return (
        <>
          <p>No chart type</p>
        </>
      );
  }
};

export default Chart;
