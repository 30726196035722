export const MEDIARESOURCES_listResources = `
query ($count: Int,
    $page: Int!,
    $search_by: MediaResourcesSearchBy,
    $search_value: String,
    $filter_format: MediaResourcesFormats,
    $filter_language: String,
    $order_by: ListMediaResourcesSortableColumn,
    $direction: SortDirection) {
    listMediaResources(
    count: $count,
    page: $page,
    search_by: $search_by,
    search_value: $search_value,
    filter_format : $filter_format,
    filter_language : $filter_language,
    order_by : $order_by,
    direction : $direction
    ) {
        status_code
        media_resources {
            id
            title
            description
            link
            language
            format
            created_at
            updated_at
            thematics {
                id
                user_position
                keyword
                title
            }
        }
    }
}
`;

export const MEDIARESOURCES_fetch_detail = `
query($id: ID!) {
    getMediaResource(
        id: $id
    ) {
        status_code
        media_resource {
            id
            title
            description
            link
            language
            format
            created_at
            updated_at
            thematics {
                id
                user_position
                keyword
                title
            }
        }
    }
}
`;

/*MUTATION*/

export const MEDIARESOURCES_create_media_resource = `
mutation ($format: MediaResourcesFormats!, $title: String!, $description: String!, $language: String!, $link: String, $file: Upload, $thematic_keywords: String) {
    createMediaResource(
        format: $format,
        title: $title,
        description: $description,
        language: $language,
        link: $link,
        file: $file,
        thematic_keywords: $thematic_keywords
    ) {
        status_code
        media_resource {
            id
            title
            description
            language
            format
            created_at
            link
            updated_at
        }
    }
}
`;

export const MEDIARESOURCES_delete_resources = `
mutation ($ids : [ID!]!) {
    deleteMediaResources(
        ids: $ids
    ) {
        status_code
    }
}
`;

//MISSING ID ?
export const MEDIARESOURCES_update_media_resource = `
mutation ($id : ID!, $title : String!, $description : String!, $language: String!, $link : String, $file : Upload, $thematic_keywords : String ) {
    updateMediaResource (
      id : $id,
      title : $title,
      description: $description,
      language: $language,
      link: $link,
      file: $file
      thematic_keywords: $thematic_keywords
    ) {
      status_code
      media_resource {
        id
        title
        description
        language
        format
        created_at
        link
        updated_at
      }
    }
  }
`;
