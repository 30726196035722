import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Segment } from 'semantic-ui-react';
import { maxUploadSize } from '../../config/helperConstants';
import { Helper_Actions, IconEnum, MediaResourcesAcceptType, MediaResourcesFormats } from '../../models/enum';
import { palette } from '../../theme/palette';
import styles from './uploadMediaFrame.module.scss';

interface Props {
  setFiles: (file: any) => void;
  files: any;
  formatChosen: MediaResourcesFormats;
}

const UploadMediaFrame: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const uploadComponentStore = useSelector((state: any) => state.helperReducer.uploadComponent);

  const [helperState, setHelperState] = useState({
    showErrorFormatType: false,
    errorFormatMessage: '',
  });
  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const onDrop = (acceptedFiles: any) => {
    props.setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
    //CHECK FILE SIZE
    if (acceptedFiles[0].size > maxUploadSize) {
      setHelperState({
        ...helperState,
        showErrorFormatType: true,
        errorFormatMessage: 'File is not allowed',
      });
      dispatch({
        type: Helper_Actions.set_icon_name,
        payload: {
          icon: IconEnum['exclamation triangle'],
          color: palette.secondaryRedMPB,
        },
      });
      return;
    }

    //CHECK FILE FORMAT
    if (props.formatChosen === MediaResourcesFormats.pdf && acceptedFiles[0].type !== MediaResourcesAcceptType.pdf) {
      setHelperState({
        ...helperState,
        showErrorFormatType: true,
        errorFormatMessage: 'Invalid file please select a PDF file',
      });
      dispatch({
        type: Helper_Actions.set_icon_name,
        payload: {
          icon: IconEnum['exclamation triangle'],
          color: palette.secondaryRedMPB,
        },
      });
      return;
    }

    if (
      props.formatChosen === MediaResourcesFormats.image &&
      ![MediaResourcesAcceptType.jpeg, MediaResourcesAcceptType.jpg, MediaResourcesAcceptType.png].includes(
        acceptedFiles[0].type,
      )
    ) {
      setHelperState({
        ...helperState,
        showErrorFormatType: true,
        errorFormatMessage: 'Invalid file please select a JPG file',
      });
      dispatch({
        type: Helper_Actions.set_icon_name,
        payload: {
          icon: IconEnum['exclamation triangle'],
          color: palette.secondaryRedMPB,
        },
      });
      return;
    }
    setHelperState({
      ...helperState,
      showErrorFormatType: false,
      errorFormatMessage: '',
    });

    let icon = '';
    let color = '';

    if (props.formatChosen === MediaResourcesFormats.image) {
      icon = IconEnum['image outline'];
      color = palette.secondaryBlue2;
    } else if (props.formatChosen === MediaResourcesFormats.pdf) {
      icon = IconEnum['file pdf outline'];
      color = palette.pdfRed;
    } else {
      icon = IconEnum['folder outline'];
      color = palette.secondaryBlue2;
    }
    dispatch({
      type: Helper_Actions.set_icon_name,
      payload: {
        icon: icon,
        color: color,
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    return () => {
      dispatch({
        type: Helper_Actions.reset,
      });
    };
  }, [dispatch]);

  return (
    <Segment className={styles.customedSegment}>
      <div className={styles.uploadWrapper} {...getRootProps()}>
        {isDragActive && <div className={styles.layer}></div>}
        <input {...getInputProps()} type='file' name='file' />
        <Icon
          name={uploadComponentStore.iconName}
          className={`${styles.uploadIcon} `}
          style={{ color: uploadComponentStore.iconColor }}
        />

        {helperState.showErrorFormatType ? (
          <>
            <p className={styles.errorMessage}>
              {translate(helperState.errorFormatMessage) + ' '}
              <br />
              {translate('Drag and drop') + ' '}
              <span className={styles.textIllegalFile}>{translate('Click here')}</span>
            </p>
          </>
        ) : (
          <>
            {!!props?.files?.length && console.log(props?.files)}
            {!props?.files?.length && (
              <p className={styles.uploadDesc}>
                {translate('Drag and drop') + ' '}
                <span className={styles.textClickable}>{translate('Click here')}</span>
              </p>
            )}
            {!!props?.files?.length && props?.files[0].size <= maxUploadSize && (
              <p className={styles.uploadDesc}>
                <span className={styles.fileName}>{props.files[0].name + ' '}</span>
                {translate('Ready to be sent')}
              </p>
            )}
          </>
        )}
      </div>
    </Segment>
  );
};

export default React.memo(UploadMediaFrame);
