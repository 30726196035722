export const DASHBOARD_get_main_dashboard = `
query{
    getStaffMainDashboard {
        status_code
        sessions {
            progress {
                number_of_done
                total                
            }
            by_month {
                done {
                    month
                    total
                }
                interrupted {
                    month
                    total
                }
            }
        }
        clients {
            month
            total
        }
        courses {
            number_of_unassigned
            number_of_started
            number_of_done
            total            
        }
        number_of_coaches
        number_of_coachees
    }
}
`;
