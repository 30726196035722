import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Icon, Pagination } from 'semantic-ui-react';
import TableDeleteButton from '../../components/Common/TableDeleteButton/TableDeleteButton';
import SearchSimple from '../../components/SearchSimple/SearchSimple';
import { mediaResourcesFormat, languages, mediaSearchByDropdownOptions } from '../../config/helperConstants';
import { translateDropDownOption } from '../../utilities/helper';
import styles from './mediaResourcesListPage.module.scss';
import { MEDIARESOURCES_delete_resources } from '../../services/apis/mediaResourcesApis';
import { useMutation } from 'urql';
import { AlertModalContentQuestionStyle, STATUS_CODE, MediaResources_Actions } from '../../models/enum';
import AlertModal from '../../components/Common/AlertModal/AlertModal';
import '../../theme/customedDropDown.scss';

interface Props {
  changePage: (page: any) => void;
  selectCriteria: (name: any, criteria: any) => void;
  handleSearchInput: (searchValue: string) => void;
}

const MediaResourcesTableToolBlock: React.FC<Props> = (props: Props) => {
  /*STATE*/

  const [state, setState] = useState({
    showAlertModal: false,
  });
  /*PROPS*/
  const selectedMediaSelector = useSelector((state: any) => state.mediaResourcesReducer.selectedMediaList);
  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [, executeDeleteMediaResources] = useMutation(MEDIARESOURCES_delete_resources);

  const deleteMediaResources = (idsMediaResources: Array<string>) => {
    executeDeleteMediaResources({ ids: idsMediaResources })
      .then((res) => {
        if (res.data.deleteMediaResources.status_code === STATUS_CODE.SUCCESS) {
          setState({ ...state, showAlertModal: false });
          dispatch({
            type: MediaResources_Actions.delete_media_items,
          });
        } else {
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={styles.toolsWrapper}>
      <div className={styles.toolsBlock}>
        <TableDeleteButton
          invisible={!selectedMediaSelector.length}
          onClick={() => setState({ ...state, showAlertModal: true })}
        />
      </div>

      <div className={styles.rightToolsModuleBlock}>
        <div id='customedDropDownID' className={styles.toolsBlock}>
          <Dropdown
            placeholder={translate('Format')}
            search
            selection
            compact
            name='filter_format'
            className={styles.dropDown}
            options={translateDropDownOption(mediaResourcesFormat, translate)}
            onChange={(e, { name, value }) => props.selectCriteria(name, value)}
          />
          <Dropdown
            placeholder={translate('Language')}
            search
            selection
            compact
            name='filter_language'
            className={styles.dropDown}
            options={translateDropDownOption(languages, translate)}
            onChange={(e, { name, value }) => props.selectCriteria(name, value)}
          />
        </div>
        <div className={styles.toolsBlock}>
          <Dropdown
            placeholder={translate('Entitle')}
            search
            selection
            compact
            name='search_by'
            className={styles.dropDown}
            options={translateDropDownOption(mediaSearchByDropdownOptions, translate)}
            onChange={(e, { name, value }) => props.selectCriteria(name, value)}
          />
          <SearchSimple
            placeholder={translate('Enter a title')}
            handleSearch={(searchValue) => props.handleSearchInput(searchValue)}
          />
        </div>
        <div className={styles.toolsBlock}>
          <div className={styles.pagination} id='paginationId'>
            <Pagination
              defaultActivePage={1}
              ellipsisItem={{
                content: <Icon name='ellipsis horizontal' />,
                icon: true,
              }}
              firstItem={null}
              lastItem={null}
              prevItem={{
                content: <Icon name='angle left' />,
                icon: true,
              }}
              nextItem={{
                content: <Icon name='angle right' />,
                icon: true,
              }}
              totalPages={1}
              onPageChange={(event, data) => props.changePage(data)}
            />
          </div>
        </div>
      </div>
      {state.showAlertModal && (
        <AlertModal
          title={translate('Confirm deletetion')}
          contentQuestion={[
            {
              text: translate('SurelyYouWantToDeleteThisMediaResourcePermanently', {
                count: selectedMediaSelector.length,
              }),
              style: AlertModalContentQuestionStyle.BASIC,
            },
          ]}
          content={translate('ClickYesToDeleteThisFilePermanently', {
            count: selectedMediaSelector.length,
          })}
          cancel={() => setState({ ...state, showAlertModal: false })}
          agree={() => deleteMediaResources(selectedMediaSelector)}
        />
      )}
    </div>
  );
};

export default React.memo(MediaResourcesTableToolBlock);
