import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { MaterialIconName } from '../../../models/enum';
import styles from './Annoucement.module.scss';

interface IProps {
  title: string;
  description: string;
  btnTitle: string;
}

const Annoucement: React.FC<IProps> = (props) => {
  /*Methods*/
  const navigate = useNavigate();

  return (
    <div>
      <span className={`${styles.checkIcon} material-icons-outlined`}>{MaterialIconName.check}</span>
      <p className={`${styles.title} topLevelText`}>{props.title}</p>
      <p className={styles.description}>{props.description}</p>
      <Button
        type='button'
        className={`${styles.submitButton}`}
        style={props.btnTitle ? { visibility: 'visible' } : { visibility: 'hidden' }}
        onClick={() => {
          navigate('login');
        }}>
        <span className='topLevelText buttonText'>{props.btnTitle}</span>
      </Button>
    </div>
  );
};

export default React.memo(Annoucement);
