import { Helper_Actions, IconEnum, MaterialIconName } from '../../../models/enum';
import { IHelper_Actions } from '../../../models/type';
import { palette } from '../../../theme/palette';

const initialState = {
  uploadComponent: {
    iconName: IconEnum['cloud upload'],
    uploaded: false,
    iconColor: palette.grey4,
  },
  dashboardLayout: {
    iconName: MaterialIconName.insert_chart,
    title: 'Tableau de bord',
  },
};

export const helperReducer = (state = initialState, action: IHelper_Actions) => {
  switch (action.type) {
    case Helper_Actions.set_icon_name:
      return {
        ...state,
        uploadComponent: {
          ...state.uploadComponent,
          iconName: action.payload.icon,
          iconColor: action.payload.color,
        },
      };
    case Helper_Actions.upload_success:
      return {
        ...state,
        uploadComponent: {
          ...state.uploadComponent,
          iconName: IconEnum.checkmark,
          uploaded: true,
        },
      };
    case Helper_Actions.reset:
      return {
        ...state,
        uploadComponent: {
          ...state.uploadComponent,
          iconName: IconEnum['cloud upload'],
          uploaded: false,
          iconColor: palette.grey4,
        },
      };
    case Helper_Actions.set_dashboard_icon_title:
      return {
        ...state,
        dashboardLayout: {
          ...state.dashboardLayout,
          iconName: action.payload.icon,
          title: action.payload.title,
        },
      };
    default:
      return state;
  }
};
