import React, { useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Grid, Icon, Popup } from 'semantic-ui-react';
import { useMutation, useQuery } from 'urql';
import { generateDevelopmentSessionOptions } from '../../config/helperConstants';
import {
  OfferName_Technical,
  SortDirection,
  ClientOrderableColumn,
  STATUS_CODE,
  AlertModalContentQuestionStyle,
  GRAPHQL_ERRORS,
  FormMessages,
} from '../../models/enum';
import { IOfferExtended, IProgram } from '../../models/type';
import { CLIENT_getClients } from '../../services/apis/clientsApi';
import { PROGRAM_createProgram, PROGRAM_update } from '../../services/apis/programApi';
import { checkNumberInRange } from '../../utilities/helper';
import AlertModal from '../Common/AlertModal/AlertModal';
import useComponentVisble from '../Common/useComponentVisible/ComponentVisible';
import InputSearchResult from '../InputSearchResult/InputSearchResult';
import styles from './editableProgramDetailComp.module.scss';
import { style } from './popupstyle';
import moment from 'moment';

interface Props {
  isEditingMode: boolean;
  program?: IProgram;
  programMangerTotal?: number;
  defaultOffer?: Array<IOfferExtended>;
  showTitle?: boolean;
  setCreateProgramSuccess?: (newProgram: any) => void;
}

const EditableProgramDetailComp: React.FC<Props> = (props: Props) => {
  /*STATE*/

  const [calendarState, setCalendarState] = useState({
    calendarValue: new Date(),
  });

  const [formState, setFormState] = useState({
    programName: '',
    client: '',
    client_id: '',
    created_date: '',
    close_date: '',
    edited: false,
  });

  const [offerConfigState, setOfferConfigState] = useState({
    edited: false,
    flash: {
      offer_id: null,
      number_of_sessions: null,
      duration_of_session: null,
      has_objectives: null,
      has_self_diagnosis: null,
      number_of_courses: null,
    },
    development: {
      offer_id: null,
      number_of_sessions: null,
      duration_of_session: null,
      has_objectives: null,
      has_self_diagnosis: null,
      number_of_courses: null,
    },
    focus: {
      offer_id: null,
      number_of_sessions: null,
      duration_of_session: null,
      has_objectives: null,
      has_self_diagnosis: null,
      number_of_courses: null,
    },
  });

  const [helperState, setHelperState] = useState({
    isEditing: true,
    showModal: false,
    message: '',
    backupForm: {
      formState: null,
      calendarState: null,
      offerConfigState: null,
    },
  });

  /*METHODS*/

  const [queryClientResult, reQueryClient] = useQuery({
    query: CLIENT_getClients,
    variables: {
      page: 1,
      search_name: formState.client,
      order_by: ClientOrderableColumn.NAME,
      direction: SortDirection.ASC,
    },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  const [{ fetching }, executeCreateProgram] = useMutation(PROGRAM_createProgram);

  const [updateProgramResult, executeUpdateProgram] = useMutation(PROGRAM_update);

  const { ref, isComponentShowed, setComponentShowed } = useComponentVisble(false);

  const calendarVisibleControl = useComponentVisble(false);

  /*METHODS*/

  const { t: translate } = useTranslation();

  const handleOnchangeCalendar = (value: any) => {
    setFormState({ ...formState, edited: true });
    setCalendarState({ ...calendarState, calendarValue: value });
    calendarVisibleControl.setComponentShowed(false);
  };

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
      edited: true,
    });
  };

  const handleMapOfferPropToState = useCallback(
    (offerArray: Array<IOfferExtended>) => {
      const tempState = { ...offerConfigState };
      for (let defaultOffer of offerArray) {
        const tempProp = {
          ...tempState[OfferName_Technical[defaultOffer.name]],
          offer_id: props.isEditingMode ? defaultOffer.offer_id : defaultOffer.id,
          number_of_sessions: defaultOffer.number_of_sessions,
          number_of_courses: defaultOffer.number_of_courses ? defaultOffer.number_of_courses : 0,
          duration_of_session: defaultOffer.duration_of_session,
          has_objectives: defaultOffer.has_objectives,
          has_self_diagnosis: defaultOffer.has_self_diagnosis,
        };
        tempState[OfferName_Technical[defaultOffer.name]] = tempProp;
      }
      setOfferConfigState(tempState);
    },
    [offerConfigState, props.isEditingMode], //props.defaultOffer, props.program, helperState.isEditing, offerConfigState.edited
  );

  const handleOnChangeOfferConfig = (e: React.FormEvent<HTMLInputElement>, course: OfferName_Technical) => {
    if (isNaN(+e.currentTarget.value)) return;
    const tempState = {
      ...offerConfigState,
      edited: true,
    };
    tempState[course] = {
      ...tempState[course],
      [e.currentTarget.name]: +e.currentTarget.value,
    };
    setOfferConfigState(tempState);
    setFormState({ ...formState, edited: true });
  };

  const shouldHideConfigPopUp = () => {
    if (!props.isEditingMode) return true;
    if (!helperState.isEditing) return true;
    if (props.program?.has_started) return false;
    return true;
  };

  const handleSwitchEditing = useCallback(() => {
    setHelperState({
      ...helperState,
      isEditing: true,
      message: '',
      backupForm: {
        formState: formState,
        calendarState: calendarState,
        offerConfigState: offerConfigState,
      },
    });
  }, [helperState, formState, calendarState, offerConfigState]);

  const handleAgreeCancelForm = useCallback(() => {
    if (props.isEditingMode) {
      setHelperState({ ...helperState, isEditing: false, showModal: false });
      setFormState(helperState.backupForm.formState);
      setCalendarState(helperState.backupForm.calendarState);
      setOfferConfigState({ ...helperState.backupForm.offerConfigState });
      return;
    }
    handleMapOfferPropToState(props.defaultOffer);
    setFormState({
      ...formState,
      programName: '',
      client: '',
      edited: false,
    });
    setHelperState({ ...helperState, isEditing: false, showModal: false });
  }, [formState, handleMapOfferPropToState, helperState, props.defaultOffer, props.isEditingMode]);

  const renderOffer = (defaultOfferArray: Array<IOfferExtended>) => {
    const canEditOfferConfig: boolean = !props?.program?.has_started && helperState.isEditing;

    return defaultOfferArray.map((item: IOfferExtended, index: number) => {
      const offerName: OfferName_Technical = OfferName_Technical[item.name];
      return (
        <Grid.Row key={index}>
          <Grid.Column computer={4}>
            <span className={`${styles.configTitle} secondLevelText`}>{item.name}</span>
          </Grid.Column>

          {item.name === 'Coaching de développement' ? (
            <Grid.Column computer={4} textAlign='center'>
              {helperState.isEditing ? (
                <Popup
                  content={translate('Program has started and unable to modify offer')}
                  position='right center'
                  style={style}
                  basic
                  disabled={shouldHideConfigPopUp()}
                  trigger={
                    <Dropdown
                      options={generateDevelopmentSessionOptions()}
                      button
                      compact
                      scrolling
                      disabled={!canEditOfferConfig}
                      onChange={(e, { value }) =>
                        setOfferConfigState({
                          ...offerConfigState,
                          development: {
                            ...offerConfigState.development,
                            number_of_sessions: value,
                          },
                        })
                      }
                      value={offerConfigState.development.number_of_sessions}
                      className={styles.dropdown}
                    />
                  }
                />
              ) : (
                <input
                  className={`${styles.inputField} ${styles.configInput}`}
                  name='number_of_sessions'
                  type='text'
                  placeholder='0'
                  maxLength={2}
                  autoComplete='off'
                  readOnly={true}
                  value={offerConfigState[offerName].number_of_sessions}
                  onChange={(e) => handleOnChangeOfferConfig(e, offerName)}
                />
              )}
            </Grid.Column>
          ) : (
            <Grid.Column computer={4} textAlign='center'>
              {
                <input
                  className={`${styles.inputField} ${styles.configInput}`}
                  name='number_of_sessions'
                  type='text'
                  placeholder='0'
                  maxLength={2}
                  autoComplete='off'
                  readOnly={true}
                  value={offerConfigState[offerName].number_of_sessions}
                  onChange={(e) => handleOnChangeOfferConfig(e, offerName)}
                />
              }
            </Grid.Column>
          )}
          <Grid.Column computer={4} textAlign='center'>
            {props.isEditingMode ? (
              <Popup
                content={translate('Program has started and unable to modify offer')}
                position='right center'
                style={style}
                basic
                disabled={shouldHideConfigPopUp()}
                trigger={
                  <input
                    className={`${styles.inputField} ${styles.configInput}`}
                    name='number_of_courses'
                    type='text'
                    placeholder='0'
                    autoComplete='off'
                    readOnly={!canEditOfferConfig}
                    value={offerConfigState[offerName].number_of_courses}
                    onChange={(e) => handleOnChangeOfferConfig(e, offerName)}
                  />
                }
              />
            ) : (
              <input
                className={`${styles.inputField} ${styles.configInput}`}
                name='number_of_courses'
                type='text'
                placeholder='0'
                maxLength={4}
                autoComplete='off'
                readOnly={!canEditOfferConfig}
                value={offerConfigState[offerName].number_of_courses}
                onChange={(e) => handleOnChangeOfferConfig(e, offerName)}
              />
            )}
          </Grid.Column>
          <Grid.Column computer={4} textAlign='center'>
            {props.isEditingMode ? (
              <Popup
                content={translate('Program has started and unable to modify offer')}
                position='right center'
                style={style}
                basic
                disabled={shouldHideConfigPopUp()}
                trigger={
                  <input
                    className={`${styles.inputField} ${styles.configInput} ${
                      +offerConfigState[offerName].duration_of_session < 55 && styles.inputAlert
                    } ${+offerConfigState[offerName].duration_of_session > 150 && styles.inputAlert} `}
                    name='duration_of_session'
                    type='text'
                    placeholder='0'
                    maxLength={3}
                    autoComplete='off'
                    readOnly={!canEditOfferConfig}
                    value={offerConfigState[offerName].duration_of_session}
                    onChange={(e) => handleOnChangeOfferConfig(e, offerName)}
                  />
                }
              />
            ) : (
              <input
                className={`${styles.inputField} ${styles.configInput} ${
                  +offerConfigState[offerName].duration_of_session < 55 && styles.inputAlert
                } ${+offerConfigState[offerName].duration_of_session > 150 && styles.inputAlert} `}
                name='duration_of_session'
                type='text'
                placeholder='0'
                maxLength={3}
                autoComplete='off'
                readOnly={!canEditOfferConfig}
                value={offerConfigState[offerName].duration_of_session}
                onChange={(e) => handleOnChangeOfferConfig(e, offerName)}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  const searchClient = useCallback(() => {
    setComponentShowed(true);
    reQueryClient({ pause: false });
  }, [reQueryClient, setComponentShowed]);

  const selectClient = useCallback(
    (client: any) => {
      setFormState({ ...formState, client: client.name, client_id: client.id });
      setComponentShowed(false);
    },
    [formState, setComponentShowed],
  );

  const handleSubmit = () => {
    if (
      !formState.client ||
      !calendarState.calendarValue ||
      !formState.programName ||
      !checkNumberInRange(offerConfigState.development.duration_of_session) ||
      !checkNumberInRange(offerConfigState.flash.duration_of_session) ||
      !checkNumberInRange(offerConfigState.focus.duration_of_session)
    ) {
      return;
    }

    switch (props.isEditingMode) {
      case true:
        executeUpdateProgram({
          id: props.program.id,
          name: formState.programName,
          start_date: calendarState.calendarValue.toLocaleString('sv-SE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          offer_configurations: [offerConfigState.flash, offerConfigState.development, offerConfigState.focus],
        }).then((res) => {
          if (+res.data.updateProgram.status_code === +STATUS_CODE.SUCCESS) {
            setHelperState({
              ...helperState,
              isEditing: false,
              message: FormMessages.Success,
            });
            setFormState({ ...formState, edited: false });
            return;
          }
        });
        return;
      case false:
        if (!formState.client_id || !formState.programName) return;
        executeCreateProgram({
          client_id: formState.client_id,
          name: formState.programName,
          start_date: calendarState.calendarValue.toLocaleString('sv-SE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          offer_configurations: [offerConfigState.flash, offerConfigState.development, offerConfigState.focus],
        }).then((res) => {
          if (+res.data?.addProgram.status_code === +STATUS_CODE.SUCCESS) {
            setHelperState({ ...helperState, message: '' });
            props.setCreateProgramSuccess(res.data.addProgram.program);
            return;
          }
          if (res.error.message === GRAPHQL_ERRORS.CLIENT_HAD_PROGRAM) {
            setHelperState({
              ...helperState,
              message: 'This program has been added to client',
            });
            return;
          }
        });
        return;
    }
  };

  useEffect(() => {
    if (!props.isEditingMode) {
      handleMapOfferPropToState(props.defaultOffer);
      return;
    }
    setHelperState({ ...helperState, isEditing: false });
    setFormState({
      programName: props?.program.name,
      client: props.program.client.name,
      client_id: '',
      created_date: props.program.creation_date,
      close_date: props.program.close_date,
      edited: false,
    });
    setCalendarState({
      ...calendarState,
      calendarValue: new Date(props.program.start_date),
    });
    handleMapOfferPropToState(props.program.offerConfigurations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.program]);

  return (
    <div className={styles.formWrapper}>
      {props.showTitle && <p className={styles.programInfoText}>{translate('Program information')}</p>}
      {!helperState.isEditing && (
        <Button className={`${styles.editBtn}`} onClick={handleSwitchEditing}>
          <Icon name='pencil alternate' className={styles.pencilIcon} />
          <span className={`topLevelText buttonText ${styles.editText}`}>{translate('Edit')}</span>
        </Button>
      )}
      <div className={styles.container}>
        <form className={styles.formCreateProgram}>
          {helperState.message && (
            <p
              className={
                helperState.message === FormMessages.Success ? 'textInfoFormMessage' : 'textDangerFormMessage'
              }>
              {translate(helperState.message)}
            </p>
          )}
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('Name')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={styles.inputField}
              name='programName'
              type='text'
              placeholder={translate('Program name')}
              readOnly={!helperState.isEditing}
              value={formState.programName}
              onChange={(e) => {
                handleOnChange(e);
              }}
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('Client')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <div className={styles.inputSearchWrapper}>
              <input
                className={`${styles.inputField} ${helperState.isEditing && styles.inputWidth}`}
                name='client'
                type='text'
                placeholder={translate("Client's name")}
                autoComplete={'off'}
                readOnly={!helperState.isEditing}
                value={formState.client}
                onChange={(e) => handleOnChange(e)}
              />

              {helperState.isEditing && (
                <Icon
                  name='search'
                  className={`${styles.searchIcon} ${formState.client && styles.highLightIcon}`}
                  onClick={searchClient}
                />
              )}

              <div ref={ref}>
                {isComponentShowed && (
                  <InputSearchResult
                    isLoading={queryClientResult.fetching}
                    result={queryClientResult.data && queryClientResult.data.listClients.clients}
                    selectResult={selectClient}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('Start date')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <div className={styles.calendarInputBlock}>
              <input
                type='text'
                placeholder='date'
                className={styles.inputField}
                readOnly={!helperState.isEditing}
                value={calendarState.calendarValue.toLocaleDateString('fr-FR')}
                onClick={() => {
                  if (!helperState.isEditing) return;
                  calendarVisibleControl.setComponentShowed(true);
                }}
              />

              <div ref={calendarVisibleControl.ref}>
                {calendarVisibleControl.isComponentShowed && (
                  <Calendar
                    value={calendarState.calendarValue}
                    onChange={(value: any) => handleOnchangeCalendar(value)}
                    className={styles.calendar}
                    locale='fr-FR'
                  />
                )}
              </div>
            </div>
          </div>

          {!helperState.isEditing && (
            <>
              <div className={styles.formField}>
                <div className={styles.labelWrapper}>
                  <label className={styles.label}>{translate('Created date')}</label>
                </div>
                <div className={styles.inputField}>
                  {formState.created_date
                    ? moment(formState.created_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : '--/--/----'}
                </div>
              </div>
              <div className={styles.formField}>
                <div className={styles.labelWrapper}>
                  <label className={styles.label}>{translate('Finish date')}</label>
                </div>
                <div className={styles.inputField}>
                  {formState.close_date
                    ? moment(formState.close_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : '--/--/----'}
                </div>
              </div>
            </>
          )}

          <div className={`${styles.formField} ${styles.glass_border}`}>
            <div className={styles.offerConfigLabelWrapper}>
              <p className={styles.label}>
                {translate('Offer configuration')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>} :
              </p>
            </div>
          </div>
          <div className={styles.configBlock}>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={4}></Grid.Column>
                <Grid.Column computer={4} textAlign='center'>
                  <span className={styles.configTitle}>{translate('Course session')}</span>
                </Grid.Column>
                <Grid.Column computer={4} textAlign='center'>
                  <span className={styles.configTitle}>{translate('Number of courses')}</span>
                </Grid.Column>
                <Grid.Column computer={4} textAlign='center'>
                  <span className={styles.configTitle}>{translate('Session duration')}</span>
                  <br />
                  <span>(55-150 min)</span>
                </Grid.Column>
              </Grid.Row>
              {props.defaultOffer && renderOffer(props.defaultOffer)}
              {props.isEditingMode && renderOffer(props.program.offerConfigurations)}
            </Grid>
          </div>
        </form>
        <div className={`${styles.rightGroup} ${helperState.isEditing && styles.center}`}>
          {helperState.isEditing ? (
            <>
              <button
                className={`${styles.btnSave} ${
                  formState.programName &&
                  formState.client &&
                  calendarState.calendarValue &&
                  formState.edited &&
                  styles.btnActive
                } ${fetching || (updateProgramResult.fetching && styles.disable)}  topLevelText buttonText`}
                onClick={handleSubmit}
                disabled={
                  !formState.programName || !formState.client || !calendarState.calendarValue || !formState.edited
                }>
                {translate('Save')}
              </button>
              <button
                className={`${styles.btnCancel} topLevelText buttonText`}
                onClick={() => {
                  if (formState.edited || offerConfigState.edited) setHelperState({ ...helperState, showModal: true });
                  else setHelperState({ ...helperState, isEditing: false });
                }}
                disabled={fetching}>
                {translate('Cancel')}
              </button>
            </>
          ) : (
            <div className={styles.totalPMBlock}>
              <span className={styles.bigNumber}>{props.programMangerTotal}</span>
              <p className={styles.text1}>
                {translate('Program manager', {
                  count: props.programMangerTotal,
                })}
              </p>
            </div>
          )}
        </div>
      </div>
      {helperState.showModal && (
        <AlertModal
          title={"CONFIRMER L'ANNULATION"}
          contentCenter={true}
          contentQuestion={[
            {
              text: 'Voulez-vous vraiment annuler cette modification?',
              style: AlertModalContentQuestionStyle.BASIC,
            },
          ]}
          content={'Vous reviendrez à la page précédente et toutes les données non enregistrées seront perdues'}
          cancel={() => setHelperState({ ...helperState, showModal: false })}
          agree={handleAgreeCancelForm}
        />
      )}
    </div>
  );
};

export default React.memo(EditableProgramDetailComp);
