import React, { useCallback, useEffect, useState } from 'react';
import styles from './uploadComponent.module.scss';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'urql';
import { PROGRAMMANAGER_upload } from '../../services/apis/programManagerApi';
import { Helper_Actions, IconEnum, STATUS_CODE } from '../../models/enum';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { palette } from '../../theme/palette';
import { maxUploadSize } from '../../config/helperConstants';

interface Props {
  passedItem_ID: string;
  title: string;
  titleDesc: string;
  titleDescBold?: string;
  titleDescContinuous?: string;
  reFetch?: () => void;
}

const UploadComponent: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const uploadComponentStore = useSelector((state: any) => state.helperReducer.uploadComponent);

  const [files, setFiles] = useState([]);

  const [helperState, setHelperState] = useState({
    message: '',
  });

  const [{ fetching }, executeUpload] = useMutation(PROGRAMMANAGER_upload);

  /*METHODS*/
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );

      if (acceptedFiles[0].size > maxUploadSize) {
        dispatch({
          type: Helper_Actions.set_icon_name,
          payload: {
            icon: IconEnum['exclamation triangle'],
            color: palette.secondaryRedMPB,
          },
        });
        return;
      }

      dispatch({
        type: Helper_Actions.set_icon_name,
        payload: {
          icon: IconEnum['file excel outline'],
          color: palette.excelGreen,
        },
      });
    },
    [dispatch],
  );

  const handleSubmit = useCallback(() => {
    if (!!!files.length) return;
    executeUpload({
      program_id: props.passedItem_ID,
      file: files[0],
    })
      .then((res) => {
        if (+res.data.importProgramManagers.status_code === +STATUS_CODE.SUCCESS) {
          props.reFetch();
          setFiles([]);
          setHelperState({ ...helperState, message: '' });
          dispatch({
            type: Helper_Actions.upload_success,
          });
        } else if (+res.error.graphQLErrors[0].extensions.error_code === +STATUS_CODE.PAYLOADTOOLARGE) {
          setHelperState({
            ...helperState,
            message: translate('File is not allowed'),
          });
        } else {
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  }, [dispatch, executeUpload, files, helperState, props, translate]);

  const handleCancel = useCallback(() => {
    setFiles([]);
    setHelperState({ ...helperState, message: '' });
    dispatch({
      type: Helper_Actions.reset,
    });
  }, [dispatch, helperState]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    return () => {
      dispatch({
        type: Helper_Actions.reset,
      });
    };
  }, [dispatch]);

  /*MAIN RETURN*/
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.formTitle}>{props.title}</h1>
      <p className={styles.formDesc}>
        {props.titleDesc}
        {props.titleDescBold && (
          <span className={`${styles.titleDescBold} topLevelText`}>{props.titleDescBold}</span>
        )}{' '}
        {props.titleDescContinuous}
      </p>
      <p className={`${styles.messageText} textDangerFormMessage`}>{helperState.message}</p>

      <Segment className={styles.customedSegment}>
        <div className={styles.uploadWrapper} {...getRootProps()}>
          {isDragActive && <div className={styles.layer}></div>}
          <input {...getInputProps()} type='file' name='file' />
          <Icon
            name={uploadComponentStore.iconName}
            className={`${styles.uploadIcon} ${
              uploadComponentStore.iconName === IconEnum['file excel outline'] && styles.excelColor
            } ${uploadComponentStore.iconName === IconEnum['exclamation triangle'] && styles.errorColor}`}
          />

          {!uploadComponentStore.uploaded ? (
            <>
              {!files.length && (
                <span className={styles.uploadDesc}>
                  {translate('Drag and drop') + ' '}
                  <span className={styles.textClickable}>{translate('Click here')}</span>
                </span>
              )}
              {!!files.length && files[0].size <= maxUploadSize && (
                <p className={styles.uploadDesc}>
                  <span className={styles.fileName}>{files[0].name + ' '}</span>
                  {translate('Ready to be sent')}
                </p>
              )}
              {!!files.length && files[0].size > maxUploadSize && (
                <p className={styles.uploadDesc}>{translate('File is not allowed')}</p>
              )}
            </>
          ) : (
            <>
              <p className={styles.uploadSuccessTextHead}>{translate('File uploaded')}</p>
              <p className={styles.uploadSuccessTextDesc}>
                <span className={styles.textClickableUploadSuccess}>{translate('Click here')}</span>{' '}
                {translate('Update or add more program managers')}
              </p>
            </>
          )}
        </div>
        <div className={styles.btnGroup}>
          <Button
            className={` ${styles.btnUpload} ${
              files.length && files[0].size <= maxUploadSize && styles.btnActive
            } topLevelText`}
            disabled={!files.length || files[0]?.size > maxUploadSize}
            onClick={handleSubmit}>
            {translate('Send')}
          </Button>
          <Button className={` ${styles.btnCancel} topLevelText`} disabled={fetching} onClick={handleCancel}>
            {translate('Cancel')}
          </Button>
        </div>
      </Segment>
    </div>
  );
};

export default React.memo(UploadComponent);
