import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';

import '../src/utilities/i18n';
import { store } from './services/redux/rootReducer';
import UrqlProvider from './core/UrqlProvider';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <UrqlProvider>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </UrqlProvider>
    </BrowserRouter>
  </StrictMode>,
);

reportWebVitals();
