import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { ICourse } from '../../models/type';
import styles from './modalChangeCoach.module.scss';
import { ICoach_in_program_reducer } from '../../models/type';
import { useMutation } from 'urql';
import { COURSE_force_coach_to_course } from '../../services/apis/courseApi';
import { STATUS_CODE } from '../../models/enum';

/*Note
 Using dropdown semantic cannot get text (which can be use as substitude coach name)
 Solutions :
 1- Find index of substitute coachID to get coach name
 2- Use substitute coach format xxxxCoachID?Coachname to split and get coach name
 Temporary go for solution 2
*/

interface Props {
  selectedCourse?: ICourse;
  handleClose?: () => void;
  refetchListCourse?: () => void;
}

interface IState {
  substituteCoach: ICoach_in_program_reducer;
  showSuccess: boolean;
}

const ModalChangeCoach: React.FC<Props> = (props: Props) => {
  /*STATE*/
  const coachTeammSelector: Array<ICoach_in_program_reducer> = useSelector(
    (state: any) => state.programReducer.coachsTeam,
  );

  const [state, setState] = useState<IState>({
    substituteCoach: null,
    showSuccess: false,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();

  const [, executeForceCoachToCourse] = useMutation(COURSE_force_coach_to_course);

  //TODO : SHOULD BE IMPROVE BY COMPARING COACH ID INSTEAD OF NAME SINCE NAME CAN BE SAME. NEED LISTCOURSE API RETURN COACH ID
  const renderDropDownOptions = () => {
    return coachTeammSelector.map((item) => {
      return {
        key: item.id,
        text: item.name,
        value: item.id + '?' + item.name,
      };
    });
  };

  const handleSubmit = () => {
    executeForceCoachToCourse({
      course_id: props.selectedCourse.id,
      coach_id: state.substituteCoach.id,
    })
      .then((res) => {
        if (+res.data?.forceAssignCoachOnCourse?.status_code === +STATUS_CODE.SUCCESS) {
          setState({ ...state, showSuccess: true });
          props.refetchListCourse();
        } else {
          console.error(res.error);
        }
      })
      .catch((err) => console.error(err));
  };

  /*VARIABLES*/
  const isSubmitActive = !!state.substituteCoach;

  return (
    <div className={`${styles.modal}`}>
      <div className={`${styles.wrapper} `}>
        <h1 className={`${styles.title} topLevelText`}>{translate('Assign coach to course')}</h1>
        <div className={styles.bottomWrapper}>
          {!state.showSuccess ? (
            <>
              <div className={`${styles.contentWrapper}`}>
                <p className={styles.contentQuestion}>
                  {translate('Are you sure want to change coach to')}{' '}
                  <span className={styles.primaryText}>{props.selectedCourse.offer_configuration.name}</span>
                  {' ?'}
                </p>

                <div className={styles.selectCoachWrapper}>
                  <p className={styles.text2}>{translate('Select a coach') + ' :'}</p>
                  <div className={styles.dropdownSelectionCoach}>
                    <Dropdown
                      basic
                      search
                      scrolling
                      placeholder={translate('Select a coach')}
                      selection
                      noResultsMessage={translate('No result')}
                      options={renderDropDownOptions()}
                      fluid
                      onChange={(e, { value }) => {
                        const splitedValue = value.toString().split('?');
                        setState({
                          ...state,
                          substituteCoach: {
                            id: splitedValue[0],
                            name: splitedValue.pop(),
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.buttonsWrapper}>
                <Button className={`${styles.btn} ${styles.cancelBtn} `} onClick={props.handleClose}>
                  <span className='topLevelText buttonText'>{translate('Cancel')}</span>
                </Button>
                <Button
                  className={`${styles.btn} ${styles.agreeBtn} `}
                  disabled={!isSubmitActive}
                  onClick={handleSubmit}>
                  <span className='topLevelText buttonText'>{translate('Save')}</span>
                </Button>
              </div>
            </>
          ) : (
            <div className={`${styles.contentWrapper} ${styles.alignCenter}`}>
              <Icon name='check' className={styles.checkIcon} />

              <p className={styles.contentQuestion}>
                {translate('A coach')} <span className={styles.primaryText}>{state.substituteCoach?.name}</span>{' '}
                {translate('has been assigned to course')}{' '}
                <span className={styles.primaryText}>{props.selectedCourse?.offer_configuration.name}</span>
              </p>
              <div className={styles.buttonsWrapper}>
                <Button className={`${styles.btn} ${styles.closeBtn} `} onClick={props.handleClose}>
                  <span className='topLevelText buttonText'>{translate('Close')}</span>
                </Button>
              </div>
            </div>
          )}
        </div>
        <Icon name='cancel' size='huge' className={styles.closeIcon} onClick={props.handleClose} />
      </div>
    </div>
  );
};

export default ModalChangeCoach;
