import React from 'react';
import { Grid } from 'semantic-ui-react';
import EditableClientDetailComp from '../EditableClientDetailComp/EditableClientDetailComp';
import styles from './createClientInfo.module.scss';

const CreateClientInfo: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Grid>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
            <EditableClientDetailComp />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CreateClientInfo;
