/*QUERY*/

export const COACH_detail_admin = `
query($coach_id: ID!) {
    detailAdminCoach (
        id: $coach_id
    ) {
        status_code
        coach {
            id
            presentation
            quote
            target_user_position
            additional_languages
            can_deactivate
            is_active
            picture
            certifications
            thematics {
                id
                user_position
                keyword
                title
                position
            }
            user_infos {
                id
                first_name
                last_name
                email
                position
                department
                gdpr_consent  
            }
        }
    }
}
`;

export const COACH_searchCoach = `
query ($count : Int, $page : Int!, $search_name : String, $order_by : ListCoachesSortableColumn, $direction : SortDirection) {
    listCoaches(
        count: $count,
        page:  $page,
        search_name: $search_name,
        order_by: $order_by,
        direction: $direction
    ) {
        status_code
        coaches {
            id
            number_of_programs
            can_deactivate
            is_active
            certifications
            user_infos {
                id
                gdpr_consent
                email
                first_name
                last_name
                department
                position
            }
            number_of_programs
        } 
        paginatorInfo {
            currentPage
            lastPage
        }
    }
}
`;

export const COACH_list_thematics = `
query {
    listThematics(
        search_by: need
    ) {
        status_code
        thematics {
            id
            user_position # EXEC = Dirigeant, MNGR = Manager, CLB = Collaborateur, CDP = Chef de Projet
            keyword
            title
            position
        }
    }
}
`;

/*MUTATION*/

export const COACH_create = `
mutation(
    $firstName: String!, 
    $lastName: String!, 
    $email: String!, 
    $target_user_position: String!, 
    $additional_languages: [String!]!, 
    $certifications: [String!]!, 
    $thematics: [ID!], 
    ) {
    createCoach (
        first_name: $firstName, 
        last_name:$lastName, 
        email: $email,
        target_user_position: $target_user_position,
        additional_languages: $additional_languages, 
        certifications: $certifications,
        thematics: $thematics
    ) {
        status_code
        id
        user_infos {
            id
            first_name
            last_name
            email
            department
            position
            gdpr_consent  
        }
    }
}
`;

export const COACH_create_team = `
mutation($coaches_id: [ID!]!, $program_id: ID!, $name: String!) {
    createCoachTeam (
        name: $name,
        program_id: $program_id,
        coaches_id: $coaches_id
    ) {
        status_code
    }
}
`;

export const COACH_update_coach_admin = `
mutation(
    $coach_id: ID!,
    $firstName: String!, 
    $lastName: String!, 
    $email: String!, 
    $presentation: String, 
    $quote: String, 
    $target_user_position: String!, 
    $additional_languages: [String!]!, 
    $certifications: [String!]!, 
    $thematics: [ID!]!,
    $is_active: Boolean
    ) {
    updateAdminCoach (
        id: $coach_id,
        first_name: $firstName, 
        last_name:$lastName, 
        email: $email,
        presentation: $presentation, 
        quote: $quote,
        target_user_position: $target_user_position,
        additional_languages: $additional_languages, 
        certifications: $certifications,
        thematics: $thematics,
        is_active : $is_active
    ) {
        status_code
        coach {
            id
            presentation
            quote
            target_user_position
            additional_languages
            certifications
            thematics {
                id
                user_position
                keyword
                title
                position
            }
            user_infos {
                id
                first_name
                last_name
                email
                position
                department
                gdpr_consent  
            }
        }
    }
}
`;
