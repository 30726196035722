import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Icon } from 'semantic-ui-react';
import SearchInput from '../SearchInput/SearchInput';
import styles from './PMHeaderBlock.module.scss';

interface Props {
  showSearch: boolean;
  showDropdown: boolean;
  showReload: boolean;
  totalProgramManagers?: number;
  handleSearch?: (search_value: string) => void;
}

const PMHeaderBlock: React.FC<Props> = (props: Props) => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        {translate('Program manager')}
        {props.totalProgramManagers && ` (${props.totalProgramManagers})`}
      </p>
      <div className={styles.rightBlock}>
        <div className={styles.searchNDropdownBlock}>
          {props.showSearch && (
            <SearchInput
              placeholder={translate('Search')}
              handleSearch={(search_value: string) => props.handleSearch(search_value)}
            />
          )}
          {props.showDropdown && (
            <Dropdown
              placeholder={translate('Program manager')}
              selection
              options={[
                {
                  key: 1,
                  text: translate('Program manager'),
                  value: 'Gestionnaire de Programme',
                },
                {
                  key: 2,
                  text: translate('Program name'),
                  value: 'Nom du Programme',
                },
              ]}
              className={styles.dropDown}
            />
          )}
        </div>
        {props.showReload && <Icon name='redo' className={styles.iconReload} />}
      </div>
    </div>
  );
};

export default React.memo(PMHeaderBlock);
