import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Transition } from 'semantic-ui-react';
import CoachConditionsTable from '../CoachConditionsTable/CoachConditionsTable';
import styles from './editableCoachDetailComp.module.scss';

const CoachCriteriaTable: React.FC = () => {
  const { t: translate } = useTranslation();

  const [isTableVisible, setTableVisibility] = useState(false);

  return (
    <div className={styles.instructionWrapper}>
      <div className={styles.instructionDropDown}>
        {isTableVisible ? (
          <Icon name='caret down' size='big' className={styles.rightIcon} onClick={() => setTableVisibility(false)} />
        ) : (
          <Icon name='caret right' size='big' className={styles.rightIcon} onClick={() => setTableVisibility(true)} />
        )}

        <div className={styles.instructionTextBlock}>{translate('Classification instruction')}</div>
      </div>

      <Transition.Group animation='slide down' duration={400}>
        {isTableVisible && (
          <div>
            <CoachConditionsTable />
          </div>
        )}
      </Transition.Group>
    </div>
  );
};

export default CoachCriteriaTable;
