import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import EditableProgramDetailComp from '../../components/EditableProgramDetailComp/EditableProgramDetailComp';
import { Helper_Actions, MaterialIconName, OfferOrderableColumn, SortDirection } from '../../models/enum';
import { PRORAM_OFFER_getList } from '../../services/apis/programApi';
import styles from './createProgramPage.module.scss';

const CreateProgramPage: React.FC = () => {
  /*STATE*/
  const [createdProgram, setcreatedProgram] = useState(null);

  /*METHODS*/
  const [defaultOfferQueryResult] = useQuery({
    query: PRORAM_OFFER_getList,
    variables: {
      page: 1,
      order_by: OfferOrderableColumn.NAME,
      direction: SortDirection.ASC,
    },
  });

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  /*EFFECT*/
  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.add,
        title: translate('Create program'),
      },
    });
  }, [dispatch, translate]);

  if (createdProgram) {
    return (
      <Navigate
        to={{
          pathname: `/program-detail/${createdProgram.id}`,
        }}
        state={{ createdProgram }}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h1 className={styles.title}>{translate('Fill out to create program')}</h1>
        <p className={styles.titleDesc}>{translate('Complete form to create program')}</p>
      </div>

      {defaultOfferQueryResult.data?.listOffers && (
        <Grid>
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
              <EditableProgramDetailComp
                setCreateProgramSuccess={(newProgram) => setcreatedProgram(newProgram)}
                isEditingMode={false}
                defaultOffer={defaultOfferQueryResult.data.listOffers.offers}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default CreateProgramPage;
