import React, { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import { useMutation } from 'urql';
import { useTranslation } from 'react-i18next';

import { STATUS_CODE } from '../../models/enum';
import useLogOut from '../../hooks/useLogOut';
import Validator from '../../services/validation/validation';
import { errorMessage } from '../../services/validation/errorMessage';
import { API_userLogin } from '../../services/apis/userApi';
import { token, token_name } from '../../config/tokenConstant';
import { getMilisecondExpiresDate } from '../../utilities/authenticate';

import styles from './login.module.scss';

const Login: React.FC = () => {
  const [{ fetching }, loginMethod] = useMutation(API_userLogin);

  const [formState, setFormState] = useState({
    email: {
      value: '',
      error: false,
      errorMessage: 'ErrorMessage',
    },
    password: {
      value: '',
      error: false,
      errorMessage: 'ErrorMessage',
    },
    toDashboard: false,
    toForgetPassword: false,
  });
  /*METHODS*/
  const { t: translate } = useTranslation();

  const logOut = useLogOut();

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name: string = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    if (name === 'email' || name === 'password') {
      setFormState({
        ...formState,
        [name]: { ...formState[name], value: value },
      });
    }
  };

  const handleToForgetPassword = useCallback(() => {
    setFormState({ ...formState, toForgetPassword: true });
  }, [formState]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const tempState = { ...formState };
    let emailPassed = true;
    let passwordPassed = true;

    if (!Validator.checkValidEmail(formState.email.value)) {
      tempState.email.error = true;
      tempState.email.errorMessage = errorMessage.email.invalid.frError;
      emailPassed = false;
    }

    if (Validator.checkEmpty(formState.email.value)) {
      tempState.email.error = true;
      tempState.email.errorMessage = errorMessage.email.presence.frError;
      emailPassed = false;
    }

    if (Validator.checkEmpty(formState.password.value)) {
      tempState.password.error = true;
      tempState.password.errorMessage = errorMessage.password.presence.frError;
      passwordPassed = false;
    }

    tempState.email.error = !emailPassed;

    tempState.password.error = !passwordPassed;

    setFormState(tempState);
    if (!emailPassed || !passwordPassed) return;

    loginMethod({
      email: formState.email.value,
      password: formState.password.value,
    })
      .then((res) => {
        if (res.data?.login.token.access_token) {
          const infoToken = {
            token: res.data.login.token.access_token,
            expires_date: getMilisecondExpiresDate(res.data.login.token.expires_in),
            expires_in: res.data.login.token.expires_in,
          };
          localStorage.setItem(token, JSON.stringify(infoToken));
          localStorage.setItem(
            token_name,
            JSON.stringify(res.data.login.user.first_name + ' ' + res.data.login.user.last_name),
          );
          setFormState({ ...formState, toDashboard: true });
          return;
        }
        if (res.error.graphQLErrors[0].extensions.error_code === STATUS_CODE.UNAUTHENTICATED) {
          logOut();
          setFormState({
            ...formState,
            password: {
              ...formState.password,
              error: true,
              errorMessage: 'Email ou mot de passe incorrect',
            },
          });
          return;
        }
        console.error(res.error.graphQLErrors[0].message);
      })
      .catch((err) => console.error(err));
  };

  /*Redirect routing statement*/
  if (localStorage.getItem(token)) {
    return <Navigate to={`/dashboard`} />;
  }

  if (formState.toForgetPassword) {
    return <Navigate to='/forgetpassword' />;
  }

  return (
    <>
      <Form className={styles.formBlock} onSubmit={(e) => handleSubmit(e)}>
        <Form.Field className={styles.formField}>
          <input
            name='email'
            placeholder='Email'
            type='email'
            className={styles.inputField}
            autoComplete='true'
            onChange={(e) => handleOnChange(e)}
          />

          <p className={`${styles.errorMessage} ${formState.email.error && styles.visible}`}>
            {formState.email.errorMessage}
          </p>
        </Form.Field>
        <Form.Field className={styles.formField}>
          <input
            name='password'
            placeholder='Password'
            type='password'
            className={styles.inputField}
            autoComplete='true'
            onChange={(e) => handleOnChange(e)}
          />
          <p className={`${styles.errorMessage} ${formState.password.error && styles.visible}`}>
            {formState.password.errorMessage}
          </p>
        </Form.Field>
        <Button type='submit' className={styles.submitButton} disabled={fetching}>
          {translate('Login')}
        </Button>
        <p className={styles.forgetPasswordText} onClick={handleToForgetPassword}>
          {translate('Forgotten password')}
        </p>
      </Form>
    </>
  );
};

export default Login;
