import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import styles from './awaitingComp.module.scss';

interface Props {
  fluid: boolean;
}

const AwaitingComp: React.FC<Props> = (props: Props) => {
  return (
    <div className={`${styles.wrapper} ${props.fluid && styles.fluid}`}>
      <Dimmer active inverted>
        <Loader size='medium'>Chargement</Loader>
      </Dimmer>
    </div>
  );
};

export default React.memo(AwaitingComp);
