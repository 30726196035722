import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { AlertModalContentQuestionStyle } from '../../../models/enum';
import { IAlertContentQuestion } from '../../../models/type';
import styles from './alertModal.module.scss';

interface Props {
  title: string;
  contentQuestion: Array<IAlertContentQuestion>;
  content: string;
  cancel: () => void;
  agree: () => void;
  secondaryStyle?: boolean;
  contentCenter?: boolean;
}

const AlertModal: React.FC<Props> = (props: Props) => {
  /*METHODS*/
  const { t: translate } = useTranslation();
  const renderContentQuestion = useCallback(() => {
    return props.contentQuestion.map((item: IAlertContentQuestion, index: number) => {
      if (item.style === AlertModalContentQuestionStyle.BASIC) return <span key={index}>{item.text}</span>;
      return (
        <span key={index} className={styles.primaryText}>
          {item.text}
        </span>
      );
    });
  }, [props.contentQuestion]);

  return (
    <div className={`${styles.modal} ${props.secondaryStyle && styles.modalStyle2}`}>
      <div className={`${styles.wrapper} ${props.secondaryStyle && styles.wrapperStyle2}`}>
        <h1 className={`${styles.title} topLevelText`}>{props.title}</h1>
        <div className={styles.bottomWrapper}>
          <div className={`${styles.contentWrapper} ${props.contentCenter && styles.alignCenter}`}>
            <p className={styles.contentQuestion}>{renderContentQuestion()}</p>
            <p className={styles.content}>
              <Icon name='info' className={styles.icon} />

              {props.content}
            </p>
          </div>
          <div className={styles.buttonsWrapper}>
            {!props.secondaryStyle ? (
              <>
                <Button className={`${styles.btn} ${styles.cancelBtn} `} onClick={props.cancel}>
                  <span className='topLevelText'>{translate('Cancel')}</span>
                </Button>
                <Button className={`${styles.btn} ${styles.agreeBtn} `} onClick={props.agree}>
                  <span className='topLevelText'>{translate('Yes')}</span>
                </Button>
              </>
            ) : (
              <Button className={` ${styles.agreeBtn} ${styles.agreeBtnStyle2} topLevelText`} onClick={props.cancel}>
                Nouvelle recherche
              </Button>
            )}
          </div>
        </div>

        <Icon name='cancel' className={styles.closeIcon} onClick={props.cancel} />
      </div>
    </div>
  );
};

export default AlertModal;
