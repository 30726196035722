import React from 'react';
import styles from './inputSearchResult.module.scss';
import { Icon } from 'semantic-ui-react';

interface Props {
  isLoading: boolean;
  result: Array<string>;
  selectResult: (resultItem: any) => void;
}
const InputSearchResult: React.FC<Props> = (props: Props) => {
  const renderResult = () => {
    return props.result.map((item: any, index) => {
      return (
        <p
          className={styles.resultItem}
          key={index}
          onClick={() => {
            props.selectResult(item);
          }}
          style={{ cursor: 'pointer' }}>
          {item.name}
        </p>
      );
    });
  };

  return (
    <div className={styles.wrapper}>
      {props.isLoading && <Icon name='spinner' rotated='clockwise' loading={true} className={styles.loadingIcon} />}

      {props.result && !!props.result.length && renderResult()}
      {props.result && !props.result.length ? <p className={styles.resultItem}>No matched result</p> : ''}
    </div>
  );
};

export default React.memo(InputSearchResult);
