import { ISemanticDropDownOptions, NavProps } from '../models/type';

/**
 *
 * @param time
 */
const autoAddingZero = (time: string) => {
  while (time.length < 2) time = '0' + time;
  return time;
};

/**
 * Format for correct Time HH:MM
 * @param hour
 * @param minute
 */
export const conCatHourAndMinute = (hour: string, minute: string) => {
  const transformedHour = autoAddingZero(hour);
  const transformedMinute = autoAddingZero(minute);
  return transformedHour + ':' + transformedMinute;
};

/**
 *
 * @param array
 */
export const generateDropDownValue = (array: Array<any>) => {
  return array.map((item: any, index: number) => {
    return {
      key: index + 1,
      value: item.id,
      text: item.name,
    };
  });
};

/**
 *
 * @param object
 */
export const checkObjectHasFalsy = (object: any) => {
  return !Object.values(object).every((o) => !o === false);
};

/**
 *
 * @param number
 */
export const checkNumberInRange = (number: number): boolean => {
  return number >= 55 && number <= 150;
};

/**
 *
 * @param rawOptions
 * @param translateFuction
 */
export const translateDropDownOption = (
  rawOptions: Array<ISemanticDropDownOptions>,
  translateFuction: (text: string) => string,
) => {
  return rawOptions.map((item: ISemanticDropDownOptions) => {
    return {
      key: item.key,
      text: translateFuction(item.text),
      value: item.value,
    };
  });
};

export const validURL = (str: string): boolean => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const navLinkClasses = (navLinkProps: NavProps, defaultClass: string, activeClass: string): string => {
  if (navLinkProps.isActive) {
    return `${defaultClass} ${activeClass}`;
  }

  return defaultClass;
};
