import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { CourseSortableColum, CourseStatus, Program_Actions, SortDirection, STATUS_CODE } from '../../models/enum';
import { ICourse, ICourseProgression } from '../../models/type';
import { COURSE_course_table } from '../../services/apis/courseApi';
import AwaitingComp from '../Common/AwaitingComp/AwaitingComp';
import CourseListRow from '../CourseListRow/CourseListRow';
import styles from './courseListTable.module.scss';
import ModalChangeCoach from './ModalChangeCoach';
import ForceCloseCourseModal from './ForceCloseCourseModal';
interface IState {
  selectedCourse: ICourse;
  openChangeCoachModal: boolean;
  openForceCloseCourseModal: boolean;
}

const CourseListTable: React.FC = () => {
  /*PROPS*/

  const [queryVariables, setQueryVariables] = useState({
    program_id: window.location.pathname.split('/').pop(),
    count: 100,
    page: 1,
    order_by: CourseSortableColum.NUMBER_OF_SESSION_DONE,
    direction: SortDirection.DESC,
  });

  const [state, setState] = useState<IState>({
    selectedCourse: null,
    openChangeCoachModal: false,
    openForceCloseCourseModal: false,
  });

  const coursesProgressionSelector = useSelector((state: any) => state.programReducer.coursesProgression);

  const [coursesList, refetchCoursesList] = useQuery({
    query: COURSE_course_table,
    variables: queryVariables,
    requestPolicy: 'cache-and-network',
  });

  /*CONST*/

  const offerTypeSortedASC =
    queryVariables.order_by === CourseSortableColum.OFFER_TYPE && queryVariables.direction === SortDirection.ASC;

  const coacheeSortedASC =
    queryVariables.order_by === CourseSortableColum.COACHEE && queryVariables.direction === SortDirection.ASC;

  const startedDateSortedASC =
    queryVariables.order_by === CourseSortableColum.STARTED_AT && queryVariables.direction === SortDirection.ASC;

  const progressSortedASC =
    queryVariables.order_by === CourseSortableColum.NUMBER_OF_SESSION_DONE &&
    queryVariables.direction === SortDirection.ASC;

  const coachSortedASC =
    queryVariables.order_by === CourseSortableColum.COACH && queryVariables.direction === SortDirection.ASC;

  const evaluationSortedASC =
    queryVariables.order_by === CourseSortableColum.EVALUATION && queryVariables.direction === SortDirection.ASC;

  const validStatuses: Array<string> = [
    CourseStatus.OPEN,
    CourseStatus.UNASSIGNED,
    CourseStatus.CLOSED,
    CourseStatus.INTERRUPTED,
  ];
  const isDisabledInterrupt = validStatuses.includes(state.selectedCourse?.status);

  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const handleSort = (direction: SortDirection, order_by: CourseSortableColum) => {
    setQueryVariables({ ...queryVariables, direction, order_by });
  };

  const handleSelectCourse = (course: ICourse) => {
    setState({ ...state, selectedCourse: course });
  };

  const findCourseProgression = (courseID: string) => {
    if (coursesProgressionSelector.programID !== window.location.pathname.split('/').pop()) return -1;

    if (coursesProgressionSelector.progressionCollection.length === 0) return -1;

    const result = coursesProgressionSelector.progressionCollection.find((courseProgression: ICourseProgression) => {
      return courseProgression.courseID === courseID;
    });

    return result.progressionPoint;
  };

  const renderCourseRow = (list: Array<ICourse>) => {
    return list.map((course: ICourse, index: number) => {
      return (
        <CourseListRow
          key={course.id}
          course={course}
          index={index}
          isChecked={state.selectedCourse?.id === course.id}
          onSelect={(course) => handleSelectCourse(course)}
          progressionPoint={findCourseProgression(course.id)}
        />
      );
    });
  };

  /*EFFECT*/
  useEffect(() => {
    if (+coursesList.data?.listCourses.status_code !== +STATUS_CODE.SUCCESS) return;

    if (coursesProgressionSelector.programID !== window.location.pathname.split('/').pop()) {
      dispatch({
        type: Program_Actions.set_course_progression,
        payload: {
          programID: window.location.pathname.split('/').pop(),
          courses: coursesList.data.listCourses.courses,
        },
      });
    }
  }, [coursesList.data, coursesProgressionSelector.programID, dispatch]);

  return (
    <>
      <div className={styles.buttonBlock}>
        <Button
          className={`${styles.btn} ${
            state.selectedCourse?.status !== CourseStatus.INTERRUPTED && state.selectedCourse?.coach && styles.btnRemove
          }`}
          disabled={!state.selectedCourse?.coach || state.selectedCourse?.status === CourseStatus.INTERRUPTED}
          onClick={() => setState({ ...state, openChangeCoachModal: true })}>
          <Icon name='exchange' />
          <span>{translate('Change coach')}</span>
        </Button>
        <Button
          className={`${styles.btn} ${state.selectedCourse && !isDisabledInterrupt && styles.btnRemove} `}
          disabled={!state.selectedCourse || isDisabledInterrupt}
          onClick={() => setState({ ...state, openForceCloseCourseModal: true })}>
          <Icon name='stop circle' />
          <span>{translate('Interrupt course button')}</span>
        </Button>
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead className={styles.fixedHeader}>
            <tr className={styles.headerTR}>
              <th className={`${styles.headerTH} ${styles.choiceTH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Choose')}</span>
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.typeTH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Type')}</span>
                  {offerTypeSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, CourseSortableColum.OFFER_TYPE)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, CourseSortableColum.OFFER_TYPE)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.assignedTH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Assigned')}</span>
                  {coacheeSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, CourseSortableColum.COACHEE)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, CourseSortableColum.COACHEE)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.startDateTH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Start date')}</span>
                  {startedDateSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, CourseSortableColum.STARTED_AT)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, CourseSortableColum.STARTED_AT)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.sessionTH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Session', { count: 2 })}</span>
                  {progressSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, CourseSortableColum.NUMBER_OF_SESSION_DONE)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, CourseSortableColum.NUMBER_OF_SESSION_DONE)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.progressTH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Progression')}</span>
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.coachTH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Coach', { count: 2 })}</span>
                  {coachSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, CourseSortableColum.COACH)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, CourseSortableColum.COACH)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.evaluationTH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Evaluation')}</span>
                  {evaluationSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, CourseSortableColum.EVALUATION)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, CourseSortableColum.EVALUATION)}
                    />
                  )}
                </div>
              </th>
            </tr>
          </thead>
          {coursesList.data?.listCourses && <tbody>{renderCourseRow(coursesList.data.listCourses.courses)}</tbody>}
        </table>
        {coursesList.fetching && <AwaitingComp fluid={false} />}
      </div>

      {state.openChangeCoachModal && (
        <ModalChangeCoach
          selectedCourse={state.selectedCourse}
          refetchListCourse={() => refetchCoursesList()}
          handleClose={() => setState({ ...state, openChangeCoachModal: false })}
        />
      )}
      {state.openForceCloseCourseModal && (
        <ForceCloseCourseModal
          selectedCourse={state.selectedCourse}
          handleClose={() => setState({ ...state, openForceCloseCourseModal: false })}
          refetchListCourse={refetchCoursesList}
          open={state.openForceCloseCourseModal}
          removeCheck={() => setState({ ...state, selectedCourse: null })}
        />
      )}
    </>
  );
};

export default React.memo(CourseListTable);
