import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, CheckboxProps, Grid, Icon, Popup, Radio, Transition } from 'semantic-ui-react';
import { useMutation, useQuery } from 'urql';
import { Coach_classify_decode, LANGUGAGE_map } from '../../config/helperConstants';
import {
  AlertModalContentQuestionStyle,
  Coach_classify,
  LANGUAGES_Translation,
  CERTIFICATIONS,
  STATUS_CODE,
  LANGUAGE_Code,
  CoachCode_Explanation,
  Coach_Actions,
  FormMessages,
} from '../../models/enum';
import { ICoachExtended, IThematic } from '../../models/type';
import { COACH_create, COACH_list_thematics, COACH_update_coach_admin } from '../../services/apis/coachApi';
import AlertModal from '../Common/AlertModal/AlertModal';
import styles from './editableCoachDetailComp.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CoachThematicTable from '../CoachThematicTable/CoachThematicTable';
import CoachCriteriaTable from './CoachCriteriaTable';
import ThemanticQuestionsBlock from './ThemanticQuestionsBlock';
import PreviewFile from './PreviewFile';

interface Props {
  editMode: boolean;
  coachDetail?: ICoachExtended;
}

const EditableCoachDetailComp: React.FC<Props> = (props: Props) => {
  /*STATE*/

  const [formState, setFormState] = useState({
    first_name: null,
    last_name: null,
    email: null,
    target_user_position: null,
    hasEdited: false,
    additional_languages: [],
    certifications: [],
    otherCertifications: '',
    thematics: [],
    is_active: false,
    quote: null,
    presentation: null,
    picture: null,
  });

  const [helperState, setHelperState] = useState({
    isEditing: !props.editMode,
    isModalShowed: false,
    showOtherCertificateInput: false,
    message: '',
    showInstructionTable: false,
    backup: {
      formstate: null,
    },
  });

  const thematicsSelector = useSelector((state: any) => state.coachReducer.listThematics);

  const [listThematics] = useQuery({
    query: COACH_list_thematics,
    requestPolicy: 'cache-and-network',
  });

  /*3rd party hooks*/

  const [createCoachResult, executeCreateCoach] = useMutation(COACH_create);

  const [, executeUpdateCoach] = useMutation(COACH_update_coach_admin);

  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
      hasEdited: true,
    });
  };

  const handleOnChangeRadio = useCallback(
    (e: React.FormEvent<HTMLInputElement>, { value }: CheckboxProps) => {
      setFormState({
        ...formState,
        target_user_position: value,
        hasEdited: true,
      });
    },
    [formState],
  );

  const handleOnChangeCheckboxLanguages = useCallback(
    (e: React.FormEvent<HTMLInputElement>, { value, name }: CheckboxProps) => {
      const tempArr = [...formState.additional_languages];
      const indexFound = tempArr.findIndex((item: string) => {
        return item === value;
      });
      if (indexFound === -1) tempArr.push(value);
      else tempArr.splice(indexFound, 1);
      setFormState({ ...formState, [name]: tempArr, hasEdited: true });
    },
    [formState],
  );

  const handleOnChangeCheckboxCertification = useCallback(
    (e: React.FormEvent<HTMLInputElement>, { value, name }: CheckboxProps) => {
      const tempArr = [...formState.certifications];
      if (value === CERTIFICATIONS.OTHER) {
        setFormState({
          ...formState,
          hasEdited: true,
          otherCertifications: '',
        });
        setHelperState({
          ...helperState,
          showOtherCertificateInput: !helperState.showOtherCertificateInput,
        });
        return;
      }
      const indexFound = tempArr.findIndex((item: string) => {
        return item === value;
      });
      if (indexFound === -1) tempArr.push(value);
      else tempArr.splice(indexFound, 1);
      setFormState({ ...formState, [name]: tempArr, hasEdited: true });
    },
    [formState, helperState],
  );

  const handleOnChangeCheckboxThematic = useCallback(
    (value: string) => {
      const tempArr = [...formState.thematics];
      const indexFound = tempArr.findIndex((item: string) => {
        return item === value;
      });
      if (indexFound === -1) tempArr.push(value);
      else tempArr.splice(indexFound, 1);
      setFormState({ ...formState, thematics: tempArr, hasEdited: true });
    },
    [formState],
  );

  const renderThemanticQuestionsBlock = () => {
    const mappedArray = [];
    for (let key in thematicsSelector) {
      mappedArray.push(
        <ThemanticQuestionsBlock
          title={key}
          selectedThemanticsState={formState.thematics}
          themanticQuestionsArray={thematicsSelector[key]}
          onChange={(value) => handleOnChangeCheckboxThematic(value)}
        />,
      );
    }
    return mappedArray;
  };

  const handleSwitchEditing = () => {
    setHelperState({
      ...helperState,
      isEditing: true,
      message: '',
      showOtherCertificateInput: !!formState.otherCertifications,
      backup: { ...helperState.backup, formstate: formState },
    });
    setFormState({ ...formState, hasEdited: false });
  };

  const handleAgreeCancelForm = useCallback(() => {
    if (props.editMode) {
      setFormState(helperState.backup.formstate);
      setHelperState({
        ...helperState,
        isModalShowed: false,
        isEditing: false,
        showOtherCertificateInput: false,
      });
      return;
    }
    setFormState({
      first_name: '',
      last_name: '',
      email: '',
      target_user_position: '',
      hasEdited: false,
      additional_languages: [],
      certifications: [],
      thematics: [],
      otherCertifications: '',
      is_active: false,
      quote: '',
      presentation: '',
      picture: '',
    });
    setHelperState({
      ...helperState,
      isEditing: false,
      isModalShowed: false,
      showOtherCertificateInput: false,
    });
  }, [helperState, props.editMode]);

  const handleSubmit = () => {
    const tempCertifications = [...formState.certifications];
    if (formState.otherCertifications) {
      tempCertifications.push(formState.otherCertifications);
    }

    switch (props.editMode) {
      //Update coach detail
      case true:
        executeUpdateCoach({
          coach_id: props.coachDetail.id,
          firstName: formState.first_name,
          lastName: formState.last_name,
          email: formState.email,
          presentation: props.coachDetail.presentation,
          quote: props.coachDetail.quote,
          target_user_position: formState.target_user_position,
          additional_languages: formState.additional_languages,
          certifications: tempCertifications,
          thematics: formState.thematics,
          is_active: formState.is_active,
        })
          .then((res) => {
            if (+res.data?.updateAdminCoach.status_code === +STATUS_CODE.SUCCESS) {
              setHelperState({
                ...helperState,
                message: FormMessages.Success,
                showInstructionTable: false,
                isEditing: false,
                showOtherCertificateInput: false,
              });
              setFormState({ ...formState, hasEdited: false });
            } else {
              console.error(res.error);
              setHelperState({
                ...helperState,
                message: FormMessages.Failed,
                showInstructionTable: false,
              });
              setFormState(helperState.backup.formstate);
            }
          })
          .catch((err) => console.error(err));
        return;
      //Create coach detail
      case false:
        executeCreateCoach({
          firstName: formState.first_name,
          lastName: formState.last_name,
          email: formState.email,
          target_user_position: formState.target_user_position,
          additional_languages: formState.additional_languages,
          certifications: tempCertifications,
          thematics: formState.thematics,
        })
          .then((res) => {
            if (+res.data.createCoach.status_code === +STATUS_CODE.SUCCESS) {
              navigate(`/coach-detail/?${res.data.createCoach.id}`);
            } else {
              console.error(res.error);
            }
          })
          .catch((err) => console.error(err));
        return;
    }
  };

  /*EFFECTS*/

  useEffect(() => {
    if (listThematics.data?.listThematics?.status_code === +STATUS_CODE.SUCCESS) {
      dispatch({
        type: Coach_Actions.set_thematics,
        payload: listThematics.data.listThematics.thematics,
      });
    } else {
      console.error(listThematics.error);
    }
  }, [dispatch, listThematics]);

  /*EFFECT coach detail*/

  useEffect(() => {
    if (!props.editMode) return;
    const officialCertifications: Array<string> = [CERTIFICATIONS.AAC, CERTIFICATIONS.MCC, CERTIFICATIONS.PCC];

    const filteredOfficialCertificate = props.coachDetail.certifications.filter((item: string) => {
      return officialCertifications.includes(item);
    });

    const filteredOtherCertification = props.coachDetail.certifications.filter((item: string) => {
      return !officialCertifications.includes(item);
    });

    setFormState({
      ...formState,
      first_name: props.coachDetail.user_infos.first_name,
      last_name: props.coachDetail.user_infos.last_name,
      email: props.coachDetail.user_infos.email,
      target_user_position: props.coachDetail.target_user_position,
      hasEdited: false,
      is_active: props.coachDetail.is_active,
      additional_languages: props.coachDetail.additional_languages ? props.coachDetail.additional_languages : [],
      certifications: filteredOfficialCertificate, //need to separate other certifications
      otherCertifications: filteredOtherCertification.length ? filteredOtherCertification[0] : '', //need to separate other certifications
      thematics: props.coachDetail.thematics.map((item: IThematic) => {
        return item.id;
      }),
      quote: props.coachDetail.quote,
      presentation: props.coachDetail.presentation,
      picture: props.coachDetail.picture,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*MAIN RETURN*/

  return (
    <div className={styles.formWrapper}>
      {!helperState.isEditing && (
        <Button className={`${styles.editBtn}`} onClick={handleSwitchEditing}>
          <Icon name='pencil alternate' className={styles.pencilIcon} />
          <span className={`topLevelText buttonText ${styles.editText}`}>{translate('Edit')}</span>
        </Button>
      )}
      {props.editMode && !helperState.isEditing && (
        <div className={`${styles.previewWrapper} ${styles.photoPositionTop} `}>
          <PreviewFile photoLink={formState.picture} photoName={formState.first_name + ' ' + formState.last_name} />
        </div>
      )}
      <div className={styles.formContainer}>
        <form className={styles.formCreateCoach}>
          <Transition.Group animation={'slide down'} duration={400}>
            {helperState.message && (
              <p
                className={
                  helperState.message === FormMessages.Success ? 'textInfoFormMessage' : 'textDangerFormMessage'
                }>
                {translate(helperState.message)}
              </p>
            )}
          </Transition.Group>
          {props.editMode && (
            <div className={styles.formRow}>
              <div className={`${styles.formField} ${styles.noBorderBottom}`}>
                <div className={styles.labelWrapper}>
                  <label className={styles.label}>{translate('Coach activate')}</label>
                </div>
                <Popup
                  content={translate('Click modify to change coach status')}
                  trigger={
                    <Checkbox
                      checked={formState.is_active}
                      readOnly={!helperState.isEditing}
                      onChange={() => {
                        setFormState({
                          ...formState,
                          is_active: !formState.is_active,
                          hasEdited: true,
                        });
                      }}
                      toggle
                      disabled={!props.coachDetail.can_deactivate && helperState.isEditing}
                    />
                  }
                  disabled={helperState.isEditing}
                />
              </div>
            </div>
          )}

          <div className={styles.formRow}>
            <div className={styles.formField}>
              <div className={styles.labelWrapper}>
                <label className={styles.label}>
                  {translate('First name')}
                  {helperState.isEditing && <sup className={styles.sup}>*</sup>}
                </label>
              </div>
              <input
                className={`${styles.inputField} ${styles.w42}`}
                name='first_name'
                type='text'
                placeholder={translate('First name')}
                value={formState.first_name}
                readOnly={!helperState.isEditing}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
            </div>
            <div className={`${styles.formField} ${styles.positioningInput} `}>
              <div className={`${styles.labelWrapper} `}>
                <label className={styles.label}>
                  {translate('Name')}
                  {helperState.isEditing && <sup className={styles.sup}>*</sup>}
                </label>
              </div>
              <input
                className={`${styles.inputField}`}
                name='last_name'
                type='text'
                placeholder='Nom de famille'
                value={formState.last_name}
                readOnly={!helperState.isEditing}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
            </div>
          </div>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>
                {translate('Email')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={`${styles.inputField} `}
              name='email'
              type='email'
              placeholder='nom@email.com'
              value={formState.email}
              readOnly={!helperState.isEditing}
              onChange={(e) => {
                handleOnChange(e);
              }}
            />
          </div>
          <div className={styles.formField}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>{translate('Language')}</label>
            </div>
            <div className={styles.checkboxWrapper}>
              {helperState.isEditing ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column stretched computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate(LANGUAGES_Translation.fr)}</label>}
                        name='additional_languages'
                        value={LANGUAGE_Code.fr}
                        checked={formState.additional_languages.includes(LANGUAGE_Code.fr)}
                        onChange={handleOnChangeCheckboxLanguages}
                      />
                    </Grid.Column>
                    <Grid.Column stretched computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate(LANGUAGES_Translation.en)}</label>}
                        name='additional_languages'
                        value={LANGUAGE_Code.en}
                        checked={formState.additional_languages.includes(LANGUAGE_Code.en)}
                        onChange={handleOnChangeCheckboxLanguages}
                      />
                    </Grid.Column>
                    <Grid.Column stretched computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate(LANGUAGES_Translation.es)}</label>}
                        name='additional_languages'
                        value={LANGUAGE_Code.es}
                        checked={formState.additional_languages.includes(LANGUAGE_Code.es)}
                        onChange={handleOnChangeCheckboxLanguages}
                      />
                    </Grid.Column>
                    <Grid.Column stretched computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate(LANGUAGES_Translation.de)}</label>}
                        name='additional_languages'
                        value={LANGUAGE_Code.de}
                        checked={formState.additional_languages.includes(LANGUAGE_Code.de)}
                        onChange={handleOnChangeCheckboxLanguages}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <div>
                  {[...formState.additional_languages].map((item: string) => {
                    return <span className={styles.languagesViewItem}>{translate(LANGUGAGE_map.get(item))}</span>;
                  })}
                </div>
              )}
            </div>
          </div>

          <div className={`${styles.formField} ${helperState.showOtherCertificateInput && styles.noBorderBottom}`}>
            <div className={styles.labelWrapper}>
              <label className={styles.label}>{translate('Certificate')}</label>
            </div>
            <div className={styles.checkboxWrapper}>
              {helperState.isEditing ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{CERTIFICATIONS.AAC}</label>}
                        name='certifications'
                        value={CERTIFICATIONS.AAC}
                        checked={formState.certifications.includes(CERTIFICATIONS.AAC)}
                        onChange={handleOnChangeCheckboxCertification}
                      />
                    </Grid.Column>
                    <Grid.Column computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{CERTIFICATIONS.MCC}</label>}
                        name='certifications'
                        value={CERTIFICATIONS.MCC}
                        checked={formState.certifications.includes(CERTIFICATIONS.MCC)}
                        onChange={handleOnChangeCheckboxCertification}
                      />
                    </Grid.Column>
                    <Grid.Column computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{CERTIFICATIONS.PCC}</label>}
                        name='certifications'
                        value={CERTIFICATIONS.PCC}
                        checked={formState.certifications.includes(CERTIFICATIONS.PCC)}
                        onChange={handleOnChangeCheckboxCertification}
                      />
                    </Grid.Column>
                    <Grid.Column computer='4'>
                      <Checkbox
                        className={styles.checkbox}
                        label={<label>{translate('Other')}</label>}
                        name='certifications'
                        value={CERTIFICATIONS.OTHER}
                        checked={helperState.showOtherCertificateInput}
                        onChange={handleOnChangeCheckboxCertification}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <div>
                  {[...formState.certifications, formState.otherCertifications].map((item) => (
                    <span className={styles.certificationViewItem}>{item}</span>
                  ))}
                </div>
              )}
            </div>
          </div>

          {helperState.showOtherCertificateInput && (
            <div className={`${styles.formField}`}>
              <div className={`${styles.labelWrapper}`}>
                <label className={styles.label}></label>
              </div>
              <input
                className={`${styles.inputField} `}
                name='otherCertifications'
                type='text'
                placeholder='ex: MBA, MS'
                onChange={handleOnChange}
                value={formState.otherCertifications}
              />
            </div>
          )}

          {props.editMode && !helperState.isEditing && (
            <>
              <div className={styles.formField}>
                <div className={styles.labelWrapper}>
                  <label className={styles.label}>{translate('Coach introduction')}</label>
                </div>
                <div className={`${styles.inputField} `}>{formState.quote}</div>
              </div>
              <div className={`${styles.formField} ${styles.presentationField}`}>
                <div className={styles.labelWrapper}>
                  <label className={styles.label}>{translate('Introduction')}</label>
                </div>
                {!!formState.presentation && (
                  <div className={`${styles.inputField} ${styles.presentationOutline}`}>{formState.presentation}</div>
                )}
              </div>
            </>
          )}

          <div className={`${styles.formField} ${helperState.isEditing && styles.noBorderBottom}`}>
            <div className={`${styles.labelWrapper} ${helperState.isEditing && styles.radioLabel}`}>
              <label className={styles.label}>
                {translate('Coach classification')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            {!helperState.isEditing && <div>{Coach_classify_decode.get(formState.target_user_position)}</div>}
          </div>
          {helperState.isEditing && (
            <div className={styles.radioWrapper}>
              <Grid>
                <Grid.Row>
                  <Grid.Column computer='4'>
                    <Radio
                      className={styles.radioBox}
                      label={translate(CoachCode_Explanation.EXEC)}
                      name='target_user_position'
                      value={Coach_classify.Executive}
                      onChange={handleOnChangeRadio}
                      checked={formState.target_user_position === Coach_classify.Executive}
                    />
                  </Grid.Column>
                  <Grid.Column computer='4'>
                    <Radio
                      className={styles.radioBox}
                      label={translate(CoachCode_Explanation.MNGR)}
                      name='target_user_position'
                      value={Coach_classify.Senior}
                      onChange={handleOnChangeRadio}
                      checked={formState.target_user_position === Coach_classify.Senior}
                    />
                  </Grid.Column>
                  <Grid.Column computer='4'>
                    <Radio
                      className={styles.radioBox}
                      label={translate(CoachCode_Explanation.CDP)}
                      name='target_user_position'
                      value={Coach_classify.Coach}
                      onChange={handleOnChangeRadio}
                      checked={formState.target_user_position === Coach_classify.Coach}
                    />
                  </Grid.Column>
                  <Grid.Column computer='4'>
                    <Radio
                      className={styles.radioBox}
                      label={translate(CoachCode_Explanation.CLB)}
                      name='target_user_position'
                      value={Coach_classify.Future}
                      onChange={handleOnChangeRadio}
                      checked={formState.target_user_position === Coach_classify.Future}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </form>
        {helperState.isEditing ? (
          <div className={`${styles.rightGroup} ${styles.buttonRightGroup}`}>
            <Button
              className={`${styles.btnSave} ${
                formState.first_name &&
                formState.last_name &&
                formState.email &&
                formState.thematics.length &&
                formState.hasEdited &&
                styles.active
              } ${createCoachResult.fetching && styles.disable}`}
              disabled={
                !formState.hasEdited ||
                !formState.first_name ||
                !formState.last_name ||
                !formState.email ||
                !formState.thematics.length
              }
              onClick={handleSubmit}>
              <span className='buttonText'>{translate('Save')}</span>
            </Button>
            <Button
              className={styles.btnCancel}
              onClick={() => {
                if (formState.hasEdited)
                  setHelperState({
                    ...helperState,
                    isModalShowed: true,
                  });
                else
                  setHelperState({
                    ...helperState,
                    isEditing: false,
                    showOtherCertificateInput: false,
                  });
              }}>
              <span className='buttonText'>{translate('Cancel')}</span>
            </Button>
          </div>
        ) : (
          <div className={styles.rightGroup}>
            {props.editMode && (
              <div className={`${styles.previewWrapper} ${styles.photoPositionLeft}`}>
                <PreviewFile
                  photoLink={formState.picture}
                  photoName={formState.first_name + ' ' + formState.last_name}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {helperState.isEditing && <CoachCriteriaTable />}
      <Transition.Group animation='slide down' duration={400}>
        {helperState.isEditing && !!formState.target_user_position && (
          <div className={styles.thematicsWrapper}>
            <div className={styles.thematicsTitle}>{translate('Coach thematics')}</div>
            {renderThemanticQuestionsBlock()}
          </div>
        )}
      </Transition.Group>

      {props.editMode && !helperState.isEditing && (
        <CoachThematicTable
          thematics={props.coachDetail.thematics}
          target_user_position={props.coachDetail.target_user_position}
        />
      )}
      {helperState.isEditing && (
        <div className={`${styles.buttonBottomGroup}`}>
          <Button
            className={`${styles.btnSave} ${
              formState.first_name &&
              formState.last_name &&
              formState.email &&
              formState.thematics.length &&
              formState.hasEdited &&
              styles.active
            } ${createCoachResult.fetching && styles.disable}`}
            disabled={
              !formState.hasEdited ||
              !formState.first_name ||
              !formState.last_name ||
              !formState.email ||
              !formState.thematics.length
            }
            onClick={handleSubmit}>
            <span className='buttonText'>{translate('Save')}</span>
          </Button>
          <Button
            className={styles.btnCancel}
            onClick={() => {
              if (formState.hasEdited)
                setHelperState({
                  ...helperState,
                  isModalShowed: true,
                });
              else
                setHelperState({
                  ...helperState,
                  isEditing: false,
                  showOtherCertificateInput: false,
                });
            }}>
            <span className='buttonText'>{translate('Cancel')}</span>
          </Button>
        </div>
      )}
      {helperState.isModalShowed && (
        <AlertModal
          title={translate('Confirm cancel')}
          contentQuestion={[
            {
              text: translate('Do you want to undo?'),
              style: AlertModalContentQuestionStyle.BASIC,
            },
          ]}
          content={translate('You will be return to previous page with unsaved data')}
          cancel={() => setHelperState({ ...helperState, isModalShowed: false })}
          agree={handleAgreeCancelForm}
        />
      )}
    </div>
  );
};

export default EditableCoachDetailComp;
