import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Search, SearchProps, Segment } from 'semantic-ui-react';
import { useMutation, useQuery } from 'urql';
import { AlertModalContentQuestionStyle, FormMessages, Program_Actions, STATUS_CODE } from '../../models/enum';
import { ICoach_render_coach_team, ICoach_search } from '../../models/type';
import { COACH_create_team, COACH_searchCoach } from '../../services/apis/coachApi';
import { COACHTEAM_detailCoachTeam, COACHTEAM_updateCoachTeam } from '../../services/apis/coachTeamApi';
import AlertModal from '../Common/AlertModal/AlertModal';
import styles from './editableTeamCoachs.module.scss';

interface Props {
  programName: string;
}

const EditableTeamCoachs: React.FC<Props> = (props: Props) => {
  const [helperState, setHelperState] = useState({
    showMainForm: false,
    isEditing: true,
    editMode: false,
    showModal: false,
    coachTeamId: '',
    searchCoachValue: '',
    backupCoachsTeam: [],
    backupTeamName: '',
    messageState: '',
    firstMounted: true,
  });

  const [formState, setFormState] = useState({
    teamName: '',
    isEdited: false,
  });

  const teamCoachOnStore = useSelector((state: any) => state.programReducer.coachsTeam);

  const [searchCoachResult, querySeachCoach] = useQuery({
    query: COACH_searchCoach,
    variables: {
      count: 100,
      page: 1,
      search_name: helperState.searchCoachValue,
      order_by: 'active',
      direction: 'DESC',
    },
    pause: true,
  });

  const [detailCoachTeam, queryDetailCoachTeam] = useQuery({
    query: COACHTEAM_detailCoachTeam,
    variables: {
      program_id: window.location.pathname.split('/').pop(),
    },
    requestPolicy: 'network-only',
    pause: true,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [, executeCreateCoachTeam] = useMutation(COACH_create_team);

  const [, executeUpdateCoachTeam] = useMutation(COACHTEAM_updateCoachTeam);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.currentTarget.name]: e.currentTarget.value,
      isEdited: true,
    });
  };

  const handleSearchChange = useCallback(
    (e: React.MouseEvent<HTMLElement>, data: SearchProps) => {
      setHelperState({
        ...helperState,
        showMainForm: true,
        searchCoachValue: data.value,
      });
    },
    [helperState],
  );

  const handleSwitchEditing = useCallback(
    (helperState: any, coachsTeam: Array<ICoach_render_coach_team>, teamName: string) => {
      setHelperState({
        ...helperState,
        isEditing: true,
        backupCoachsTeam: coachsTeam,
        backupTeamName: teamName,
        messageState: '',
      });
      setFormState({ ...formState, isEdited: false });
    },
    [formState],
  );

  const handleAgreeCancelForm = useCallback(
    (backupCoachsTeam: Array<ICoach_render_coach_team>, backupTeamName: string) => {
      setFormState({ ...formState, teamName: backupTeamName, isEdited: false });
      setHelperState({ ...helperState, showModal: false, isEditing: false });
      dispatch({
        type: Program_Actions.reset_coach_team_with_backup,
        payload: backupCoachsTeam,
      });
    },

    [dispatch, formState, helperState],
  );

  const renderSelectedCoachs = useCallback(
    (coachsArray: Array<ICoach_render_coach_team>) => {
      return coachsArray.map((item: ICoach_render_coach_team) => {
        return (
          <li key={item.id}>
            <span>{item.name}</span>
            {helperState.isEditing && (
              <Icon
                name='remove'
                className={styles.iconRemove}
                onClick={() => {
                  dispatch({
                    type: Program_Actions.remove_coach_from_team,
                    payload: item.id,
                  });
                  setFormState({ ...formState, isEdited: true });
                }}
              />
            )}
          </li>
        );
      });
    },
    [dispatch, formState, helperState.isEditing],
  );

  const renderSearchResult = useCallback(
    (coachList: Array<ICoach_search>) => {
      return coachList.map((item: ICoach_search) => {
        return {
          title:
            item.user_infos.first_name +
            ' ' +
            item.user_infos.last_name +
            (item.is_active ? '' : ' (' + translate('Inactive') + ')'),
          id: item.id,
        };
      });
    },
    [translate],
  );

  const handleSubmit = useCallback(
    (name: string, programID: string, coaches: Array<ICoach_render_coach_team>) => {
      if (!formState.teamName) return;
      const formatCoachsID = coaches.map((item: ICoach_render_coach_team) => {
        return item.id;
      });
      switch (helperState.editMode) {
        //editMode true => need api update team coach
        case true:
          return;
        case false:
          //editmode fale => create
          executeCreateCoachTeam({
            name: name,
            program_id: programID,
            coaches_id: formatCoachsID,
          })
            .then((res) => {
              if (res.data.createCoachTeam.status_code === STATUS_CODE.SUCCESS) {
                setHelperState({
                  ...helperState,
                  editMode: true,
                  isEditing: false,
                  messageState: FormMessages.Success,
                });
              } else {
                setHelperState({
                  ...helperState,
                  messageState: FormMessages.Failed,
                });
                console.error(res.error.graphQLErrors[0].extensions.error_code);
              }
            })
            .catch((err) => console.error(err));
          return;
      }
    },
    [executeCreateCoachTeam, formState.teamName, helperState],
  );

  const handleUpdateCoachTeam = useCallback(
    (id: string, name: string, coach_ids: Array<ICoach_render_coach_team>) => {
      if (!formState.teamName) return;
      const formatCoachsID = coach_ids.map((item: ICoach_render_coach_team) => {
        return item.id;
      });
      executeUpdateCoachTeam({
        id: id,
        name: name,
        coach_ids: formatCoachsID,
      })
        .then((res) => {
          if (res.data.updateCoachTeam.status_code === STATUS_CODE.SUCCESS) {
            setHelperState({
              ...helperState,
              editMode: true,
              isEditing: false,
              messageState: FormMessages.Success,
            });
          } else {
            console.error(res.error.graphQLErrors[0].extensions.error_code);
          }
        })
        .catch((err) => console.error(err));
      return;
    },
    [executeUpdateCoachTeam, formState.teamName, helperState],
  );

  /*EFFECTS*/

  useEffect(() => {
    if (!helperState.searchCoachValue) return; //check if allow to search
    querySeachCoach();
  }, [helperState.searchCoachValue, querySeachCoach]);

  useEffect(() => {
    queryDetailCoachTeam();
    if (!props.programName) return;
    setFormState({ ...formState, teamName: props.programName });
    return () => {
      dispatch({
        type: Program_Actions.reset_coach_team_with_backup,
        payload: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!detailCoachTeam.data) return;

    setFormState({
      ...formState,
      teamName: detailCoachTeam.data.detailCoachTeam.coach_team.name,
    });
    const coaches = detailCoachTeam.data.detailCoachTeam.coach_team.coaches;
    if (!teamCoachOnStore.length && helperState.firstMounted) {
      setHelperState({
        ...helperState,
        isEditing: false,
        showMainForm: true,
        editMode: true,
        coachTeamId: detailCoachTeam.data.detailCoachTeam.coach_team.id,
        firstMounted: false,
      });
      dispatch({
        type: Program_Actions.set_coachs_team,
        payload: coaches,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailCoachTeam]);

  // @ts-ignore
  const resultRenderer = ({ title }) => (
    <div style={(title as string).includes(translate('Inactive')) ? { color: 'grey' } : {}}>{title}</div>
  );

  return (
    <Segment className={styles.customedSegment}>
      <h3 className={styles.title}>{translate('Program coach team')}</h3>
      {helperState.showMainForm ? (
        <>
          {helperState.messageState && (
            <p
              className={
                helperState.messageState === FormMessages.Success ? styles.textSuccessMessage : styles.textErrorMessage
              }>
              {translate(helperState.messageState)}
            </p>
          )}
          <div className={styles.mainContent}>
            <form className={styles.form}>
              {!helperState.isEditing && (
                <Button
                  className={`${styles.editBtn}`}
                  onClick={() => handleSwitchEditing(helperState, teamCoachOnStore, formState.teamName)}>
                  <Icon name='pencil alternate' />
                  <span className={`topLevelText`}>{translate('Edit')}</span>
                </Button>
              )}
              <div className={styles.formField}>
                <div className={styles.labelWrapper}>
                  <label className={styles.label}>{translate('Program name')}</label>
                </div>
                <input
                  className={styles.inputField}
                  onChange={(e) => handleOnChange(e)}
                  name='teamName'
                  type='text'
                  placeholder={translate('Team name')}
                  readOnly={!helperState.isEditing}
                  value={formState.teamName}
                />
              </div>

              {helperState.isEditing ? (
                <div className={styles.searchWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.label}>{translate('Add a coach')}</label>
                  </div>
                  <Search
                    size='small'
                    noResultsMessage={translate('Search not match any name')}
                    value={helperState.searchCoachValue}
                    loading={searchCoachResult.fetching}
                    onResultSelect={(e, data) => {
                      if ((e.target as Element).innerHTML.includes(translate('Inactive'))) return;

                      dispatch({
                        type: Program_Actions.add_coach_to_team,
                        payload: data.result,
                      });
                      setFormState({ ...formState, isEdited: true });
                      setHelperState({ ...helperState, searchCoachValue: '' });
                    }}
                    onSearchChange={(e, data) => handleSearchChange(e, data)}
                    resultRenderer={resultRenderer}
                    results={
                      searchCoachResult.data?.listCoaches.coaches
                        ? renderSearchResult(searchCoachResult.data?.listCoaches.coaches)
                        : []
                    }
                  />
                </div>
              ) : (
                <p className={styles.listCoachesText}>{translate('Coachs list') + ' :'}</p>
              )}
              <div className={styles.selectedCoachWrapper}>
                <ul>{renderSelectedCoachs(teamCoachOnStore)}</ul>
              </div>
            </form>
            <div className={`${styles.rightGroup} ${helperState.isEditing && styles.center}`}>
              {helperState.isEditing && (
                <>
                  <button
                    disabled={!formState.isEdited}
                    className={`${styles.btnSave} ${
                      formState.teamName && formState.isEdited && styles.btnActive
                    } topLevelText`}
                    onClick={() =>
                      helperState.editMode
                        ? handleUpdateCoachTeam(helperState.coachTeamId, formState.teamName, teamCoachOnStore)
                        : handleSubmit(formState.teamName, window.location.pathname.split('/').pop(), teamCoachOnStore)
                    }>
                    {translate('Save')}
                  </button>
                  <button
                    className={`${styles.btnCancel} topLevelText`}
                    onClick={() => {
                      if (formState.isEdited) setHelperState({ ...helperState, showModal: true });
                      else setHelperState({ ...helperState, isEditing: false });
                    }}>
                    {translate('Cancel')}
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.firstContent}>
          <span>{translate('No coaching team')}</span>
          <span>{translate('Click here to create coaching team')}</span>
          <Icon
            name='plus circle'
            size='huge'
            className={styles.plusIcon}
            onClick={() => {
              setHelperState({ ...helperState, showMainForm: true });
            }}
          />
        </div>
      )}
      {helperState.showModal && (
        <AlertModal
          title={translate('Confirm cancel')}
          contentCenter={false}
          contentQuestion={[
            {
              text: translate('Do you want to undo?'),
              style: AlertModalContentQuestionStyle.BASIC,
            },
          ]}
          content={translate('You will be return to previous page with unsaved data')}
          cancel={() => setHelperState({ ...helperState, showModal: false })}
          agree={() => handleAgreeCancelForm(helperState.backupCoachsTeam, helperState.backupTeamName)}
        />
      )}
    </Segment>
  );
};

export default EditableTeamCoachs;
