import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { Coach_thamatic_title } from '../../config/helperConstants';
import { IThematic } from '../../models/type';
import styles from './editableCoachDetailComp.module.scss';

interface Props {
  title: string;
  selectedThemanticsState: Array<string>;
  themanticQuestionsArray: any;
  onChange?: (value: string) => void;
}

const ThemanticQuestionsBlock: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const renderThematicQuestions = (renderArray: Array<IThematic>) => {
    return renderArray.map((item: IThematic, index: number) => {
      return (
        <Checkbox
          key={item.id}
          className={`${styles.checkbox} ${styles.thematicQuestion}`}
          label={<label>{item.title}</label>}
          name={item.user_position}
          value={item.id}
          checked={props.selectedThemanticsState.includes(item.id)}
          onChange={() => {
            props.onChange(item.id);
          }}
        />
      );
    });
  };

  return (
    <div className={styles.thematicsQuestionWrapper}>
      <p className={`${styles.questionCategory} topLevelText`}>{Coach_thamatic_title.get(props.title)}</p>
      <div className={styles.thematicsQuestionBlock}>{renderThematicQuestions(props.themanticQuestionsArray)}</div>
    </div>
  );
};

export default ThemanticQuestionsBlock;
