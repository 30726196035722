import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon, Popup, Segment } from 'semantic-ui-react';
import { IProgramManagerExtended, ProgramRef } from '../../models/type';
import ZzzIcon from '../ZzzIcon/ZzzIcon';
import styles from './cardProgramManager.module.scss';

interface Props {
  programManager: IProgramManagerExtended;
  removeProgramManger?: () => void;
  showDeleteCross?: boolean;
}

const CardProgramManager: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  /*METHODS*/

  const { t: translate } = useTranslation();

  const programId = window.location.pathname.split('/').pop();

  /*MAIN RETURN*/
  return (
    <div className={styles.wrapper}>
      <div className={styles.blueBlock}></div>
      {props.showDeleteCross && (
        <Icon name='close' className={styles.iconClose} onClick={() => props.removeProgramManger()} />
      )}
      <Segment className={styles.customedSegment}>
        <div className={styles.leftBlock}>
          <p className={styles.managerName}>
            {props.programManager.user_infos.first_name + ' ' + props.programManager.user_infos.last_name}{' '}
            {props.programManager.user_infos.gdpr_consent === false && (
              <span>
                <ZzzIcon />
              </span>
            )}
          </p>
          <div>
            <p className={styles.normalText}>{props.programManager.user_infos.email}</p>
            <p>
              <span className={styles.boldtext}>{translate('Job')}: </span>
              <span className={styles.normalText}>{props.programManager.user_infos.department}</span>
            </p>
            <p>
              <span className={styles.boldtext}>{translate('Position')}: </span>
              <span className={styles.normalText}>{props.programManager.user_infos.position}</span>
            </p>
          </div>
        </div>
        <div className={styles.rightBlock}>
          <p className={styles.programTitle}>{translate('Program', { count: 2 })}:</p>
          <div className={styles.programTagsBlock}>
            {props.programManager.programs.map((item: ProgramRef) => {
              return (
                <Popup
                  key={item.id}
                  trigger={
                    <p
                      className={styles.programTag}
                      onClick={() => {
                        if (item.id === programId) {
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                          return;
                        }
                        navigate(`/program-detail/${item.id}`);
                      }}>
                      {item.name.length <= 35 ? item.name : item.name.substr(0, 35) + '...'}
                    </p>
                  }
                  content={item.name}
                  position='bottom center'
                  wide
                  basic
                />
              );
            })}
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default CardProgramManager;
