import React from 'react';
import styles from './dashboardStatisticalComp.module.scss';

interface Props {
  label: string;
  category_sessions?: {
    number_of_sessions_done: number;
    total: number;
  };
  category_auto_diagnostics?: { number_of_done: number; total: number };
  simplyNumber?: number | string;
}

const DashboardStatisticalComp: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.numberBlock}>
        <div className={styles.numberContainer}>
          {props.category_sessions && (
            <>
              <p className={styles.primaryNumber}>
                {props.category_sessions.number_of_sessions_done}/{props.category_sessions.total}
              </p>
            </>
          )}

          {props.category_auto_diagnostics && (
            <p className={styles.primaryNumber}>
              {props.category_auto_diagnostics.total === 0
                ? '0%'
                : (
                    (props.category_auto_diagnostics.number_of_done / props.category_auto_diagnostics.total) *
                    100
                  ).toFixed(0) + '%'}
            </p>
          )}
          {props.simplyNumber && <p className={styles.primaryNumber}>{props.simplyNumber}</p>}
        </div>
        <div className={styles.labelBlock}>
          <p className={styles.label}>{props.label}</p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DashboardStatisticalComp);
