import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { Button, Icon } from 'semantic-ui-react';

import { ICourse, IInterruptCourseResponse } from '../../models/type';
import { STATUS_CODE } from '../../models/enum';
import { COURSE_interrupt_course } from '../../services/apis/courseApi';

import ModalCustom from '../ModalCustom/ModalCustom';
import styles from './forceCloseCourseModal.module.scss';

interface Props {
  selectedCourse: ICourse;
  open: boolean;
  handleClose: () => void;
  refetchListCourse: () => void;
  removeCheck: () => void;
}

const ForceCloseCourseModal: FC<Props> = ({
  selectedCourse,
  handleClose,
  open,
  refetchListCourse,
  removeCheck,
}: Props) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { t: translate } = useTranslation();

  const [, executeInterruptCourse] = useMutation(COURSE_interrupt_course);

  const handleClick = async () => {
    try {
      const res: IInterruptCourseResponse = await executeInterruptCourse({ course_id: selectedCourse.id });
      if (+res.data?.interruptCourse?.status_code === +STATUS_CODE.SUCCESS) {
        setShowSuccess(true);
        refetchListCourse();
        removeCheck();
      } else {
        console.error(res.error);
      }
    } catch (error) {
      console.error('Call interrupt course API failed!', error);
    }
  };

  const header: ReactNode = <h1 className={`${styles.title} topLevelText`}>{translate('Interrupt course')}</h1>;

  const body: ReactNode = !showSuccess ? (
    <p className={styles.contentQuestion}>
      {translate('Interrupt course description modal')}{' '}
      <span className={styles.primaryText}>{selectedCourse.offer_configuration.name}</span>
      {' ?'}
    </p>
  ) : (
    <p className={styles.contentQuestion}>
      <div className={styles.alignCenter}>
        <Icon name='check' className={`${styles.checkIcon} `} />
      </div>
      {translate('Interrupt course result message')}
      <div className={styles.buttonsWrapper}>
        <Button className={`${styles.btn} ${styles.closeBtn} `} onClick={handleClose}>
          <span className='topLevelText buttonText'>{translate('Close')}</span>
        </Button>
      </div>
    </p>
  );

  const actions: Array<ReactNode> = [
    <Button className={`${styles.btn} ${styles.cancelBtn} `} onClick={handleClose}>
      <span className='topLevelText buttonText'>{translate('Cancel')}</span>
    </Button>,
    <Button className={`${styles.btn} ${styles.agreeBtn} `} onClick={handleClick}>
      <span className='topLevelText buttonText'>{translate('Yes')}</span>
    </Button>,
  ];

  return (
    <ModalCustom
      actions={!showSuccess && actions}
      handleClose={handleClose}
      header={header}
      content={body}
      open={open}
    />
  );
};

export default ForceCloseCourseModal;
