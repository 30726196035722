import React, { useState } from 'react';
import EditableClientDetailComp from '../EditableClientDetailComp/EditableClientDetailComp';
import { useSelector } from 'react-redux';
import styles from './clientDetailInfo.module.scss';
import { Dropdown, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { generateDropDownValue } from '../../utilities/helper';
import ProgramDashboardComp from '../ProgramDashboardComp/ProgramDashboardComp';

const ClientDetailInfo: React.FC = () => {
  /*PROPS*/
  const [dashboardState, setDashboardState] = useState({
    selectedProgramID: null,
  });

  const clientOnStore = useSelector((state: any) => {
    return state.clientReducer.client;
  });

  /*METHODS*/
  const { t: translate } = useTranslation();

  const handleChangeDropDown = (value: any) => {
    setDashboardState({ ...dashboardState, selectedProgramID: value });
  };

  /*EFFECTS*/

  return (
    <div className={styles.wrapper}>
      <Grid>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={11}>
            <EditableClientDetailComp />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={11}>
            <p className={styles.programDashboardText}>{translate('Program dashboard')}</p>
          </Grid.Column>
        </Grid.Row>
        {clientOnStore && (
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={11}>
              <div className={styles.dropDownWrapper}>
                <Dropdown
                  className={styles.dropDown}
                  fluid
                  selection
                  options={generateDropDownValue(clientOnStore.programs)}
                  placeholder={translate('Program name')}
                  onChange={(e, { value }) => handleChangeDropDown(value)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={11}>
            <ProgramDashboardComp programID={dashboardState.selectedProgramID} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ClientDetailInfo;
