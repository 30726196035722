import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ICoachListItemExtended } from '../../models/type';
import styles from './coachRow.module.scss';

interface Props {
  coachItem: ICoachListItemExtended;
  index: number;
}

const CoachRow: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const { t: translate } = useTranslation();

  return (
    <tr className='tableListRow'>
      <td className={`${styles.tbodyTD} ${styles.boldText} secondLevelText`}>{props.index + 1}</td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        <Link to={`/coach-detail/?${props.coachItem.id}`}>
          <span className={styles.coachName}>
            {props.coachItem.user_infos.first_name + ' ' + props.coachItem.user_infos.last_name}
          </span>
        </Link>
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.coachItem.is_active ? translate('Active') : translate('Inactive')}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        <ul>
          {props.coachItem.certifications.map((certification: string) => (
            <li>{certification}</li>
          ))}
        </ul>
      </td>
      <td className={`${styles.tbodyTD} ${styles.boldText} secondLevelText`}>{props.coachItem.number_of_programs}</td>
    </tr>
  );
};

export default React.memo(CoachRow);
