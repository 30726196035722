import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { useMutation, useQuery } from 'urql';
import AlertModal from '../Common/AlertModal/AlertModal';
import { CLIENT_creation, CLIENT_getClient, CLIENT_update } from '../../services/apis/clientsApi';
import styles from './editableClientDetailComp.module.scss';
import { mapDateToContinuosArray, dateToStringConvertor, wholeWeek, dateOptions } from '../../utilities/dateConvertor';
import {
  AlertModalContentQuestionStyle,
  Client_Action,
  FormMessages,
  GRAPHQL_ERRORS,
  STATUS_CODE,
} from '../../models/enum';
import { useNavigate } from 'react-router-dom';
import { conCatHourAndMinute } from '../../utilities/helper';
import { useTranslation } from 'react-i18next';
import { mpburl } from '../../config/helperConstants';
import { useDispatch } from 'react-redux';

const EditableClientDetailComp: React.FC = () => {
  const [helperState, setHelperState] = useState({
    isEditing: true,
    editingMode: false,
    modal: false,
    loading: false,
    messageState: '',
    backup: {
      formState: null,
      timeState: null,
    },
  });

  const [formState, setFormState] = useState({
    companyName: '',
    companyType: '',
    platform: '',
    domain: '',
    edited: false,
  });

  const [timeState, setTimeState] = useState({
    startDate: null,
    endDate: null,
    startHour_hour: '09',
    startHour_minute: '00',
    endHour_hour: '18',
    endHour_minute: '00',
  });

  const [clientQueryResult] = useQuery({
    query: CLIENT_getClient,
    variables: { id: window.location.pathname.split('/').pop() },
    requestPolicy: 'cache-and-network',
  });

  /*METHODS*/

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const [, addNewClient] = useMutation(CLIENT_creation);
  const [, updateClient] = useMutation(CLIENT_update);

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.currentTarget.name]: event.currentTarget.value,
      edited: true,
    });
  };

  const handleOnChangeTime = (event: React.FormEvent<HTMLInputElement>) => {
    if (isNaN(+event.currentTarget.value)) return;
    if (event.currentTarget.name.includes('hour')) {
      if (+event.currentTarget.value < 0 || +event.currentTarget.value > 24) return;
    }
    if (event.currentTarget.name.includes('minute')) {
      if (+event.currentTarget.value < 0 || +event.currentTarget.value > 59) return;
    }
    setTimeState({
      ...timeState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSelect = (e: any, ele: any) => {
    setTimeState({
      ...timeState,
      [ele.name]: ele.value,
    });
    setFormState({ ...formState, edited: true });
  };

  const renderWorkingDate = useCallback((arrayDate: Array<number>) => {
    const converted = dateToStringConvertor(arrayDate);
    return wholeWeek.map((item: string, index: number) => {
      return (
        <span className={`${styles.dateSquare} ${!converted.includes(item) && styles.dateOff}`} key={index}>
          {item[0]}
        </span>
      );
    });
  }, []);

  const handleSwitchEditing = useCallback(() => {
    setHelperState({
      ...helperState,
      isEditing: !helperState.isEditing,
      messageState: '',
      backup: {
        formState: formState,
        timeState: timeState,
      },
    });
  }, [formState, helperState, timeState]);

  const handleAgreeCancelForm = useCallback(() => {
    if (helperState.editingMode) {
      setFormState(helperState.backup.formState);
      setTimeState(helperState.backup.timeState);
      setHelperState({ ...helperState, isEditing: false, modal: false });
      return;
    }
    setFormState({
      companyName: '',
      companyType: '',
      platform: '',
      domain: '',
      edited: false,
    });
    setTimeState({
      startDate: '',
      endDate: '',
      startHour_hour: '',
      startHour_minute: '',
      endHour_hour: '',
      endHour_minute: '',
    });
    setHelperState({ ...helperState, isEditing: false, modal: false });
  }, [helperState]);

  const canActiveSubmitButton = () => {
    if (helperState.loading) return false;
    if (
      formState.companyName &&
      formState.domain &&
      formState.platform &&
      timeState.startDate &&
      timeState.endDate &&
      timeState.startHour_hour &&
      timeState.startHour_minute &&
      timeState.endHour_hour &&
      timeState.endHour_minute &&
      formState.edited
    )
      return true;
    return false;
  };

  const handleSubmit = useCallback(() => {
    if (
      !formState.companyName ||
      !formState.domain ||
      !formState.platform ||
      !timeState.startDate ||
      !timeState.endDate ||
      !timeState.startHour_hour ||
      !timeState.startHour_minute ||
      !timeState.endHour_hour ||
      !timeState.endHour_minute
    )
      return;
    setHelperState({ ...helperState, loading: true });

    switch (helperState.editingMode) {
      case true:
        /*From detail page*/
        updateClient({
          id: window.location.pathname.split('/').pop(),
          name: formState.companyName,
          days: mapDateToContinuosArray([+timeState.startDate, +timeState.endDate]),
          open_at: `${timeState.startHour_hour}:${timeState.startHour_minute}`,
          close_at: `${timeState.endHour_hour}:${timeState.endHour_minute}`,
          subdomain: formState.domain,
          tool: formState.platform ? formState.platform : 'n/a',
          company_type: formState.companyType ? formState.companyType : 'n/a',
        })
          .then((res) => {
            setHelperState({ ...helperState, loading: false });
            if (+res.data?.updateClient.status_code === +STATUS_CODE.SUCCESS) {
              setHelperState({
                ...helperState,
                isEditing: false,
                messageState: FormMessages.Success,
              });
            }
            if (res.error.graphQLErrors[0].message === GRAPHQL_ERRORS.CLIENT_EXISTED) {
              setHelperState({
                ...helperState,
                messageState: 'Client existed',
              });
            }
            return;
          })
          .catch((err) => console.error(err));
        return;
      case false:
        /*From creation page*/
        addNewClient({
          name: formState.companyName,
          days: mapDateToContinuosArray([+timeState.startDate, +timeState.endDate]),
          open_at: conCatHourAndMinute(timeState.startHour_hour, timeState.startHour_minute),
          close_at: conCatHourAndMinute(timeState.endHour_hour, timeState.endHour_minute),
          subdomain: formState.domain,
          tool: formState.platform ? formState.platform : 'n/a',
          company_type: formState.companyType ? formState.companyType : 'n/a',
        })
          .then((res) => {
            setHelperState({ ...helperState, loading: false });
            if (+res.data?.createClient.status_code === +STATUS_CODE.SUCCESS) {
              navigate(`/client-detail/info/${res.data.createClient.client.id}`);
            } else if (res.error.message === '[GraphQL] Client already exists') {
              setHelperState({
                ...helperState,
                messageState: 'Client existed',
              });
            } else console.error(res.error.graphQLErrors[0].extensions.error_code);
          })
          .catch((err) => console.error(err));
        return;
    }
  }, [
    addNewClient,
    formState.companyName,
    formState.companyType,
    formState.domain,
    formState.platform,
    helperState,
    navigate,
    timeState.endDate,
    timeState.endHour_hour,
    timeState.endHour_minute,
    timeState.startDate,
    timeState.startHour_hour,
    timeState.startHour_minute,
    updateClient,
  ]);

  /*EFFECTS*/
  useEffect(() => {
    if (clientQueryResult?.data?.detailClient.client) {
      setFormState({
        companyName: clientQueryResult.data.detailClient.client.name,
        platform:
          clientQueryResult.data.detailClient.client.tool === 'n/a'
            ? ''
            : clientQueryResult.data.detailClient.client.tool,
        companyType:
          clientQueryResult.data.detailClient.client.company_type === 'n/a'
            ? ''
            : clientQueryResult.data.detailClient.client.company_type,
        domain: clientQueryResult.data.detailClient.client.subdomain,
        edited: false,
      });
      setTimeState({
        startDate: clientQueryResult.data.detailClient.client.days[0],
        endDate:
          clientQueryResult.data.detailClient.client.days[clientQueryResult.data.detailClient.client.days.length - 1],
        startHour_hour: clientQueryResult.data.detailClient.client.open_at.split(':')[0],
        startHour_minute: clientQueryResult.data.detailClient.client.open_at.split(':')[1],
        endHour_hour: clientQueryResult.data.detailClient.client.close_at.split(':')[0],
        endHour_minute: clientQueryResult.data.detailClient.client.close_at.split(':')[1],
      });
    }
  }, [clientQueryResult.data]);

  useEffect(() => {
    if (clientQueryResult?.data?.detailClient.client) {
      setHelperState({ ...helperState, isEditing: false, editingMode: true });
      dispatch({
        type: Client_Action.set_client,
        payload: clientQueryResult.data.detailClient.client,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientQueryResult.data]);

  /*MAIN RETURN*/

  if (clientQueryResult.fetching) return <></>;

  return (
    <div className={styles.formWrapper}>
      {helperState.messageState && (
        <p
          className={
            helperState.messageState === FormMessages.Success ? 'textInfoFormMessage' : 'textDangerFormMessage'
          }>
          {translate(helperState.messageState)}
        </p>
      )}
      {!helperState.isEditing && (
        <Button className={`${styles.editBtn}`} onClick={handleSwitchEditing}>
          <Icon name='pencil alternate' className={styles.pencilIcon} />
          <span className={`topLevelText buttonText ${styles.editText}`}>{translate('Edit')}</span>
        </Button>
      )}
      <div className={styles.formContainer}>
        <form className={styles.formCreateClient}>
          <div className={`${styles.formField} `}>
            <div className={` ${styles.labelWrapper} ${styles.specialLabelWrapper}`}>
              <label className={styles.label}>
                {translate('Client name')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={styles.inputField}
              readOnly={!helperState.isEditing}
              name='companyName'
              type='text'
              placeholder={translate('Company name')}
              value={formState.companyName}
              onChange={(e) => handleOnChange(e)}
            />
          </div>

          <div className={styles.formField}>
            <div className={` ${styles.labelWrapper} ${styles.specialLabelWrapper}`}>
              <label className={styles.label}>{translate('Type')}</label>
            </div>
            <input
              name='companyType'
              readOnly={!helperState.isEditing}
              type='text'
              placeholder={translate('Company type place')}
              value={formState.companyType}
              onChange={(e) => handleOnChange(e)}
              className={`${styles.inputField} ${styles.w40}`}
            />
          </div>
          <div className={styles.formField}>
            <div className={` ${styles.labelWrapper} ${styles.specialLabelWrapper}`}>
              <label className={styles.label}>
                {translate('Platform used')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            <input
              className={styles.inputField}
              name='platform'
              readOnly={!helperState.isEditing}
              type='text'
              placeholder={translate('Platform placeholder')}
              value={formState.platform}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className={styles.formField}>
            <div className={` ${styles.labelWrapper} ${styles.specialLabelWrapper}`}>
              <label className={styles.label}>
                {translate('Domain')}
                {helperState.isEditing && <sup className={styles.sup}>*</sup>}
              </label>
            </div>
            {helperState.isEditing ? (
              <input
                name='domain'
                readOnly={!helperState.isEditing}
                type='text'
                placeholder={translate('Domain placeholder')}
                value={formState.domain}
                onChange={(e) => handleOnChange(e)}
                className={`${styles.inputField} `}
              />
            ) : (
              <input
                name='domain'
                readOnly={true}
                type='text'
                placeholder={translate('Domain placeholder')}
                value={formState.domain + mpburl}
                className={`${styles.inputField} ${!helperState.isEditing && styles.domainActive}`}
              />
            )}
            {helperState.isEditing && <span className={styles.mpbDomain}>{translate('mpbUrl')}</span>}
          </div>
          {helperState.isEditing && (
            <div className={styles.formField}>
              <div className={styles.labelWrapper}>
                <label className={styles.label}>
                  {translate('Work time')}
                  {helperState.isEditing && <sup className={styles.sup}>*</sup>}
                </label>
              </div>
              <div className={styles.dropWrapper}>
                <span className={styles.textFromDate}>{translate('from')}</span>
                <Dropdown
                  name='startDate'
                  upward={false}
                  search
                  options={dateOptions}
                  placeholder='Jour'
                  selection
                  item={true}
                  onChange={handleSelect}
                  value={parseInt(timeState.startDate)}
                  className={`${styles.customInput} ${styles.date}`}
                />
                <span className={styles.textToDate}>{translate('to')}</span>
                <Dropdown
                  name='endDate'
                  upward={false}
                  search
                  options={dateOptions}
                  placeholder='Jour'
                  selection
                  value={parseInt(timeState.endDate)}
                  onChange={handleSelect}
                  className={`${styles.customInput} ${styles.date}`}
                />
                <div className={styles.timeInputWrapper}>
                  <span className={styles.textFromToHour}>{translate('from')}</span>

                  <div className={styles.timeInputBlock}>
                    <input
                      className={`${styles.inputField} ${styles.timeInputField}`}
                      readOnly={!helperState.isEditing}
                      name='startHour_hour'
                      type='text'
                      maxLength={2}
                      placeholder='00'
                      value={timeState.startHour_hour}
                      onChange={handleOnChangeTime}
                    />
                    {':'}
                    <input
                      className={`${styles.inputField} ${styles.timeInputField}`}
                      readOnly={!helperState.isEditing}
                      name='startHour_minute'
                      type='text'
                      maxLength={2}
                      placeholder='00'
                      value={timeState.startHour_minute}
                      onChange={handleOnChangeTime}
                    />
                  </div>
                  <span className={styles.textFromToHour}>{translate('to')}</span>
                  <div className={styles.timeInputBlock}>
                    <input
                      className={`${styles.inputField} ${styles.timeInputField}`}
                      readOnly={!helperState.isEditing}
                      name='endHour_hour'
                      type='text'
                      maxLength={2}
                      placeholder='00'
                      value={timeState.endHour_hour}
                      onChange={handleOnChangeTime}
                    />
                    {':'}
                    <input
                      className={`${styles.inputField} ${styles.timeInputField}`}
                      readOnly={!helperState.isEditing}
                      name='endHour_minute'
                      type='text'
                      maxLength={2}
                      placeholder='00'
                      value={timeState.endHour_minute}
                      onChange={handleOnChangeTime}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
        <div className={styles.rightGroup}>
          {helperState.isEditing && (
            <>
              <Button
                className={`${styles.btnSave} ${canActiveSubmitButton() && styles.activeSave}`}
                onClick={handleSubmit}
                disabled={!canActiveSubmitButton()}>
                <span className='buttonText topLevelText'>{translate('Save')}</span>
              </Button>
              <Button
                className={`${styles.btnCancel}`}
                onClick={() => {
                  if (formState.edited) setHelperState({ ...helperState, modal: true });
                  else setHelperState({ ...helperState, isEditing: false });
                }}>
                <span className='buttonText topLevelText'>{translate('Cancel')}</span>
              </Button>
            </>
          )}
          {!helperState.isEditing && (
            <div className={styles.scheduleWrapper}>
              <p className={styles.textCoachingOpening}>Plages ouvertes au coaching</p>
              <div className={styles.dateSquaresContainer}>
                {helperState.editingMode ? (
                  renderWorkingDate(mapDateToContinuosArray([+timeState.startDate, +timeState.endDate]))
                ) : (
                  <>
                    <span className={`${styles.dateSquare}`}>L</span>
                    <span className={`${styles.dateSquare}`}>M</span>
                    <span className={`${styles.dateSquare}`}>M</span>
                    <span className={`${styles.dateSquare}`}>J</span>
                    <span className={`${styles.dateSquare}`}>V</span>
                    <span className={`${styles.dateSquare} ${styles.dateOff}`}>S</span>
                    <span className={`${styles.dateSquare} ${styles.dateOff}`}>D</span>
                  </>
                )}
              </div>
              <p className={styles.textWorkingHour}>
                DE
                <span className={styles.numberHour}>
                  {' '}
                  {`${timeState.startHour_hour}:${timeState.startHour_minute}`}{' '}
                </span>
                À<span className={styles.numberHour}> {`${timeState.endHour_hour}:${timeState.endHour_minute}`}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      {helperState.modal && (
        <AlertModal
          title={translate('Confirm cancel')}
          contentCenter={true}
          contentQuestion={[
            {
              text: translate('Do you want to undo?'),
              style: AlertModalContentQuestionStyle.BASIC,
            },
          ]}
          content={translate('You will be return to previous page with unsaved data')}
          cancel={() => setHelperState({ ...helperState, modal: false })}
          agree={handleAgreeCancelForm}
        />
      )}
    </div>
  );
};

export default React.memo(EditableClientDetailComp);
