import React from 'react';
import { Grid } from 'semantic-ui-react';
import ChartContainer from '../ChartContainer/ChartContainer';
import styles from './dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';
import { DASHBOARD_get_main_dashboard } from '../../services/apis/dashboardApis';
import AwaitingComp from '../Common/AwaitingComp/AwaitingComp';
import { ITotalByMonth } from '../../models/type';
import moment from 'moment';
import { secondary_blue2, secondary_red2 } from '../../theme/chartTheme';

const Dashboard: React.FC = () => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  const [mainDashboardData] = useQuery({
    query: DASHBOARD_get_main_dashboard,
  });
  const generate_bar_chart_data = (rawData: Array<ITotalByMonth>, columnColor: string) => {
    const labels = [];

    const data = [];

    for (let item of rawData) {
      labels.push(translate(moment(item.month, 'MM').format('MMM')));
      data.push(item.total);
    }
    return {
      chartType: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: columnColor,
            borderColor: columnColor,
            borderWidth: 100,
            barPercentage: 0.5,
            categoryPercentage: 1.0,
            hoverBackgroundColor: columnColor,
            hoverBorderColor: columnColor,
            data: data,
          },
        ],
      },
    };
  };

  /*EFFECT*/

  if (mainDashboardData.fetching) return <AwaitingComp fluid={true} />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.statisticalBlock}>
        <Grid stretched divided='vertically'>
          <Grid.Row className={`${styles.p0}`}>
            <Grid.Column className={`${styles.p1}`} mobile={16} tablet={8} computer={8} widescreen={6}>
              <ChartContainer
                chartTitle={translate('Bar chart title total number of session')}
                chartType='bar'
                data={generate_bar_chart_data(
                  mainDashboardData.data?.getStaffMainDashboard?.sessions.by_month.done,
                  secondary_red2,
                )}
              />
            </Grid.Column>
            <Grid.Column className={`${styles.p1}`} mobile={16} tablet={8} computer={8} widescreen={6}>
              <ChartContainer
                chartTitle={translate('Bar chart title total number of session interrupted')}
                chartType='bar'
                data={generate_bar_chart_data(
                  mainDashboardData.data?.getStaffMainDashboard?.sessions.by_month.interrupted,
                  secondary_red2,
                )}
              />
            </Grid.Column>
            <Grid.Column className={`${styles.p1}`} mobile={16} tablet={16} computer={16} widescreen={12}>
              <ChartContainer
                chartTitle={translate('Bar chart title number of clients')}
                chartType='bar'
                data={generate_bar_chart_data(mainDashboardData.data.getStaffMainDashboard.clients, secondary_blue2)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid stretched divided='vertically'>
          <Grid.Row className={`${styles.p0}`}>
            <Grid.Column className={`${styles.p1}`} mobile={16} tablet={8} computer={8} widescreen={6}>
              <div className={`${styles.statisticalItemBlock}`}>
                <p className={styles.statisticalDesc}>{translate('Total courses completed')}</p>
                <p className={styles.highlighNumber}>
                  {mainDashboardData.data?.getStaffMainDashboard?.courses.number_of_done +
                    '/' +
                    mainDashboardData.data?.getStaffMainDashboard?.courses.total}
                </p>
              </div>
            </Grid.Column>
            <Grid.Column className={`${styles.p1}`} mobile={16} tablet={8} computer={8} widescreen={6}>
              <div className={`${styles.statisticalItemBlock}`}>
                <p className={styles.statisticalDesc}>{translate('Sessions done')}</p>
                <p className={styles.highlighNumber}>
                  {mainDashboardData.data?.getStaffMainDashboard?.sessions.progress.number_of_done +
                    '/' +
                    mainDashboardData.data?.getStaffMainDashboard?.sessions.progress.total}
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid stretched divided='vertically'>
          <Grid.Row className={`${styles.p0}`}>
            <Grid.Column className={`${styles.p1}`} mobile={16} tablet={8} computer={8} widescreen={6}>
              <div className={`${styles.statisticalItemBlock}`}>
                <p className={styles.statisticalDesc}>{translate('Total coachs')}</p>
                <p className={styles.highlighNumber}>
                  {mainDashboardData.data?.getStaffMainDashboard?.number_of_coaches}
                </p>
              </div>
            </Grid.Column>
            <Grid.Column className={`${styles.p1}`} mobile={16} tablet={8} computer={8} widescreen={6}>
              <div className={`${styles.statisticalItemBlock}`}>
                <p className={styles.statisticalDesc}>{translate('Total coachees')}</p>
                <p className={styles.highlighNumber}>
                  {' '}
                  {mainDashboardData.data?.getStaffMainDashboard?.number_of_coachees}
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
