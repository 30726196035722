import { ReactNode } from 'react';
import { CombinedError, Operation, Provider, createClient, cacheExchange } from 'urql';
import { AuthConfig, AuthUtilities, authExchange } from '@urql/exchange-auth';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';

import useLogOut from '../hooks/useLogOut';
import { token } from '../config/tokenConstant';
import { STATUS_CODE } from '../models/enum';
import { Token } from '../models/type';
import { getInfoTokenFromLocalStorage, getMilisecondExpiresDate } from '../utilities/authenticate';
import { API_userRefreshToken } from '../services/apis/userApi';

const endpoint = process.env.REACT_APP_BACKEND;

type UrqlProviderProps = {
  children: ReactNode;
};

const UrqlProvider = ({ children }: UrqlProviderProps) => {
  const logOut = useLogOut();

  const authRefresh = async (utils: AuthUtilities): Promise<AuthConfig> => {
    return {
      addAuthToOperation(operation: Operation) {
        return operation;
      },
      didAuthError(error: CombinedError, _operation: Operation) {
        return error.graphQLErrors.some((e) => e.extensions?.error_code === STATUS_CODE.UNAUTHENTICATED);
      },
      willAuthError() {
        const milisecondTimeToExpired: number = 600000;
        const tempToken: Token = getInfoTokenFromLocalStorage();

        if (tempToken && tempToken?.expires_date && tempToken?.expires_date - Date.now() < milisecondTimeToExpired) {
          return true;
        }

        return false;
      },
      async refreshAuth() {
        const res = await utils.mutate(API_userRefreshToken, {});
        if (res.data && res.data.refreshToken) {
          const infoToken: Token = {
            token: res.data.refreshToken.access_token,
            expires_date: getMilisecondExpiresDate(res.data.refreshToken.expires_in),
            expires_in: res.data.refreshToken.expires_in,
          };
          localStorage.setItem(token, JSON.stringify(infoToken));
        } else {
          logOut();
        }
      },
    };
  };

  const client = createClient({
    url: endpoint,
    exchanges: [cacheExchange, authExchange(authRefresh), multipartFetchExchange],
    fetchOptions: () => {
      const tempToken = JSON.parse(localStorage.getItem(token))?.token;
      return {
        headers: { Authorization: tempToken ? `Bearer ${tempToken}` : '' },
        mode: 'cors',
        credentials: 'same-origin',
      };
    },
  });

  return <Provider value={client}>{children}</Provider>;
};

export default UrqlProvider;
