import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import EditableMediaResource from '../../components/EditableMediaResource/EditableMediaResource';
import { Helper_Actions, MaterialIconName } from '../../models/enum';
import { MEDIARESOURCES_fetch_detail } from '../../services/apis/mediaResourcesApis';
import styles from './mediaResourcesDetailPage.module.scss';

const MediaResourcesDetailPage: React.FC = () => {
  /*PROPS*/

  const [mediaResourceDetail] = useQuery({
    query: MEDIARESOURCES_fetch_detail,
    variables: { id: window.location.search.slice(1) },
    requestPolicy: 'cache-and-network',
  });
  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  /*EFFECT*/

  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.info,
        title: translate('Media resource detail'),
      },
    });
  }, [dispatch, translate]);

  if (mediaResourceDetail.fetching) return <AwaitingComp fluid={true} />;

  return (
    <div className={styles.wrapper}>
      <Grid>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
            <EditableMediaResource
              editMode={true}
              mediaItem={mediaResourceDetail.data.getMediaResource.media_resource}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default MediaResourcesDetailPage;
