import { combineReducers, createStore } from 'redux';
import { clientReducer } from './reducers/clientReducer';
import { helperReducer } from './reducers/helperReducer';
import { programReducer } from './reducers/programReducer';
import { coachReducer } from './reducers/coachReducer';
import { mediaResourcesReducer } from './reducers/mediaResourcesReducer';

const rootReducer = combineReducers({
  clientReducer,
  helperReducer,
  programReducer,
  coachReducer,
  mediaResourcesReducer,
});

export const store = createStore(rootReducer);
