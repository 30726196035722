/*QUERY*/

export const PROGRAMMANAGER_getPMListFromProgram = `
query ($program_id : ID!, $count : Int!, $page : Int!, $search_name : String, $order_by : String, $direction : String){
    listProgramManagersFromProgram(
        program_id: $program_id
        count: $count
        page: $page
        search_name: $search_name
        order_by: $order_by,
        direction: $direction,
    ) {
        status_code
        program_managers {
            id
            programs {
                id
                name
            }
            user_infos {
                id
                email
                first_name
                last_name
                department
                position
                gdpr_consent
            }
        }
        paginatorInfo {
            currentPage
            lastPage
        }
    }
}
`;

export const PROGRAMMANAGER_getPMListFromClient = `
query ($client_id : ID!, $count : Int!, $page : Int!, $search_name : String, $order_by : String!, $direction : String! ) {
    listProgramManagersFromClient(
        client_id: $client_id
        count: $count
        page: $page
        search_name: $search_name
        order_by: $order_by
        direction: $direction
    ) {
        status_code
        program_managers {
            id
            programs {
                id
                name
            }
            user_infos {
                id
                email
                first_name
                last_name
                department
                position
                gdpr_consent
            }
        }
        paginatorInfo {
            currentPage
            lastPage
        }
    }
}
`;

/*MUTATION*/

export const PROGRAMMANAGER_upload = `
mutation ($program_id : ID!, $file: Upload!)  {
    importProgramManagers(program_id : $program_id, file : $file) {
        status_code
        total
        number_of_imported
        number_of_duplicates
        number_of_invalids
    }
}
`;

export const PROGRAMMANAGER_removeFromProgram = `
mutation ($id : ID!, $program_id : ID!) {
    removeProgramManagerFromProgram (id: $id, program_id: $program_id) {
        status_code
    }
}
`;

export const PROGRAMMANAGER_removeFromClient = `
mutation ($id : ID!, $client_id : ID!) {
    removeProgramManagerFromClient(
        id: $id,
        client_id: $client_id
    ) {
        status_code
    }
}
`;
