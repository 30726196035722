import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { IProgramManagerExtended } from '../../models/type';
import CardProgramManager from '../CardProgramManager/CardProgramManager';
import CreateDirector from '../CreateDirector/CreateDirector';
import PMHeaderBlock from '../PMHeaderBlock/PMHeaderBlock';
import styles from './clientDetailProjectDirector.module.scss';
import { useDispatch } from 'react-redux';
import {
  PROGRAMMANAGER_getPMListFromClient,
  PROGRAMMANAGER_removeFromClient,
} from '../../services/apis/programManagerApi';
import { useMutation, useQuery } from 'urql';
import { AlertModalContentQuestionStyle, Client_Action, STATUS_CODE } from '../../models/enum';
import AwaitingComp from '../Common/AwaitingComp/AwaitingComp';
import AlertModal from '../Common/AlertModal/AlertModal';

const ClientDetailProjectDirector: React.FC = () => {
  /*PROPS*/

  const [programManagerQueryVariables, setProgramMangerQueryVariables] = useState({
    client_id: window.location.pathname.split('/').pop(),
    count: 100,
    page: 1,
    search_name: '',
    order_by: 'createdAt',
    direction: 'asc',
  });

  let [programManagerListResult, queryPrograManagers] = useQuery({
    query: PROGRAMMANAGER_getPMListFromClient,
    variables: programManagerQueryVariables,
    requestPolicy: 'cache-and-network',
  });

  const clientStore = useSelector((state: any) => state.clientReducer);

  const [helperState, setHelperState] = useState({
    isShowedDeleteModal: false,
    isShowedNoSearchResult: false,
    deleteID: null,
    modalContentQuestion: null,
    modalContent: null,
  });

  const [, executeRemoveProgramManager] = useMutation(PROGRAMMANAGER_removeFromClient);

  /*METHODS*/
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const handleAgreeToRemoveProgramManager = useCallback(
    (programManagerID: string) => {
      executeRemoveProgramManager({
        id: programManagerID,
        client_id: window.location.pathname.split('/').pop(),
      })
        .then((res) => {
          setHelperState({
            ...helperState,
            isShowedDeleteModal: false,
            deleteID: null,
          });
          if (res.data.removeProgramManagerFromClient.status_code === STATUS_CODE.NOCONTENT) {
            queryPrograManagers();
          }
        })
        .catch((err) => console.error(err));
    },
    [executeRemoveProgramManager, helperState, queryPrograManagers],
  );

  const handleSearch = useCallback(
    (search_name: string) => {
      setProgramMangerQueryVariables({
        ...programManagerQueryVariables,
        search_name,
      });
    },
    [programManagerQueryVariables],
  );

  /*EFFECTS*/

  useEffect(() => {
    if (!clientStore.programManager.length || programManagerQueryVariables.search_name) {
      queryPrograManagers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programManagerQueryVariables.search_name]);

  useEffect(() => {
    if (programManagerListResult.data?.listProgramManagersFromClient.program_managers.length) {
      dispatch({
        type: Client_Action.set_program_managers,
        payload: programManagerListResult.data.listProgramManagersFromClient.program_managers,
      });
    }
    if (
      clientStore.hasProgramManager &&
      !programManagerListResult.data?.listProgramManagersFromClient.program_managers.length
    ) {
      dispatch({
        type: Client_Action.set_program_managers,
        payload: programManagerListResult.data.listProgramManagersFromClient.program_managers,
      });
      setHelperState({ ...helperState, isShowedNoSearchResult: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programManagerListResult.data]);

  const renderProgramManagers = useCallback(
    (PMArray: Array<IProgramManagerExtended>) => {
      return PMArray.map((item: IProgramManagerExtended, index: number) => {
        return (
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12} key={index}>
            <CardProgramManager
              programManager={item}
              key={index}
              showDeleteCross={true}
              removeProgramManger={() => {
                setHelperState({
                  ...helperState,
                  isShowedDeleteModal: true,
                  deleteID: item.id,
                  modalContentQuestion: [
                    {
                      text: 'Voulez-vous vraiment détacher ',
                      style: AlertModalContentQuestionStyle.BASIC,
                    },
                    {
                      text: item.user_infos.first_name + ' ' + item.user_infos.last_name,
                      style: AlertModalContentQuestionStyle.PRIMARY,
                    },
                    {
                      text: ' du programme ',
                      style: AlertModalContentQuestionStyle.BASIC,
                    },
                    {
                      text: 'Gérer un lieu de travail diversifié en temps de fusion',
                      style: AlertModalContentQuestionStyle.PRIMARY,
                    },
                    {
                      text: ' ?',
                      style: AlertModalContentQuestionStyle.BASIC,
                    },
                  ],
                  modalContent: 'Ce gestionnaire de programme est toujours disponible dans la base de données.',
                });
              }}
            />
          </Grid.Column>
        );
      });
    },
    [helperState],
  );

  return (
    <div className={styles.wrapper}>
      {clientStore.clientName && (
        <p className={styles.clientName}>{clientStore.clientName || clientStore.client.name}</p>
      )}

      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
              <CreateDirector />
            </Grid.Column>
          </Grid.Row>

          {!clientStore.hasProgramManager ? (
            <Grid.Row>
              <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
                <div className={styles.wrapperBottomBlock}>
                  <p className={styles.formTitle}>{translate('Program manager')}</p>
                </div>
                <p className={styles.noManagerText}>{translate('Have not created any program manager')}</p>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row>
                <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
                  <PMHeaderBlock
                    showSearch={true}
                    showDropdown={false}
                    showReload={false}
                    totalProgramManagers={!clientStore.programManager.length ? '' : clientStore.programManager.length}
                    handleSearch={(search_value: string) => handleSearch(search_value)}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {renderProgramManagers(clientStore.programManager)}
                {helperState.isShowedNoSearchResult && (
                  <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
                    <p className={styles.noManagerText}>{translate('No result')}</p>
                  </Grid.Column>
                )}
              </Grid.Row>
            </>
          )}
        </Grid>
      </div>
      {programManagerListResult.fetching && <AwaitingComp fluid={true} />}
      {helperState.isShowedDeleteModal && (
        <AlertModal
          title='CONFIRMER LA SUPPRESSION'
          contentQuestion={helperState.modalContentQuestion}
          content={helperState.modalContent}
          cancel={() => setHelperState({ ...helperState, isShowedDeleteModal: false })}
          agree={() => handleAgreeToRemoveProgramManager(helperState.deleteID)}
        />
      )}
    </div>
  );
};

export default ClientDetailProjectDirector;
