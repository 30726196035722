import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Icon, Pagination } from 'semantic-ui-react';
import { useQuery } from 'urql';
import CoachRow from '../../components/CoachRow/CoachRow';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import SearchInput from '../../components/SearchInput/SearchInput';
import { Helper_Actions, ListCoachesSortableColumn, MaterialIconName, SortDirection } from '../../models/enum';
import { ICoachListItemExtended } from '../../models/type';
import { COACH_searchCoach } from '../../services/apis/coachApi';
import styles from './coachListPage.module.scss';

const CoachListPage: React.FC = () => {
  /*PROPS*/

  const [queryVariables, setQueryVariables] = useState({
    count: 20,
    page: 1,
    search_name: '',
    order_by: ListCoachesSortableColumn.active,
    direction: SortDirection.DESC,
  });

  const [listCoach] = useQuery({
    query: COACH_searchCoach,
    variables: queryVariables,
    requestPolicy: 'cache-and-network',
  });

  /*CONST*/
  const nameSortedASC =
    queryVariables.order_by === ListCoachesSortableColumn.name && queryVariables.direction === SortDirection.ASC;

  const activeStatusSortedASC =
    queryVariables.order_by === ListCoachesSortableColumn.active && queryVariables.direction === SortDirection.ASC;

  const certificationSortedASC =
    queryVariables.order_by === ListCoachesSortableColumn.certifications &&
    queryVariables.direction === SortDirection.ASC;

  const programNumberSortedASC =
    queryVariables.order_by === ListCoachesSortableColumn.coach_teams && queryVariables.direction === SortDirection.ASC;

  /*METHODS*/

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const handleSearch = (searchInput: string) => {
    setQueryVariables({ ...queryVariables, page: 1, search_name: searchInput });
  };

  const handleSort = (direction: SortDirection, order_by: ListCoachesSortableColumn) => {
    setQueryVariables({ ...queryVariables, direction, order_by });
  };

  const handleRenderCoachRow = (coachsArray: Array<ICoachListItemExtended>) => {
    return coachsArray.map((item: ICoachListItemExtended, index: number) => (
      <CoachRow coachItem={item} index={index} key={item.id} />
    ));
  };

  const changePage = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
    setQueryVariables({ ...queryVariables, page: data.activePage });
  };

  /*EFFECT*/

  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.group,
        title: translate('Coachs list'),
      },
    });
  }, [dispatch, translate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <p className={styles.titleDesc}>{translate('Click to view coach detail')}</p>
      </div>

      <div className={styles.searchInputAndPaginationWrapper}>
        <SearchInput
          placeholder={translate('Search program')}
          handleSearch={(searchInput) => handleSearch(searchInput)}
        />

        <div className={styles.pagination} id='paginationId'>
          <Pagination
            defaultActivePage={1}
            ellipsisItem={{
              content: <Icon name='ellipsis horizontal' />,
              icon: true,
            }}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
            totalPages={
              listCoach.data?.listCoaches?.paginatorInfo.lastPage
                ? listCoach.data.listCoaches.paginatorInfo.lastPage
                : 1
            }
            onPageChange={(event, data) => changePage(event, data)}
          />
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.coachTable}>
          <thead className={styles.fixedHeader}>
            <tr className={styles.headerTR}>
              <th className={`${styles.headerTH} ${styles.no_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>No</span>
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.name_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Name')}</span>
                  {nameSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ListCoachesSortableColumn.name)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ListCoachesSortableColumn.name)}
                    />
                  )}
                </div>
              </th>

              <th className={`${styles.headerTH} ${styles.accountStatus_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Account status')}</span>
                  {activeStatusSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ListCoachesSortableColumn.active)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ListCoachesSortableColumn.active)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.certification_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Certificate', { count: 2 })}</span>
                  {certificationSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ListCoachesSortableColumn.certifications)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ListCoachesSortableColumn.certifications)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.programNumber_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Number of pragrams')}</span>
                  {programNumberSortedASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ListCoachesSortableColumn.coach_teams)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ListCoachesSortableColumn.coach_teams)}
                    />
                  )}
                </div>
              </th>
            </tr>
          </thead>
          {listCoach.fetching && <AwaitingComp fluid={false} />}
          {listCoach.data?.listCoaches && <tbody>{handleRenderCoachRow(listCoach.data.listCoaches.coaches)}</tbody>}
        </table>
      </div>
    </div>
  );
};

export default CoachListPage;
