import { IOfferConfiguration } from '../models/type';

export const generate_staked_bar_data = (data: {
  number_of_unassigned: number;
  number_of_started: number;
  number_of_done: number;
  total: number;
}) => {
  return {
    chartType: 'horizontal_stacked_bar',
    data: data,
  };
};

/**
 * Find and return the data form for generate_staked_bar_data
 * @param offersArray
 * @param type
 */
export const filerCourseType = (offersArray: Array<IOfferConfiguration>, type: string) => {
  if (!offersArray.length) {
    return {
      number_of_unassigned: 0,
      number_of_started: 0,
      number_of_done: 0,
      total: 0,
    };
  }

  const course: IOfferConfiguration = offersArray.find((item: IOfferConfiguration) => {
    return item.name === type;
  });
  return {
    number_of_unassigned: course.number_of_unassigned,
    number_of_started: course.number_of_started,
    number_of_done: course.number_of_closed,
    total: course.number_of_courses,
  };
};
