import React from 'react';
import Login from '../../components/Login/Login';
import LoginLayout from '../../layouts/LoginLayout/LoginLayout';

const LoginPage: React.FC = () => {
  return (
    <>
      <LoginLayout logoPosition='middle' background='photo' formPostion='left'>
        <Login />
      </LoginLayout>
    </>
  );
};

export default LoginPage;
