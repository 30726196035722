// import { SortDirection, ClientOrderableColumn } from "../../models/enum";
export const CLIENT_getClients = `
query ($page : Int!, $search_name : String, $order_by : ClientOrderableColumn, $direction : SortDirection) {
    listClients(page: $page, search_name: $search_name, order_by: $order_by, direction: $direction) {
       status_code
       clients {
           id
           name
           tool
           days
           open_at
           close_at
           company_type
           subdomain
           programs {
               id
               name
           }
           project_director {
            id
            email
            first_name
            last_name
            department
            position
            client_id
        }
       }
       paginatorInfo {
           currentPage
           lastPage
       }
    }
}
`;

export const CLIENT_get_project_director = `
query ($id : ID!) {
    detailClient(id: $id) {
        status_code
        client {
            name
            project_director {
                id
                email
                first_name
                last_name
                department
                position
                client_id
            }
           
        }
      
    }
}
`;

export const CLIENT_creation = `
mutation($name : String!, $days : [Int!]!, $open_at : String!, $close_at : String!, $subdomain : String!, $tool : String!, $company_type : String) {
    createClient(name: $name, days: $days, open_at: $open_at, close_at: $close_at, subdomain: $subdomain, tool : $tool, company_type : $company_type ) {
        status_code
        client {
            id
            name
            tool
            days
            open_at
            close_at
            subdomain
            company_type
        }
    }
}
`;

export const CLIENT_getClient = `
query($id : ID!){
    detailClient(id: $id) {
        status_code
        client {
            id
            name
            tool
            days
            open_at
            close_at
            subdomain
            company_type
            programs {
                id
                name
                number_of_coachees
                offerConfigurations {
                    id
                    name
                    number_of_courses
                    number_of_program_managers
                    number_of_coaches
                    number_of_coachees
                }
            }
        }
      
    }
}`;

export const CLIENT_update = `
mutation ($id : ID!, $name : String!, $days : [Int!]!, $open_at : String!, $close_at : String!, $subdomain : String!, $tool : String!, $company_type : String) {
    updateClient(
        id: $id, 
        name: $name, 
        days: $days, 
        tool: $tool, 
        open_at: $open_at, 
        close_at: $close_at, 
        subdomain: $subdomain
        company_type : $company_type
    ) {
        status_code
        client {
            id
            name
            tool
            days
            open_at
            close_at
            subdomain
            company_type
        }
    }
}
`;
