import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Icon, Pagination } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { useLocation } from 'react-router-dom';

import { TempProgramInterface } from '../../models/type';
import { PROGRAM_getPrograms } from '../../services/apis/programApi';
import { Helper_Actions, MaterialIconName, ProgramOrderableColumn, SortDirection } from '../../models/enum';

import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import ProgramRow from '../../components/ProgramRow/ProgramRow';
import SearchInput from '../../components/SearchInput/SearchInput';
import styles from './programsListPage.module.scss';

const ProgramsListPage: React.FC = (props: any) => {
  const [helperState, setHelperState] = useState({
    showAlert: false,
  });
  const [queryVariables, setQueryVariables] = useState({
    page: 1,
    search_name: '',
    order_by: 'NUMBER_OF_PROGRAM_MANAGERS',
    direction: SortDirection.DESC,
    client_id: '',
  });

  const [{ data, fetching }] = useQuery({
    query: PROGRAM_getPrograms,
    variables: queryVariables,
    requestPolicy: 'network-only',
  });

  const location = useLocation();

  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const changePage = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
    setQueryVariables({ ...queryVariables, page: data.activePage });
  };

  const handleSearch = (searchInput: string) => {
    setQueryVariables({ ...queryVariables, page: 1, search_name: searchInput });
  };

  const handleSort = (direction: SortDirection, order_by: ProgramOrderableColumn) => {
    setQueryVariables({ ...queryVariables, direction, order_by });
  };

  /*EFFECT*/

  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.school,
        title: translate('Programs list'),
      },
    });
    if (location.state?.client_needed_fetch_program) {
      setQueryVariables({
        ...queryVariables,
        search_name: '',
        client_id: location.state?.client_needed_fetch_program.id,
      });
    }
  }, [dispatch, location.state?.client_needed_fetch_program, queryVariables, translate]);

  useEffect(() => {
    if (data?.listPrograms && !data.listPrograms.programs.length && queryVariables.search_name !== '') {
      setHelperState({ ...helperState, showAlert: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderProgramRow = (programArray: []) => {
    if (!programArray.length) {
      return (
        <tr>
          <td colSpan={6}>{translate('No result')}</td>{' '}
        </tr>
      );
    }

    return programArray.map((item: TempProgramInterface, index: number) => {
      return <ProgramRow program={item} key={index} />;
    });
  };

  /*MAIN RETURN*/

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <p className={styles.titleDesc}>{translate('Click to see program detail')}</p>
      </div>
      <div className={styles.searchInputAndPaginationWrapper}>
        <SearchInput
          placeholder={translate('Search program')}
          handleSearch={(searchInput) => handleSearch(searchInput)}
          client_needed_fetch_program={location.state?.client_needed_fetch_program}
        />
        {data && (
          <div className={styles.pagination} id='paginationId'>
            <Pagination
              defaultActivePage={1}
              ellipsisItem={{
                content: <Icon name='ellipsis horizontal' />,
                icon: true,
              }}
              firstItem={null}
              lastItem={null}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              totalPages={data.listPrograms ? data.listPrograms.paginatorInfo.lastPage : 1}
              onPageChange={(event, data) => changePage(event, data)}
            />
          </div>
        )}
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.programTable}>
          <thead className={styles.fixedHeader}>
            <tr className={styles.headerTR}>
              <th className={`${styles.headerTH} ${styles.programName_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Program name')}</span>
                  {queryVariables.order_by === ProgramOrderableColumn.PROGRAM_NAME &&
                  queryVariables.direction === SortDirection.ASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ProgramOrderableColumn.PROGRAM_NAME)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ProgramOrderableColumn.PROGRAM_NAME)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.client_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Client', { count: 2 })}</span>
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.courseOverTotal_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span style={{ width: 'min-content' }}>Parcours démarrés / Total</span>
                  {queryVariables.order_by === ProgramOrderableColumn.NUMBER_OF_COURSES &&
                  queryVariables.direction === SortDirection.ASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ProgramOrderableColumn.NUMBER_OF_COURSES)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ProgramOrderableColumn.NUMBER_OF_COURSES)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.programManager_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Manager', { count: 2 })}</span>
                  {queryVariables.order_by === ProgramOrderableColumn.NUMBER_OF_PROGRAM_MANAGERS &&
                  queryVariables.direction === SortDirection.ASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ProgramOrderableColumn.NUMBER_OF_PROGRAM_MANAGERS)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ProgramOrderableColumn.NUMBER_OF_PROGRAM_MANAGERS)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.coach_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Coach', { count: 2 })}</span>
                  {queryVariables.order_by === ProgramOrderableColumn.NUMBER_OF_COACHES &&
                  queryVariables.direction === SortDirection.ASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ProgramOrderableColumn.NUMBER_OF_COACHES)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ProgramOrderableColumn.NUMBER_OF_COACHES)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.coachee_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Coachee', { count: 2 })}</span>
                  {queryVariables.order_by === ProgramOrderableColumn.NUMBER_OF_COACHEES &&
                  queryVariables.direction === SortDirection.ASC ? (
                    <Icon
                      name='angle up'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.DESC, ProgramOrderableColumn.NUMBER_OF_COACHEES)}
                    />
                  ) : (
                    <Icon
                      name='angle down'
                      className={styles.sortIcon}
                      onClick={() => handleSort(SortDirection.ASC, ProgramOrderableColumn.NUMBER_OF_COACHEES)}
                    />
                  )}
                </div>
              </th>
              <th className={`${styles.headerTH} ${styles.closeDate_TH}`}>
                <div className={styles.headerTitleBlock}>
                  <span>{translate('Finish date')}</span>
                </div>
              </th>
            </tr>
          </thead>
          {fetching && <AwaitingComp fluid={false} />}
          <tbody>{data?.listPrograms?.programs && renderProgramRow(data.listPrograms.programs)}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ProgramsListPage;
