import React from 'react';
import { Radio } from 'semantic-ui-react';
import { ICourse } from '../../models/type';
import styles from './courseListRow.module.scss';
import moment from 'moment';
import { renderPercentage } from '../../utilities/progression/progressionUtils';

interface Props {
  course: ICourse;
  index: number;
  onSelect: (course: ICourse) => void;
  isChecked: boolean;
  progressionPoint: number;
}

const CourseListRow: React.FC<Props> = (props: Props) => {
  return (
    <tr
      className={`tableListRow ${props.isChecked && !!props.course.coach && styles.selectedActiveRow}`}
      key={props.course.id}>
      <td className={`${styles.tbodyTD} ${styles.choiceTD} secondLevelText`}>
        <Radio
          label=''
          name='listCourseRadioGroup'
          value='this'
          checked={props.isChecked}
          onChange={() => props.onSelect(props.course)}
        />
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>{props.course.offer_configuration.name}</td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.course.coachee ? props.course.coachee.first_name + ' ' + props.course.coachee.last_name : '-'}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.course.started_at ? moment(props.course.started_at).format('DD/MM/YYYY') : '-'}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.course.number_of_sessions_done}/{props.course.offer_configuration.number_of_sessions}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>{renderPercentage(props.progressionPoint)}</td>
      <td className={`${styles.tbodyTD} secondLevelText`}>
        {props.course.coach ? props.course.coach.first_name + ' ' + props.course.coach.last_name : '-'}
      </td>
      <td className={`${styles.tbodyTD} secondLevelText`}>{props.course.evaluation ? props.course.evaluation : '-'}</td>
    </tr>
  );
};

export default React.memo(CourseListRow);
