import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MaterialIconName } from '../../models/enum';
import { token_name } from '../../config/tokenConstant';
import { navLinkClasses } from '../../utilities/helper';

import UserMenuModal from '../../components/UserMenuModal/UserMenuModal';
import useComponentVisble from '../../components/Common/useComponentVisible/ComponentVisible';
import IconAndTitleDashboardLayout from '../../components/IconAndTitleDashboardLayout/IconAndTitleDashboardLayout';
import styles from './dashboardlayout.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo_svg.svg';

const DashboardLayout: React.FC = (props: any) => {
  const { t: translate } = useTranslation();

  const [user, setUser] = useState({
    name: null,
    role: translate('Admin'),
  });

  const [helperState, setHelperState] = useState({
    showToggleMenu: false,
  });

  const { ref, isComponentShowed, setComponentShowed } = useComponentVisble(false);

  const location = useLocation();
  const isCurrentPathActive: boolean = location.pathname.includes('createclient');

  /*METHODS*/

  const handleToggleMenu = () => {
    setHelperState({
      ...helperState,
      showToggleMenu: !helperState.showToggleMenu,
    });
  };

  useEffect(() => {
    const fullName = JSON.parse(localStorage.getItem(token_name));
    setUser({ ...user, name: fullName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      {user.name && (
        <Grid className={styles.m0}>
          <Grid.Row className={`${styles.topRowWrapper} ${styles.p0}`} divided={false}>
            <Grid.Column
              only={'tablet computer'}
              tablet={4}
              computer={3}
              widescreen={2}
              className={`${styles.p0} ${styles.logoColumn}`}>
              <Logo className={styles.logo} />
            </Grid.Column>
            <Grid.Column
              mobile={10}
              tablet={8}
              computer={10}
              widescreen={12}
              className={`${styles.p0} ${styles.topMidColumn}`}>
              <IconAndTitleDashboardLayout />
            </Grid.Column>
            <Grid.Column
              mobile={3}
              tablet={2}
              computer={3}
              widescreen={2}
              className={`${styles.p0} ${styles.userRightColumn}`}
              onClick={() => setComponentShowed(true)}>
              <div className={`${styles.userInfo}`}>
                <p className={`${styles.m0} ${styles.userName}`}>{user.name}</p>
                <p className={styles.userTitle}>{user.role}</p>
              </div>
              <div className={styles.userMenuCollapseBlock}>
                <Icon name='ellipsis vertical' className={styles.icon} />
                <div ref={ref}>{isComponentShowed && <UserMenuModal />}</div>
              </div>
            </Grid.Column>
            <Grid.Column only={'mobile tablet'} mobile={3} tablet={2} className={styles.menuColumn}>
              {/*onClick={toggleMenu}*/}
              <Button className={styles.menuButton} onClick={handleToggleMenu}>
                {helperState.showToggleMenu ? (
                  <i className='close icon menuButtonIcon'></i>
                ) : (
                  <i className='bars icon menuButtonIcon'></i>
                )}
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={`${styles.p0} ${styles.botRowWrapper} ${styles.noBg}`}>
            <Grid.Column
              tablet={12}
              computer={3}
              widescreen={2}
              className={`${styles.p0} ${styles.menu} ${helperState.showToggleMenu && styles.open}`}>
              <Logo className={styles.logo} />
              <div className={styles.sideNav}>
                <div className={styles.tableauNavLinkWrapper}>
                  <NavLink
                    to={`dashboard`}
                    className={(navData) =>
                      navData.isActive
                        ? `${styles.tableauNavItem} ${styles.active} topLevelText`
                        : `${styles.tableauNavItem} topLevelText`
                    }
                    onClick={() => {
                      handleToggleMenu();
                    }}>
                    <p className={styles.textTableau}>{translate('Dashboard')}</p>
                  </NavLink>
                </div>

                <div className={styles.navClient}>
                  <p className={`${styles.categoryTitle} topLevelText`}>{translate('Client')}</p>
                  <div>
                    <div className={styles.navLinkWrapper}>
                      <NavLink
                        to={{
                          pathname: `/createclient/info`,
                        }}
                        className={`${styles.itemBlock} ${isCurrentPathActive ? styles.active : ''}`}
                        onClick={() => {
                          handleToggleMenu();
                        }}>
                        <span className={`material-icons-outlined`}>{MaterialIconName.add}</span>
                        <p className={styles.categoryText}>{translate('Create client')}</p>
                      </NavLink>
                    </div>

                    <div className={styles.navLinkWrapper}>
                      <NavLink
                        to={{ pathname: `/clientlist` }}
                        className={(navData) => navLinkClasses(navData, styles.itemBlock, styles.active)}
                        onClick={() => {
                          handleToggleMenu();
                        }}>
                        <span className='material-icons-outlined'>{MaterialIconName.assignment}</span>
                        <p className={styles.categoryText}>{translate('Clients list')}</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className={styles.navProgram}>
                  <p className={`${styles.categoryTitle} topLevelText`}>{translate('Program')}</p>
                  <div>
                    <div className={styles.navLinkWrapper}>
                      <NavLink
                        to={{ pathname: `/createprogram` }}
                        className={(navData) => navLinkClasses(navData, styles.itemBlock, styles.active)}
                        onClick={() => {
                          handleToggleMenu();
                        }}>
                        <span className={`material-icons-outlined`}>{MaterialIconName.add}</span>
                        <p className={styles.categoryText}>{translate('Create program')}</p>
                      </NavLink>
                    </div>

                    <div className={styles.navLinkWrapper}>
                      <NavLink
                        to={{ pathname: `/programslist` }}
                        className={(navData) => navLinkClasses(navData, styles.itemBlock, styles.active)}
                        onClick={() => {
                          handleToggleMenu();
                        }}>
                        <span className='material-icons-outlined'>{MaterialIconName.school}</span>
                        <p className={styles.categoryText}>{translate('Programs list')}</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className={styles.navCoach}>
                  <p className={`${styles.categoryTitle} topLevelText`}>{translate('Coach')}</p>
                  <div>
                    <div className={styles.navLinkWrapper}>
                      <NavLink
                        to={{ pathname: `/createcoach` }}
                        className={(navData) => navLinkClasses(navData, styles.itemBlock, styles.active)}
                        onClick={() => {
                          handleToggleMenu();
                        }}>
                        <span className='material-icons-outlined'>{MaterialIconName.person_add}</span>
                        <p className={styles.categoryText}>{translate('Create coach')}</p>
                      </NavLink>
                    </div>

                    <div className={styles.navLinkWrapper}>
                      <NavLink
                        to={{ pathname: `/coachsList` }}
                        className={(navData) => navLinkClasses(navData, styles.itemBlock, styles.active)}
                        onClick={() => {
                          handleToggleMenu();
                        }}>
                        <span className='material-icons-outlined'>{MaterialIconName.group}</span>
                        <p className={styles.categoryText}>{translate('Coachs list')}</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className={styles.navResource}>
                  <p className={`${styles.categoryTitle} topLevelText`}>{translate('Media resources')}</p>
                  <div>
                    <div className={styles.navLinkWrapper}>
                      <NavLink
                        to={{ pathname: `/resourcelist` }}
                        className={(navData) => navLinkClasses(navData, styles.itemBlock, styles.active)}
                        onClick={() => {
                          handleToggleMenu();
                        }}>
                        <span className='material-icons-outlined'>{MaterialIconName.perm_media}</span>
                        <p className={styles.categoryText}>{translate('Media list')}</p>
                      </NavLink>
                    </div>

                    <div className={styles.navLinkWrapper}>
                      <NavLink
                        to={{ pathname: `/addresource` }}
                        className={(navData) => navLinkClasses(navData, styles.itemBlock, styles.active)}
                        onClick={() => {
                          handleToggleMenu();
                        }}>
                        <span className='material-icons-outlined'>{MaterialIconName.add}</span>
                        <p className={styles.categoryText}>{translate('Add resource')}</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column
              tablet={16}
              computer={13}
              widescreen={14}
              className={`${styles.contentContainer} ${styles.p0}`}>
              <Outlet />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default DashboardLayout;
