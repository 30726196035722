import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import { ReactComponent as ZzzIconSvg } from '../../assets/images/zzz_25.svg';

const ZzzIcon = () => {
  const { t: translate } = useTranslation();

  return <Popup content={translate('Inactive user')} trigger={<ZzzIconSvg />} />;
};

export default ZzzIcon;
