import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import EditableCoachDetailComp from '../../components/EditableCoachDetailComp/EditableCoachDetailComp';
import { Helper_Actions, MaterialIconName, STATUS_CODE } from '../../models/enum';
import { COACH_detail_admin } from '../../services/apis/coachApi';
import styles from './coachDetailPage.module.scss';

const CoachDetailPage: React.FC = () => {
  /*PROPS*/
  const [coachDetail] = useQuery({
    query: COACH_detail_admin,
    variables: { coach_id: window.location.search.slice(1) },
  });
  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.info,
        title: translate('Coach detail'),
      },
    });
  }, [dispatch, translate]);

  return (
    <div className={styles.wrapper}>
      <Grid>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
            {coachDetail.data?.detailAdminCoach.status_code === STATUS_CODE.SUCCESS && (
              <EditableCoachDetailComp editMode={true} coachDetail={coachDetail.data.detailAdminCoach.coach} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CoachDetailPage;
