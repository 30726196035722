import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Pagination } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQuery } from 'urql';

import { IClient } from '../../models/type';
import { SortDirection, ClientOrderableColumn, Helper_Actions, MaterialIconName } from '../../models/enum';
import { CLIENT_getClients } from '../../services/apis/clientsApi';

import EditableClientDetailComp from '../../components/EditableClientDetailComp/EditableClientDetailComp';
import SearchInput from '../../components/SearchInput/SearchInput';
import ClientRow from '../../components/ClientRow/ClientRow';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import './customedPagination.scss';
import styles from './clientsListPage.module.scss';

const ClientsListPage: React.FC = () => {
  const [viewingDetail, setViewDetail] = useState({
    showDetail: false,
    client: null,
  });

  const [queryVariables, setQueryVariables] = useState({
    page: 1,
    search_name: '',
    order_by: ClientOrderableColumn.NAME,
    direction: SortDirection.DESC,
  });

  const viewClient = useCallback((client: IClient) => {
    setViewDetail({
      showDetail: true,
      client,
    });
  }, []);

  const [{ data, fetching }] = useQuery({
    query: CLIENT_getClients,
    variables: queryVariables,
    requestPolicy: 'network-only',
  });

  /*METHODS*/

  const { t: translate } = useTranslation();

  const handleSearch = (searchInput: string) => {
    setQueryVariables({ ...queryVariables, page: 1, search_name: searchInput });
  };

  const handleSort = (direction: SortDirection, order_by: ClientOrderableColumn) => {
    setQueryVariables({ ...queryVariables, direction, order_by });
  };

  const changePage = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
    setQueryVariables({ ...queryVariables, page: data.activePage });
  };
  const dispatch = useDispatch();

  /*EFFECT*/
  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.assignment,
        title: translate('Clients list'),
      },
    });
  }, [dispatch, translate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <p className={styles.titleDesc}>
          {!viewingDetail.showDetail
            ? translate('Click on customer to view detail')
            : `${'Détails du client ' + viewingDetail.client.name}`}
        </p>
      </div>
      {!viewingDetail.showDetail ? (
        <>
          <div className={styles.searchInputAndPaginationWrapper}>
            <SearchInput
              placeholder={translate('Search client')}
              handleSearch={(searchInput) => handleSearch(searchInput)}
            />
            <div className={styles.pagination} id='paginationId'>
              <Pagination
                defaultActivePage={1}
                ellipsisItem={{
                  content: <Icon name='ellipsis horizontal' />,
                  icon: true,
                }}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: <Icon name='angle left' />,
                  icon: true,
                }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                totalPages={data?.listClients ? data.listClients.paginatorInfo.lastPage : 1}
                onPageChange={(event, data) => changePage(event, data)}
              />
            </div>
          </div>
          <div className={styles.tableWrapper}>
            <table className={styles.clientsTable}>
              <thead className={styles.fixedHeader}>
                <tr className={styles.headerTR}>
                  <th className={styles.headerTH}>
                    <div className={styles.headerTitleBlock}>
                      <span>{translate('Client', { count: 2 })}</span>
                      {queryVariables.order_by === ClientOrderableColumn.NAME &&
                      queryVariables.direction === SortDirection.ASC ? (
                        <Icon
                          name='angle up'
                          className={styles.sortIcon}
                          onClick={() => handleSort(SortDirection.DESC, ClientOrderableColumn.NAME)}
                        />
                      ) : (
                        <Icon
                          name='angle down'
                          className={styles.sortIcon}
                          onClick={() => handleSort(SortDirection.ASC, ClientOrderableColumn.NAME)}
                        />
                      )}
                    </div>
                  </th>
                  <th className={styles.headerTH}>
                    <div className={styles.headerTitleBlock}>
                      <span>{translate('Project director')}</span>
                      {queryVariables.order_by === ClientOrderableColumn.PROJECT_DIRECTOR &&
                      queryVariables.direction === SortDirection.ASC ? (
                        <Icon
                          name='angle up'
                          className={styles.sortIcon}
                          onClick={() => handleSort(SortDirection.DESC, ClientOrderableColumn.PROJECT_DIRECTOR)}
                        />
                      ) : (
                        <Icon
                          name='angle down'
                          className={styles.sortIcon}
                          onClick={() => handleSort(SortDirection.ASC, ClientOrderableColumn.PROJECT_DIRECTOR)}
                        />
                      )}
                    </div>
                  </th>
                  <th className={styles.headerTH}>
                    <span>{translate('Opening time for coaching')}</span>
                  </th>
                  <th className={styles.headerTH}>
                    <span>{translate('Program', { count: 2 })}</span>
                  </th>

                  <th className={styles.headerTH}>
                    <div className={styles.headerTitleBlock}>
                      <span>{translate('Tool used')}</span>
                      {queryVariables.order_by === ClientOrderableColumn.TOOL &&
                      queryVariables.direction === SortDirection.ASC ? (
                        <Icon
                          name='angle up'
                          className={styles.sortIcon}
                          onClick={() => handleSort(SortDirection.DESC, ClientOrderableColumn.TOOL)}
                        />
                      ) : (
                        <Icon
                          name='angle down'
                          className={styles.sortIcon}
                          onClick={() => handleSort(SortDirection.ASC, ClientOrderableColumn.TOOL)}
                        />
                      )}
                    </div>
                  </th>
                  <th className={styles.headerTH}>
                    <div className={styles.headerTitleBlock}>
                      <span>{translate('Domain')}</span>
                      {queryVariables.order_by === ClientOrderableColumn.SUBDOMAIN &&
                      queryVariables.direction === SortDirection.ASC ? (
                        <Icon
                          name='angle up'
                          className={styles.sortIcon}
                          onClick={() => handleSort(SortDirection.DESC, ClientOrderableColumn.SUBDOMAIN)}
                        />
                      ) : (
                        <Icon
                          name='angle down'
                          className={styles.sortIcon}
                          onClick={() => handleSort(SortDirection.ASC, ClientOrderableColumn.SUBDOMAIN)}
                        />
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              {fetching && <AwaitingComp fluid={false} />}
              <tbody className={styles.tbody}>
                {data?.listClients &&
                  data.listClients.clients.map((client: IClient, index: number) => {
                    return <ClientRow client={client} key={index} handleViewDetail={() => viewClient(client)} />;
                  })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <EditableClientDetailComp />
      )}
    </div>
  );
};

export default ClientsListPage;
