import { Client_Action } from '../../../models/enum';
import { IClient, IClient_Actions, IProgramManagerExtended } from '../../../models/type';

interface State {
  client: IClient;
  programManager: Array<IProgramManagerExtended>;
  hasProgramManager: boolean;
  clientName: string;
}

const initialState: State = {
  client: null,
  programManager: [],
  hasProgramManager: false,
  clientName: '',
};

export const clientReducer = (state = initialState, action: IClient_Actions) => {
  switch (action.type) {
    case Client_Action.set_client:
      return {
        ...state,
        client: action.payload,
        clientName: action.payload.name,
      };
    case Client_Action.set_client_name:
      return {
        ...state,
        clientName: action.payload,
      };
    case Client_Action.set_program_managers:
      return {
        ...state,
        programManager: action.payload,
        hasProgramManager: true,
      };
    case Client_Action.clear_client_and_program_manager:
      return {
        ...state,
        client: null,
        programManager: [],
        hasProgramManager: false,
        clientName: '',
      };
    default:
      return state;
  }
};
