import React, { Fragment } from 'react';
import Router from '../../components/Router/Router';

const Main: React.FC = () => {
  return (
    <Fragment>
      <Router />
    </Fragment>
  );
};

export default Main;
