import React, { useEffect } from 'react';

import useLogOut from '../../hooks/useLogOut';

import LoginLayout from '../../layouts/LoginLayout/LoginLayout';
import ResetPassword from '../../components/ResetPassword/ResetPassword';

const ResetPasswordPage: React.FC = () => {
  const logOut = useLogOut();

  useEffect(() => {
    logOut();
  }, [logOut]);

  return (
    <div>
      <LoginLayout logoPosition='middle' background='white' formPostion='center'>
        <ResetPassword />
      </LoginLayout>
    </div>
  );
};

export default ResetPasswordPage;
