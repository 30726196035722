import React from 'react';
import { useSelector } from 'react-redux';
import styles from './iconAndTitleDashboardLayout.module.scss';

const IconAndTitleDashboardLayout: React.FC = () => {
  /*PROPS*/

  const store = useSelector((state: any) => state.helperReducer.dashboardLayout);
  return (
    <div className={styles.wrapper}>
      <span className={`${styles.currentTitleIcon} material-icons-outlined`}>{store.iconName}</span>

      <span className={styles.currentTitle}>{store.title}</span>
    </div>
  );
};

export default React.memo(IconAndTitleDashboardLayout);
