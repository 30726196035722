import React from 'react';
import ForgetPassword from '../../components/ForgetPassword/ForgetPassword';
import LoginLayout from '../../layouts/LoginLayout/LoginLayout';

const ForgetPasswordPage: React.FC = () => {
  return (
    <>
      <LoginLayout logoPosition='middle' background='white' formPostion='center'>
        <ForgetPassword />
      </LoginLayout>
    </>
  );
};

export default ForgetPasswordPage;
