import React, { useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Helper_Actions, MaterialIconName } from '../../models/enum';
import { navLinkClasses } from '../../utilities/helper';

import styles from './createClientPage.module.scss';

const CreateClientPage: React.FC = () => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  /*EFFECT*/
  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.add,
        title: translate('Create client'),
      },
    });
  }, [dispatch, translate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h1 className={styles.title}>{translate('Fill in client details')}</h1>
      </div>
      <div className={styles.navBlock}>
        <NavLink
          to={{ pathname: '/createclient/info' }}
          className={(navData) => navLinkClasses(navData, styles.navItem, styles.active)}>
          {translate('Client info')}
        </NavLink>
        <NavLink
          to={{ pathname: '/createclient/manager' }}
          className={(navData) => navLinkClasses(navData, styles.navItem, styles.active)}>
          {translate('Responsible manager')}
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default CreateClientPage;
