import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import EditableCoachDetailComp from '../../components/EditableCoachDetailComp/EditableCoachDetailComp';
import { Helper_Actions, MaterialIconName } from '../../models/enum';
import styles from './createCoachPage.module.scss';

const CreateCoachPage: React.FC = () => {
  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  /*EFFECT*/
  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.person_add,
        title: translate('Create coach'),
      },
    });
  }, [dispatch, translate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h1 className={styles.title}>{translate('About coach')}</h1>
        <p className={styles.titleDesc}>{translate('Please complete form to create a coach')}</p>
      </div>
      <Grid>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={12}>
            <EditableCoachDetailComp editMode={false} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CreateCoachPage;
