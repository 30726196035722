import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQuery } from 'urql';

import {
  Helper_Actions,
  ListMediaResourcesSortableColumn,
  MaterialIconName,
  MediaResources_Actions,
  SortDirection,
} from '../../models/enum';
import { MEDIARESOURCES_listResources } from '../../services/apis/mediaResourcesApis';

import MediaResourcesTable from './MediaResourcesTable';
import MediaResourcesTableToolBlock from './MediaResourcesTableToolBlock';
import styles from './mediaResourcesListPage.module.scss';

const MediaResourcesListPage: React.FC = () => {
  /*STATE*/

  const [queryVariables, setQueryVariables] = useState({
    count: 20,
    page: 1,
    search_by: '',
    search_value: '',
    filter_format: '',
    filter_language: '',
    order_by: ListMediaResourcesSortableColumn.title,
    direction: SortDirection.DESC,
  });

  /*PROPS*/

  const [listMedia] = useQuery({
    query: MEDIARESOURCES_listResources,
    variables: queryVariables,
    requestPolicy: 'cache-and-network',
  });

  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const changePage = (page: any) => {
    setQueryVariables({ ...queryVariables, page: page });
  };

  const handleSelectFilter = (name: string, criteria: string) => {
    setQueryVariables({ ...queryVariables, [name]: criteria });
  };

  const handleSearchInput = (searchValue: string) => {
    setQueryVariables({ ...queryVariables, search_value: searchValue });
  };

  const handleChangeOrder = (category: ListMediaResourcesSortableColumn, direction: SortDirection) => {
    setQueryVariables({
      ...queryVariables,
      order_by: category,
      direction: direction,
    });
  };

  /*EFFECTs*/

  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.perm_media,
        title: translate('Media list'),
      },
    });
  }, [dispatch, translate]);

  useEffect(() => {
    if (!listMedia.data) return;
    dispatch({
      type: MediaResources_Actions.set_list,
      payload: listMedia.data?.listMediaResources?.media_resources,
    });
  }, [dispatch, listMedia.data]);

  /*MAIN RETURN*/
  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <p className={styles.title}>{translate('Collection of media resources')}</p>
      </div>

      <MediaResourcesTableToolBlock
        changePage={(page) => changePage(page)}
        selectCriteria={(name, criteria) => handleSelectFilter(name, criteria)}
        handleSearchInput={(searchValue) => handleSearchInput(searchValue)}
      />

      <div className={styles.tableWrapper}>
        <MediaResourcesTable
          handleChangeDirection={(category, direction) => handleChangeOrder(category, direction)}
          currentOrderBy={queryVariables.order_by}
          currentDirection={queryVariables.direction}
        />
      </div>
    </div>
  );
};

export default MediaResourcesListPage;
