import { ICourse, ICourseProgression, IObjective, IObjectiveEvaluation } from '../../models/type';

/**
 * Return collection content courseID and its progression point of highest session current index
 * course with no Progression point has Progression point value = -1
 *
 * @param coursesArray
 * @returns array of courseID and progressionPoint
 */
export const mapAllCoursesProgressionPoint = (coursesArray: Array<ICourse>) => {
  const result: Array<ICourseProgression> = coursesArray.map((course: ICourse) => {
    let progressionPoint: number;

    const numberOfObjective: number = course.objectives.length;

    if (numberOfObjective === 0) {
      progressionPoint = -1;
    } else {
      const totalPoint: number = course.objectives.reduce((accummulator: number, objective: IObjective) => {
        // Find Highest current session index in evaluations Array;
        const ascEvaluationByCurrentIndex = objective.evaluations.sort(
          (a: IObjectiveEvaluation, b: IObjectiveEvaluation) => {
            return a.session_current_index - b.session_current_index;
          },
        );

        return accummulator + ascEvaluationByCurrentIndex[ascEvaluationByCurrentIndex.length - 1].grade;
      }, 0);

      const avgPoint: number = totalPoint / numberOfObjective;

      progressionPoint = avgPoint / 10;
    }

    return {
      courseID: course.id,
      progressionPoint: progressionPoint,
    };
  });

  return result;
};

/**
 *
 * @param coursesProgression
 * @returns average progression point of program
 */
export const calcAvgProgrammProgressionPoint = (coursesProgression: Array<ICourseProgression>) => {
  if (coursesProgression.length === 0) return 0;

  const totalPoint: number = coursesProgression.reduce(
    (accummulator: number, courseProgression: ICourseProgression) => {
      if (courseProgression.progressionPoint === -1) return accummulator + 0;

      return accummulator + courseProgression.progressionPoint;
    },
    0,
  );

  return totalPoint / coursesProgression.length;
};

/**
 *
 * @param anyNumber
 * @returns string number+%
 */
export const renderPercentage = (anyNumber: number) => {
  if (anyNumber === -1 || isNaN(anyNumber)) return '-';

  return Math.round(anyNumber * 100) + '%';
};
