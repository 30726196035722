import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { TempProgramInterface } from '../../models/type';
import styles from './programRow.module.scss';

interface Props {
  program: TempProgramInterface;
}

const ProgramRow: React.FC<Props> = (props: Props) => {
  return (
    <tr className='tableListRow'>
      <td className={styles.tbodyTD}>
        <Link to={{ pathname: `/program-detail/${props.program.id}` }}>
          <span className={`${styles.programNameText} ${styles.boldNumber}`}>{`${
            props.program.name.length > 40 ? props.program.name.substring(0, 40) + '...' : props.program.name
          }`}</span>
        </Link>
      </td>
      <td className={`${styles.tbodyTD}`}>{props.program.client.name}</td>
      <td className={`${styles.tbodyTD}`}>
        {props.program.courses_summary.number_of_started + '/' + props.program.courses_summary.total}
      </td>
      <td className={`${styles.tbodyTD}`}>{props.program.number_of_program_managers}</td>
      <td className={`${styles.tbodyTD}`}>{props.program.number_of_coaches}</td>
      <td className={`${styles.tbodyTD}`}>{props.program.number_of_coachees}</td>
      <td className={`${styles.tbodyTD}`}>
        {props.program.close_date ? moment(props.program.close_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
      </td>
    </tr>
  );
};

export default ProgramRow;
