export const DIRECTOR_create = `
mutation ($client_id : ID!, $email : String!, $first_name: String!, $last_name: String!, $department: String!, $position: String!) {
    addProjectDirector(client_id: $client_id, email: $email, first_name: $first_name, last_name: $last_name, department: $department, position: $position) {
        status_code
        project_director {
            id
            email  
            first_name
            last_name
            department
            position
            client_id
        }
    }
}
`;

export const DIRECTOR_update = `
mutation ($id : ID!, $email :String!, $last_name: String!, $first_name: String!, $department : String!, $position: String!) {
    updateProjectDirector(
        id: $id,
        email: $email,
        last_name: $last_name, 
        first_name: $first_name, 
        department: $department, 
        position: $position
    ) {
        status_code
        project_director {
            id
            email
            last_name
            first_name
            department
            position
            client_id
        }
    }
}
`;
