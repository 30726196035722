import React from 'react';
import { useMutation } from 'urql';
import { Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useLogOut from '../../hooks/useLogOut';
import { API_userLogout } from '../../services/apis/userApi';

import styles from './userMenuModal.module.scss';

const UserMenuModal: React.FC = () => {
  const { t: translate } = useTranslation();

  const [, logoutMethod] = useMutation(API_userLogout);

  const logOut = useLogOut();

  const handleLogout = () => {
    logoutMethod()
      .then(logOut)
      .catch((err) => {
        console.error(err);
        logOut();
      });
  };

  return (
    <div className={styles.wrapper}>
      <Segment className={styles.customedSegment}>
        <p className={styles.textItem} onClick={handleLogout}>
          {translate('Log out')}
        </p>
      </Segment>
    </div>
  );
};

export default React.memo(UserMenuModal);
