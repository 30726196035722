export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum ClientOrderableColumn {
  NAME = 'NAME',
  TOOL = 'TOOL',
  SUBDOMAIN = 'SUBDOMAIN',
  PROJECT_DIRECTOR = 'PROJECT_DIRECTOR',
}

export enum OfferOrderableColumn {
  NAME = 'NAME',
  TYPE = 'TYPE',
  CREATED_AT = 'CREATED_AT',
}

export enum OfferName {
  Flash = 'Appui Flash',
  Focus = 'Coaching focus',
  Development = 'Coaching de développement',
}

export enum OfferName_Technical {
  'Appui Flash' = 'flash',
  'Coaching focus' = 'focus',
  'Coaching de développement' = 'development',
}

export enum IconEnum {
  'chart bar outline' = 'chart bar outline',
  'bell' = 'bell',
  'envelope outline' = 'envelope outline',
  'user' = 'user',
  'users' = 'users',
  'ellipsis vertical' = 'ellipsis vertical',
  'plus' = 'plus',
  'clipboard list' = 'clipboard list',
  'graduation' = 'graduation',
  'add user' = 'add user',
  'picture' = 'picture',
  'cloud upload' = 'cloud upload',
  'file excel outline' = 'file excel outline',
  'checkmark' = 'checkmark',
  'info' = 'info',
  'image outline' = 'image outline',
  'file pdf outline' = 'file pdf outline',
  'folder outline' = 'folder outline',
  'dropdown' = 'dropdown',
  'exclamation triangle' = 'exclamation triangle',
}

export enum ProgramOrderableColumn {
  PROGRAM_NAME = 'PROGRAM_NAME',
  CLIENT_NAME = 'CLIENT_NAME',
  NUMBER_OF_COURSES = 'NUMBER_OF_COURSES',
  NUMBER_OF_PROGRAM_MANAGERS = 'NUMBER_OF_PROGRAM_MANAGERS',
  NUMBER_OF_COACHES = 'NUMBER_OF_COACHES',
  NUMBER_OF_COACHEES = 'NUMBER_OF_COACHEES',
}

export enum ListCoachesSortableColumn {
  certifications = 'certifications',
  geographical_area = 'geographical_area',
  active = 'active',
  coach_teams = 'coach_teams',
  name = 'name',
  number_of_programs = 'number_of_programs',
}

export enum CourseStatus {
  UNASSIGNED = 'unassigned',
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
  INTERRUPTED = 'interrupted',
}

export enum STATUS_CODE {
  SUCCESS = '200',
  UNAUTHENTICATED = 401,
  FORBIDDEN = '403',
  BADREQUEST = '400',
  NOTFOUND = '404',
  NOCONTENT = '204',
  PAYLOADTOOLARGE = '413',
}

export enum GRAPHQL_ERRORS {
  UNAUTHENTICATED = '[GraphQL] Unauthenticated',
  CLIENT_EXISTED = '[GraphQL] Client already exists',
  CLIENT_HAD_PROGRAM = '[GraphQL] This client is already had this program.',
}

export enum AlertModalContentQuestionStyle {
  BASIC = 'basic',
  PRIMARY = 'primary',
}

/*REDUX*/
export enum Client_Action {
  'set_client' = 'set_client',
  'set_project_director' = 'set_project_director',
  'set_program_managers' = 'set_program_managers',
  'clear_client_and_program_manager' = 'clear_client_and_program_manager',
  'remove_program_manager' = 'remove_program_manager',
  'set_client_name' = 'set_client_name',
}

export enum Program_Actions {
  'add_coach_to_team' = 'add_coach_to_team',
  'remove_coach_from_team' = 'remove_coach_from_team',
  'reset_coach_team_with_backup' = 'reset_coach_team_with_backup',
  'set_coachs_team' = 'set_coachs_team',
  'set_course_progression' = 'set_course_progression',
}

export enum Helper_Actions {
  'set_icon_name' = 'set_icon_name',
  'upload_success' = 'upload_success',
  'reset' = 'reset',
  'set_dashboard_icon_title' = 'set_dashboard_icon_title',
}

export enum Coach_Actions {
  'set_thematics' = 'set_thematics',
}

export enum MediaResources_Actions {
  'set_list' = 'set_list',
  'select_media_item' = 'select_media_item',
  'delete_media_items' = 'delete_media_items',
}

export enum Coach_classify {
  'Executive' = 'EXEC',
  'Senior' = 'MNGR',
  'Coach' = 'CDP',
  'Future' = 'CLB',
}

export enum CoachCode_Explanation {
  'EXEC' = 'Executive coach',
  'MNGR' = 'Senior coach',
  'CDP' = 'Coach',
  'CLB' = 'Future coach',
}

export enum LANGUAGES_Translation {
  'fr' = 'French',
  'en' = 'English',
  'es' = 'Spanish',
  'de' = 'German',
}

export enum LANGUAGE_Code {
  'fr' = 'fr',
  'en' = 'en',
  'es' = 'es',
  'de' = 'de',
}

export enum CERTIFICATIONS {
  AAC = 'AAC',
  MCC = 'MCC',
  PCC = 'PCC',
  OTHER = 'Other',
}

export enum CourseSortableColum {
  OFFER_TYPE = 'offer_type',
  COACHEE = 'coachee',
  COACH = 'coach',
  STARTED_AT = 'started_at',
  NUMBER_OF_SESSION_DONE = 'number_of_sessions_done',
  EVALUATION = 'evaluation',
}

export enum MediaResourcesSearchBy {
  title = 'title',
  keyword = 'keyword',
}

export enum MediaResourcesFormats {
  image = 'image',
  pdf = 'pdf',
  video_link = 'video_link',
  web_link = 'web_link',
}

export enum ListMediaResourcesSortableColumn {
  title = 'title',
  format = 'format',
  created_at = 'created_at',
  updated_at = 'updated_at',
  language = 'language',
}

export enum MediaResourcesAcceptType {
  'png' = 'image/png',
  'jpeg' = 'image/jpeg',
  'jpg' = 'image/jpg',
  'pdf' = 'application/pdf',
}

export enum FormMessages {
  'Success' = 'Success',
  'Failed' = 'Failed',
}

export enum MaterialIconName {
  'add' = 'add',
  'assignment' = 'assignment',
  'school' = 'school',
  'person_add' = 'person_add',
  'group' = 'group',
  'perm_media' = 'perm_media',
  'insert_chart' = 'insert_chart',
  'info' = 'info',
  'check' = 'check',
}
