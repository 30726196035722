import { token } from '../config/tokenConstant';
import { Token } from '../models/type';

export const getInfoTokenFromLocalStorage = (): Token => {
  const tempToken = JSON.parse(localStorage.getItem(token));
  return tempToken;
};

export const getMilisecondExpiresDate = (minute: number): number => {
  const minuteToMilisecond: number = 60 * 1000;
  return Date.now() + minute * minuteToMilisecond;
};
