import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Image, Icon } from 'semantic-ui-react';
import styles from './previewFile.module.scss';
import moment from 'moment';
import { IconEnum, MediaResourcesFormats } from '../../models/enum';
import { locationOrigin } from '../../config/helperConstants';

interface Props {
  createDate: string;
  fileLink: string;
  format: string;
}

const PreviewFile: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const { t: translate } = useTranslation();

  return (
    <Segment className={styles.customedSegment}>
      <div className={styles.topBlock}>
        <div className={styles.image}>
          {props.format === MediaResourcesFormats.image ? (
            <a target='_blank' href={locationOrigin + '/' + props.fileLink} rel='noreferrer'>
              <Image src={locationOrigin + '/' + props.fileLink} size='small' className={styles.previewImage} />
            </a>
          ) : (
            <a target='_blank' href={locationOrigin + '/' + props.fileLink} rel='noreferrer'>
              <Icon name={IconEnum['file pdf outline']} size='huge' className={styles.pdfIcon} />
            </a>
          )}
        </div>
      </div>
      <div className={styles.bottomBlock}>
        <span>{translate('Uploaded') + ' : ' + moment(props.createDate).format('DD/MM/YYYY')}</span>
      </div>
    </Segment>
  );
};

export default PreviewFile;
