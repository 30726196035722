import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import CreateDirector from '../CreateDirector/CreateDirector';
import styles from './createClientProjectDirector.module.scss';

const CreateClientProjectDirector: React.FC = (props) => {
  const { t: translate } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Grid>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={11}>
            <CreateDirector />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={14} widescreen={11}>
            <div className={styles.wrapperBottomBlock}>
              <p className={styles.formTitle}>{translate('Program manager')}</p>
            </div>
            <p className={styles.noManagerText}>{translate('Have not created any program manager')}</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CreateClientProjectDirector;
