import React from 'react';
import './App.scss';
import './theme/mpbConsistence.scss';
import Main from './pages/Main/Main';

const App: React.FC = () => {
  return (
    <div className='App'>
      <Main />
    </div>
  );
};

export default App;
