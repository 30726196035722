import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styles from './tableDeleteButton.module.scss';

interface Props {
  invisible: boolean;
  onClick?: () => void;
}

const TableDeleteButton: React.FC<Props> = (props: Props) => {
  /*METHODS*/

  const { t: translate } = useTranslation();

  return (
    <Button className={`${styles.btn} ${props.invisible && styles.invisible}`} disabled={false} onClick={props.onClick}>
      <Icon name='trash alternate' />
      <span>{translate('Delete')}</span>
    </Button>
  );
};

export default TableDeleteButton;
