import React, { useState } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import Annoucement from '../Common/Annoucement/Annoucement';
import styles from './ResetPassword.module.scss';
import { passwordRule } from '../../services/validation/errorMessage';
import Validator from '../../services/validation/validation';
import { useMutation } from 'urql';
import { API_userResetPassword } from '../../services/apis/userApi';
import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC = () => {
  const [{ fetching }, resetPasswordMethod] = useMutation(API_userResetPassword);

  const [formState, setFormstate] = useState({
    password: {
      value: '',
    },
    repassword: {
      value: '',
    },
    isPassedMinLength: false,
    isPassedNumber: false,
    isPassedSpecialChar: false,
    isPassedUpperCase: false,
    isPassedLowerCase: false,
    isPassedSame: false,
    resetSuccess: false,
  });

  /*METHODS*/
  const { t: translate } = useTranslation();

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name: string = event.currentTarget.name;
    const value: string = event.currentTarget.value;
    const tempState = { ...formState };

    if (name === 'password' || name === 'repassword') {
      tempState[name].value = value;
    }

    tempState.isPassedMinLength = !Validator.checkLength(tempState.password.value, 8, 'min');

    tempState.isPassedNumber = Validator.checkHasNumber(tempState.password.value);

    tempState.isPassedSpecialChar = Validator.checkHasSpecialChar(tempState.password.value);

    tempState.isPassedUpperCase = Validator.checkHasUpperCase(tempState.password.value);

    tempState.isPassedLowerCase = Validator.checkHasLowerCase(tempState.password.value);

    tempState.isPassedSame =
      tempState.password.value === tempState.repassword.value && tempState.password.value.length !== 0;

    setFormstate(tempState);
  };

  const handleSubmit = () => {
    if (
      !formState.isPassedMinLength ||
      !formState.isPassedNumber ||
      !formState.isPassedLowerCase ||
      !formState.isPassedUpperCase ||
      !formState.isPassedSpecialChar ||
      !formState.isPassedSame
    )
      return;

    //url/token/email
    const pathname = window.location.search;
    const token = pathname.split('&')[0].split('=')[1];
    const email = decodeURIComponent(pathname.split('&')[1].split('=')[1]);
    //call resetPasswordMethod
    resetPasswordMethod({
      email: email,
      password: formState.password.value,
      token: token,
    })
      .then((res) => {
        if (res.data.resetPassword.status_code === 200) {
          setFormstate({ ...formState, resetSuccess: true });
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {!formState.resetSuccess ? (
        <Form className={styles.formBlock} onSubmit={handleSubmit}>
          <Icon name='unlock' size={'big'} />
          <p className={`${styles.title} topLevelText`}>{translate('Reset password')}</p>

          <div className={styles.ruleWrapper}>
            <p className={styles.ruleTitle}>{translate('The password must have')}</p>
            <div className={styles.ruleContainer}>
              <p className={`${styles.rule} ${formState.isPassedMinLength && styles.ruleCorrect}`}>
                {translate(passwordRule[0])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedNumber && styles.ruleCorrect}`}>
                {translate(passwordRule[1])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedSpecialChar && styles.ruleCorrect}`}>
                {translate(passwordRule[2])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedUpperCase && styles.ruleCorrect}`}>
                {translate(passwordRule[3])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedLowerCase && styles.ruleCorrect}`}>
                {translate(passwordRule[4])}
              </p>
              <p className={`${styles.rule} ${formState.isPassedSame && styles.ruleCorrect}`}>
                {translate(passwordRule[5])}
              </p>
            </div>
          </div>

          <Form.Field className={styles.formField}>
            <input
              name='password'
              placeholder={translate('New password')}
              type='password'
              className={styles.inputField}
              onChange={(e) => handleOnChange(e)}
            />
          </Form.Field>
          <Form.Field className={styles.formField}>
            <input
              name='repassword'
              placeholder={translate('Re-enter new password')}
              type='password'
              className={styles.inputField}
              onChange={(e) => handleOnChange(e)}
            />
          </Form.Field>

          <Button
            type='submit'
            className={`${styles.submitButton} ${
              formState.isPassedMinLength &&
              formState.isPassedLowerCase &&
              formState.isPassedNumber &&
              formState.isPassedUpperCase &&
              formState.isPassedSpecialChar &&
              formState.isPassedSame &&
              styles.btnActive
            }`}
            disabled={fetching}
            onClick={handleSubmit}>
            {translate('Confirm password')}
          </Button>
        </Form>
      ) : (
        <Annoucement
          title={translate('Password reseted')}
          description={translate('Password reseted success message')}
          btnTitle={translate('Back to home page')}
        />
      )}
    </>
  );
};

export default ResetPassword;
