import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';

import { Client_Action, Helper_Actions, MaterialIconName } from '../../models/enum';
import { navLinkClasses } from '../../utilities/helper';

import styles from './clientDetailPage.module.scss';

const ClientDetailPage: React.FC = () => {
  /*METHODS*/
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  useEffect(() => {
    dispatch({
      type: Helper_Actions.set_dashboard_icon_title,
      payload: {
        icon: MaterialIconName.info,
        title: translate('Client detail'),
      },
    });

    return () => {
      dispatch({
        type: Client_Action.clear_client_and_program_manager,
      });
    };
  }, [dispatch, translate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.navBlock}>
        <NavLink
          to={{
            pathname: `/client-detail/info/${window.location.pathname.split('/').pop()}`,
          }}
          className={(navData) => navLinkClasses(navData, styles.navItem, styles.active)}>
          {translate('Client info')}
        </NavLink>
        <NavLink
          to={{
            pathname: `/client-detail/manager/${window.location.pathname.split('/').pop()}`,
          }}
          className={(navData) => navLinkClasses(navData, styles.navItem, styles.active)}>
          {translate('Responsible manager')}
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default ClientDetailPage;
