import { MediaResources_Actions } from '../../../models/enum';
import { IMediaResource, IMediaResources_Actions } from '../../../models/type';

interface State {
  mediaRourcesList: Array<IMediaResource>;
  selectedMediaList: Array<string>;
}

const initialState: State = {
  mediaRourcesList: [],
  selectedMediaList: [],
};

export const mediaResourcesReducer = (state = initialState, action: IMediaResources_Actions) => {
  switch (action.type) {
    case MediaResources_Actions.set_list:
      return { ...state, mediaRourcesList: action.payload };

    case MediaResources_Actions.select_media_item:
      const newArr = [...state.selectedMediaList];
      const indexFound = newArr.findIndex((item: string) => {
        return item === action.payload.id;
      });
      if (indexFound === -1) newArr.push(action.payload.id);
      else newArr.splice(indexFound, 1);
      return {
        ...state,
        selectedMediaList: newArr,
      };
    case MediaResources_Actions.delete_media_items:
      const copiedMediaList = [...state.mediaRourcesList];
      state.selectedMediaList.forEach((id: string) => {
        const indexFound = copiedMediaList.findIndex((mediaItem: IMediaResource) => mediaItem.id === id);
        if (indexFound !== -1) {
          copiedMediaList.splice(indexFound, 1);
        }
      });

      return {
        ...state,
        mediaRourcesList: copiedMediaList,
        selectedMediaList: [],
      };

    default:
      return state;
  }
};
