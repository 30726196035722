import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './coachConditionsTable.module.scss';
import { coachCriteria } from '../../config/helperConstants';
import { ICoach_condition } from '../../models/type';

const CoachConditionsTable: React.FC = () => {
  /*METHODS*/

  const { t: translate } = useTranslation();

  const renderConditionTable = (tableData: Array<ICoach_condition>) => {
    return tableData.map((item: ICoach_condition, index: number) => {
      return (
        <tr key={index}>
          <td>
            <p className={styles.position}>{translate(item.position)}</p>
            <p className={styles.numberOfCriteria}>{translate(item.numberOfConditions)}</p>
          </td>
          <td>
            <ul>
              {item.criteria.map((item: string, index: number) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </td>
        </tr>
      );
    });
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={`${styles.categoryTH} topLevelText`}>{translate('Category')}</th>
          <th className={`${styles.criteriaTH} topLevelText`}>{translate('Criteria')}</th>
        </tr>
      </thead>
      <tbody>{renderConditionTable(coachCriteria)}</tbody>
    </table>
  );
};

export default React.memo(CoachConditionsTable);
