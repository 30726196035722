export const errorMessage = {
  incorrectInfo: {
    frError: "Nous n'avons pas pu vous identifier car votre identifiant et/ou mot de passe sont incorrects",
    enError: 'Incorrect email and password',
  },
  limitAndSpecialLetter: {
    frError: '8 caractères minimum avec au moins 1 chiffre, lettre et un caractère spécial',
    enError: '8 characters minimum with at least 1 number, letter and a special character',
  },
  email: {
    presence: {
      frError: 'Vous devez entrer votre identifiant',
      enError: 'Email can not be empty',
    },
    invalid: {
      frError: 'Veuillez modifier la valeur pour correspondre au format demandé',
      enError: 'Invalid email',
    },
  },
  password: {
    presence: {
      frError: 'Vous devez entrer votre mot de passe',
      enError: 'Password can not be empty',
    },
  },
};

export const passwordRule = [
  'At least 8 characters',
  'At least 1 number',
  'At least 1 special character',
  'At least 1 capital letter',
  'At least 1 lowercase letter',
  'Passwords must be the same',
];
