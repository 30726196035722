import { DropdownItemProps } from 'semantic-ui-react';

export const mpburl = '.mypositivebooster.fr';

export const generateDevelopmentSessionOptions = (): DropdownItemProps[] => {
  const start = 6;
  const maxSessions = 26;
  return Array.from({ length: maxSessions - start }, (item, index) => {
    return {
      key: index + start,
      text: index + start,
      value: index + start,
    };
  });
};

export const Coach_classify_decode = new Map([
  ['EXEC', 'Executive Coach'],
  ['MNGR', 'Coach Senior'],
  ['CDP', 'Coach'],
  ['CLB', 'Coach en devenir'],
]);

export const Coach_thamatic_title = new Map([
  ['EXEC', 'Dirigeant'],
  ['MNGR', 'Manager'],
  ['CDP', 'Chef de Projet'],
  ['CLB', 'Collaborateur'],
]);

export const LANGUGAGE_map = new Map([
  ['fr', 'French'],
  ['en', 'English'],
  ['es', 'Spanish'],
  ['de', 'German'],
]);

export const coachCriteria = [
  {
    position: 'Executive coach',
    numberOfConditions: 'Executive coach conditions',
    criteria: [
      'Certifié ICF : MCC',
      '2500 heures et plus',
      `Expérience profils « dirigeants » et « managers intermédiaires »`,
      "Plus de 5 ans d'expérience",
      'Formation initiale 25 jours et plus',
    ],
  },
  {
    position: 'Senior coach',
    numberOfConditions: 'Senior coach conditions',
    criteria: [
      'Certifié ICF : PCC',
      '500 heures et plus',
      'Expérience profils « managers intermédiaires » et « managers de proximité »',
      "Plus de 2 ans d'expérience",
      'Formation initiale 15 jours et plus minimum',
    ],
  },
  {
    position: 'Coach',
    numberOfConditions: 'Coach conditions',
    criteria: [
      'Certifié ICF : ACC',
      '100 heures et plus',
      'Expérience profils « collaborateurs non managers» et « managers de proximité »',
      'Formation initiale 10 jours et plus minimum',
    ],
  },
  {
    position: 'Future coach',
    numberOfConditions: 'Future coach conditions',
    criteria: ['Qui ne rentre pas dans les cases précédentes', '2/5'],
  },
];

/*MOSTLY FOR MEDIA RESOURCES TABLE DROPDOWN TOOLS*/

export const mediaResourcesFormat = [
  {
    key: 'all',
    text: 'All',
    value: '',
  },
  {
    key: 'image',
    text: 'Image',
    value: 'image',
  },
  {
    key: 'pdf',
    text: 'Pdf',
    value: 'pdf',
  },
  {
    key: 'video_link',
    text: 'Video link',
    value: 'video_link',
  },
  {
    key: 'web_link',
    text: 'Web link',
    value: 'web_link',
  },
];

export const mediaResourcesInputFormat = [
  {
    key: 'image',
    text: 'Image',
    value: 'image',
  },
  {
    key: 'pdf',
    text: 'PDF',
    value: 'pdf',
  },
  {
    key: 'video_link',
    text: 'Video link',
    value: 'video_link',
  },
  {
    key: 'web_link',
    text: 'Web link',
    value: 'web_link',
  },
];

export const languages = [
  {
    key: 'all',
    text: 'All',
    value: '',
  },
  {
    key: 'fr',
    text: 'French',
    value: 'fr',
  },
  {
    key: 'en',
    text: 'English',
    value: 'en',
  },
  {
    key: 'es',
    text: 'Spanish',
    value: 'es',
  },
  {
    key: 'de',
    text: 'German',
    value: 'de',
  },
];

export const languages_without_all_choice = [
  {
    key: 'fr',
    text: 'French',
    value: 'fr',
  },
  {
    key: 'en',
    text: 'English',
    value: 'en',
  },
  {
    key: 'es',
    text: 'Spanish',
    value: 'es',
  },
  {
    key: 'de',
    text: 'German',
    value: 'de',
  },
];

export const mediaSearchByDropdownOptions = [
  {
    key: 'title',
    text: 'Title',
    value: 'title',
  },
  {
    key: 'keyword',
    text: 'Keyword',
    value: 'keyword',
  },
];

export const locationOrigin = window.location.origin;

export const maxUploadSize = 5000000; //5MB
